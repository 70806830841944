import "./PageView.scss";
import { IonCard, IonCardContent } from "@ionic/react";
import { PropsWithChildren, useEffect } from "react";
import { Page, OnDragEndParams, OnUpdateContentParams, OnDeleteContentParams } from "./Diary";
import PageDeleteButton from "./PageDeleteButton";
import Verhaal from "./PageTypes/Verhaal/Verhaal";
import Tekenkaart from "./PageTypes/Tekenkaart/Tekenkaart";
import Plaatjes from "./PageTypes/Plaatjes/Plaatjes";
import Voorgeschiedenis from "./PageTypes/Voorgeschiedenis/Voorgeschiedenis";
import OverMij from "./PageTypes/OverMij/OverMij";
import Handleiding from "./PageTypes/Handleiding/Handleiding";
import Background from "../../resources/dagboek-bg.png";

const PageView = (
	props: PropsWithChildren<{
		diaryIndex: string;
		chapterIndex: string;
		currentBufferIndex: number;
		page: Page;
		deleteFunc: () => any;
		reloadFunc: () => any;
		showDelete: boolean;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
		showAddContentButton: (condition: boolean) => any;
		showAddVoorgeschiedenis: (condition: boolean) => any;
	}>,
) => {
	const getPageType = (pageType: string) => {
		switch (pageType) {
			case "verhaal":
				return (
					<Verhaal
						page={props.page}
						onDragEnd={props.onDragEnd}
						onUpdateContent={props.onUpdateContent}
						onDeleteContent={props.onDeleteContent}
					/>
				);
			case "plakkaart":
				return (
					<Plaatjes
						page={props.page}
						reloadFunc={props.reloadFunc}
						onDragEnd={props.onDragEnd}
						onUpdateContent={props.onUpdateContent}
						onDeleteContent={props.onDeleteContent}
					/>
				);
			case "tekenkaart":
				return <Tekenkaart page={props.page} />;
			case "voorgeschiedenis":
				return (
					<Voorgeschiedenis
						page={props.page}
						reloadFunc={props.reloadFunc}
						onDragEnd={props.onDragEnd}
						onUpdateContent={props.onUpdateContent}
						onDeleteContent={props.onDeleteContent}
					/>
				);
			case "Over mij":
				return <OverMij page={props.page} diaryIndex={props.diaryIndex} />;
			case "Handleiding":
				return <Handleiding page={props.page} />;
		}
	};

	useEffect(() => {
		if (props.page.type == "verhaal") {
			props.showAddContentButton(true);
		} else {
			props.showAddContentButton(false);
		}
		if (props.page.type == "voorgeschiedenis") {
			props.showAddVoorgeschiedenis(true);
		} else {
			props.showAddVoorgeschiedenis(false);
		}
	}, []);

	return (
		<>
			<IonCard className="page_view_card">
				<IonCardContent className="page_view_card_content">
					<div className="page_content">{getPageType(props.page.type)}</div>
					{props.showDelete && (
						<PageDeleteButton
							diaryIndex={props.diaryIndex}
							chapterIndex={props.chapterIndex}
							pageIndex={props.page.order_index}
							pageName={props.page.title}
							currentBufferIndex={props.currentBufferIndex}
							deleteFunc={props.deleteFunc}
						/>
					)}
				</IonCardContent>
			</IonCard>
		</>
	);
};

export default PageView;
