//import './RegisterForm.scss';
import { IonButton, IonImg, useIonRouter, useIonToast } from "@ionic/react";
import "./VerifyAccount.scss";
import React from "react";
import axiosConfig from "../../axiosConfig";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import logo from "../../resources/dapper_dagboek_logo.png";

const VerifyAccount = () => {
	const router = useIonRouter();
	const [cookies] = useCookies(["user"]);

	const [present] = useIonToast();

	const presentToast = () => {
		present({
			message: "Er is iets mis gegaan. Probeer het later opnieuw.",
			position: "top",
			duration: 2500,
			color: "danger",
			cssClass: "verify-failed-toast",
		});
	};

	const verify = () => {
		const query = queryString.parse(location.search);

		axiosConfig
			.post("/api/confirm_account", {
				headers: {
					Accept: "application/json",
				},
				user_id: query.id,
				token: query.token,
			})
			.then(function (response) {
				console.log(response.data);

				window.setNextAlertMessage("Uw account is bevestigd.");
				window.nextAlertColor = "success";
				router.push("/");
			})
			.catch(function (error) {
				console.log(error);
				// window.setNextAlertMessage("Er is iets mis gegaan.");
				// window.nextAlertColor = "danger";
				presentToast();
			});
	};

	return (
		<div className="verify-page">
			<div className="verify-page-container">
				<IonImg src={logo} />
				<h3>Klik op de onderstaande knop om je account te bevestigen.</h3>
				<IonButton onClick={() => verify()}>Bevestig account</IonButton>
			</div>
		</div>
	);
};

export default VerifyAccount;
