import { IonContent } from "@ionic/react";
import "./Login.scss";
import LoginForm from "../components/LoginForm";
import React, { PropsWithChildren } from "react";

const Login = (props: PropsWithChildren<{ setUser: any; setLoggedIn: any }>) => {
	return (
		<>
			<IonContent className="login-form-container">
				<LoginForm redirect="/" setUser={props.setUser} setLoggedIn={props.setLoggedIn}></LoginForm>
			</IonContent>
		</>
	);
};

export default Login;
