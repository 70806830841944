import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useRef } from "react";
import "./Terms.scss";
import Privacy from "./Privacy";

const Terms = () => {
	const privacyModal = useRef<HTMLIonModalElement>(null);

	return (
		<div className="terms_container">
			<div className="terms_header">
				<h1>Gebruiksvoorwaarden DapperKind App</h1>
				<h6>van</h6>
				<h4>Stichting Dapper Kind</h4>
			</div>

			<div className="terms_sub_header">
				Stichting Dapper Kind verbindt voorwaarden aan het gebruik van de DapperKind App. In de volgende artikelen zijn deze
				voorwaarden te lezen. Lees deze goed door. Heb je vragen? Stuur gerust een e-mail met jouw vraag naar{" "}
				<a href="mailto:info@stichtingdapperkind.nl">info@stichtingdapperkind.nl</a>.
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 1: Stichting Dapper Kind</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Stichting Dapper Kind (hierna verwezen als ‘wij’ of ‘ons’) is gevestigd in Sleeuwijk en zet zich in voor alle
						dappere kinderen rondom ziekenhuizen en zorg. Daarvoor hebben we het DapperDagboek en het BOZ-Boek gemaakt, dé
						dagboeken voor zieke kinderen en voor kinderen met ziekte om hen heen. Wij hebben nu ook een downloadbare
						applicatie, de DapperKind App, waar kinderen (en apart ook ouders/verzorgers) hun gevoelens en ervaringen
						digitaal kunnen beschrijven en op kunnen slaan.
					</li>
					<li className="terms_article_list_item">
						Wij hebben de ANBI (Algemeen Nut Beogende Instelling) status, wat betekent dat de stichting voor minstens 90% het
						maatschappelijk belang dient.
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 2: Gebruik van de DapperKind App</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Deze voorwaarden zijn van toepassing op het gebruik van de DapperKind App die dienst doet als digitaal dagboek.
					</li>
					<li className="terms_article_list_item">
						De gebruikers van de DapperKind App verklaren de gebruiksvoorwaarden te hebben begrepen en zich aan deze
						voorwaarden te houden. Je wordt gevraagd om deze gebruiksvoorwaarden uitdrukkelijk te accepteren wanneer je de
						DapperKind App voor het eerst gebruikt.
					</li>
					<li className="terms_article_list_item">
						Het gebruik van de DapperKind App is gratis. Jouw mobiele provider kan kosten in rekening brengen voor de toegang
						tot netwerkverbindingen voor de duur van de verbinding tijdens het gebruik van de DapperKind App.
					</li>
					<li className="terms_article_list_item">
						Je kunt gebruik maken van de DapperKind App als je maximaal 21 jaar oud bent en een mobiele telefoon of tablet
						tot je beschikking hebt. Ben jij jonger dan 16 jaar, dan is de DapperKind App alleen voor jou beschikbaar indien
						jouw ouder of wettelijk vertegenwoordiger toestemming heeft gegeven voor het door jou gebruiken van de DapperKind
						App.
					</li>
					<li className="terms_article_list_item">
						Daarnaast dien je om gebruik te kunnen maken van de DapperKind App een account aan te maken in de DapperKind App.
						Dit account is persoonlijk en niet overdraagbaar. Nadat je succesvol een account hebt aangemaakt, ontvang je
						daarvan via de e-mail een bevestiging.
					</li>
					<li className="terms_article_list_item">
						Door gebruik te maken van de DapperKind App accepteer je dat wij via de e-mail met jou communiceren.
					</li>
					<li className="terms_article_list_item">
						De gebruikers van de DapperKind App verklaren zich uitsluitend voor deze App te hebben aangemeld voor het doel
						dat wij met deze App nastreven, namelijk de mogelijkheid voor (voormalig) zieke kinderen om digitaal een dagboek
						bij te houden. De DapperKind App is te gebruiken door (voormalig) zieke kinderen die in een ziekenhuis of
						zorginstelling verblijven (of verbleven) maar is daarnaast ook te gebruiken door broertjes of zusjes van
						(voormalig) zieke kinderen die in een ziekenhuis of zorginstelling verblijven.
					</li>
					<li className="terms_article_list_item">
						Je bent zelf verantwoordelijk voor de geheimhouding van jouw inloggegevens. Jij bent verantwoordelijk voor alle
						vormen van gebruik van jouw inloggegevens, met of zonder jouw toestemming. Wij accepteren geen
						verantwoordelijkheid of aansprakelijkheid voor verstuurde informatie door iemand die frauduleus toegang heeft
						gekregen tot jouw account van de DapperKind App. Indien jij merkt dat iemand anders jouw account van de
						DapperKind App gebruikt, dan is het jouw verantwoordelijkheid om zo spoedig mogelijk jouw wachtwoord te wijzigen.
					</li>
					<li className="terms_article_list_item">
						Tenslotte verklaar je als gebruiker van de DapperKind App dat je het aanmeldformulier naar waarheid hebt ingevuld
						en dat je voldoet aan de voorwaarden zoals in deze gebruiksvoorwaarden zijn gesteld.
					</li>
					<li className="terms_article_list_item">
						Je kunt op ieder moment jouw account van de DapperKind App deactiveren en de DapperKind App verwijderen van jouw
						mobiele apparaat.
					</li>
					<li className="terms_article_list_item">
						Het is niet toegestaan om van de DapperKind App gebruik te maken op een manier die andere gebruikers zou kunnen
						hinderen of anderszins het goed functioneren van de DapperKind App zou kunnen aantasten.
					</li>
					<li className="terms_article_list_item">
						Naast deze gebruiksvoorwaarden kunnen de Appstore van Apple en de Playstore van Google afzonderlijke voorwaarden
						opleggen voor het verkrijgen en gebruiken van de DapperKind App. Voor dit doel dient de gebruiker de
						gebruiksvoorwaarden en de privacyverklaring van de AppStore van Apple, Google’s Playstore en alle toepasselijke
						voorwaarden op de website van de betreffende aanbieder te raadplegen. Apple en Google zijn geen partij bij deze
						gebruiksvoorwaarden
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 3: Wijziging</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Wij mogen deze gebruiksvoorwaarden van tijd tot tijd wijzigen. Wij stellen jou via de e-mail op de hoogte van de
						nieuwe gebruiksvoorwaarden en van de datum waarop de nieuwe gebruiksvoorwaarden in werking treden. Indien je niet
						akkoord gaat met de gewijzigde gebruiksvoorwaarden, dan dien je geen gebruik meer te maken van de DapperKind App
						en de DapperKind App te verwijderen van jouw mobiele apparaat. Een link naar de meest recente versie van de
						gebruiksvoorwaarden zal altijd beschikbaar zijn in de DapperKind App en op onze website www.dapperdagboek.nl.
					</li>
					<li className="terms_article_list_item">
						De DapperKind App kan updates uitgeven om eventuele fouten op te lossen, de prestaties van de DapperKind App te
						verbeteren of nieuwe functionaliteiten toe te voegen.
					</li>
					<li className="terms_article_list_item">
						Beschikbare updates voor de DapperKind App worden gemeld via de betreffende App store en het is de
						verantwoordelijkheid van de gebruiker van de DapperKind App om dergelijke meldingen bij te houden.
					</li>
					<li className="terms_article_list_item">
						Stichting Dapper Kind behoudt zich het recht voor om op elk moment de DapperKind App geheel of gedeeltelijk te
						beëindigen. De gebruiker wordt hiervan op de hoogte gesteld en heeft – in het geval de DapperKind App in zijn
						geheel wordt beëindigd – de mogelijkheid om het reeds aanwezige dagboek in de DapperKind App zelf uit te printen
						of in pdf aan Stichting Dapper Kind te sturen zodat Stichting Dapper Kind het dagboek voor je kan samenstellen.
						Indien Stichting Dapper Kind besluit te stoppen met de DapperKind App, dan heb je 60 dagen de tijd om jouw keuze
						hierin kenbaar te maken. Stichting Dapper Kind zal je in die periode van 60 dagen meerdere keren per e-mail
						wijzen op de mogelijkheid om jouw dagboek zelf te printen of in pdf aan Stichting Dapper Kind te mailen. Indien
						je kiest voor de laatste optie dan geef je toestemming om jouw dagboek in pdf aan ons te verzenden, waarbij je
						rekening dient te houden met het feit dat je hierbij de beveiligde DapperKind App verlaat en het via de e-mail
						naar ons toe stuurt.
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 4: Privacybewaking</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Wij vinden de bescherming van de privacy van gebruikers van de DapperKind App en veiligheid van persoonsgegeven
						belangrijk. De gegevens zullen dan ook, in overeenstemming met de Algemene Verordening Gegevensbescherming (AVG),
						uitsluitend worden verwerkt met het doel waarvoor zij zijn verkregen. De gegevens worden zonder uitdrukkelijke
						toestemming van de gebruiker niet verstrekt aan anderen, tenzij wij daartoe verplicht zijn op basis van de wet of
						een rechterlijke uitspraak. Wij treffen passende technische en organisatorische maatregelen ter beveiliging van
						persoonsgegevens.
					</li>
					<li className="terms_article_list_item">
						Voor meer informatie over de verwerking van persoonsgegevens door ons kun je onze privacy verklaring raadplegen.
						Je kunt de privacyverklaring hier onder vinden en op onze website{" "}
						<a href="https://www.dapperdagboek.nl">dapperdagboek.nl</a>.
						<br />
						<IonButton className="terms_and_conditions ion-margin-top" fill="outline" id="open-modal-privacy">
							privacy voorwaarden
						</IonButton>
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 5: Aansprakelijkheid</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Het gebruik van de DapperKind App is geheel op eigen risico. Jij bent zelf verantwoordelijk voor het gebruik van
						de DapperKind App.
					</li>
					<li className="terms_article_list_item">
						In geen geval zijn wij aansprakelijk voor enige directe en/of indirecte schade van gebruikers van de DapperKind
						App.
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 6: Intellectueel eigendom</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Alle rechten van intellectuele eigendom alsmede soortgelijke rechten tot bescherming van informatie met
						betrekking tot de DapperKind App (incl. standaard aanpassingen en updates), de achterliggende software, teksten,
						lay-out en afbeeldingen komen uitsluitend aan ons toe.
					</li>
					<li className="terms_article_list_item">
						Jij bent verplicht om op geen enkele wijze inbreuk te maken op onze intellectuele eigendomsrechten. Wij verlenen
						jou een wereldwijde, niet-exclusieve, niet-overdraagbaar en herroepelijke licentie om de DapperKind App te
						gebruiken voor persoonlijk (niet-commercieel) gebruik. Het is niet toegestaan om enig onderdeel van de DapperKind
						App te reproduceren, verspreiden, wijzigen of hiervan afgeleide werken te maken, inhoud publiekelijk te tonen of
						commercieel te exploiteren.
					</li>
					<li className="terms_article_list_item">
						Het gebruik van de naam ‘Stichting Dapper Kind’ en/of ‘DapperDagboek’ en de bijbehorende logo’s is verboden.
					</li>
					<li className="terms_article_list_item">
						Indien de gebruiker dit artikel overtreedt, dan verbeurt hij voor elke overtreding ten behoeve van ons een
						onmiddellijk opeisbare boete van € 1.000,-. Voor het verbeuren van deze boete is geen voorafgaande
						ingebrekestelling of gerechtelijke procedure nodig. Ook hoeft er geen sprake te zijn van enige vorm van schade.
						Het verbeuren van deze boete doet geen afbreuk aan onze overige rechten waaronder het recht om naast de boete
						schadevergoeding te vorderen.
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 7: Vrijwaring</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Wij kunnen niet verplicht worden tot het ondersteunen of onderhouden van de DapperKind App. Zowel wij als onze
						partners bieden geen enkele garantie met betrekking tot de werking van de diensten.
					</li>
					<li className="terms_article_list_item">
						De DapperKind App wordt via het internet aan jou ter beschikking gesteld. Dit betekent dat de kwaliteit of
						beschikbaarheid van de DapperKind App kan worden beïnvloed door factoren die niet aan ons zijn toe te rekenen.
						Wij kunnen nimmer aansprakelijk worden gesteld voor schade die jij lijdt doordat de DapperKind App niet of niet
						volledig aan jou ter beschikking kan worden gesteld.
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 8: Klachtenregeling</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Wanneer je als gebruiker van de DapperKind App een klacht hebt, kun je je wenden tot{" "}
						<a href="mailto:info@stichtingdapperkind.nl">info@stichtingdapperkind.nl</a>.
					</li>
				</ol>
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Artikel 9: Slotbepalingen</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Op de rechtsverhouding tussen ons enerzijds en de gebruikers van de DapperKind App anderzijds, is het Nederlands
						recht van toepassing.
					</li>
					<li className="terms_article_list_item">
						De Nederlandse rechter in het arrondissement waar wij zijn gevestigd, is exclusief bevoegd om kennis te nemen van
						eventuele geschillen, tenzij de wet dwingend anders voorschrijft.
					</li>
					<li className="terms_article_list_item">
						Wanneer in een gerechtelijke procedure één of meerdere bepalingen van deze gebruiksvoorwaarden als onredelijk
						bezwarend worden aangemerkt, dan blijven de overige bepalingen onverminderd van kracht.
					</li>
					<li className="terms_article_list_item">
						Deze gebruiksvoorwaarden kun je ook vinden op <a href="https://www.dapperdagboek.nl">dapperdagboek.nl</a>. De
						nieuwste versie van de voorwaarden is van toepassing.
					</li>
				</ol>
			</div>

			<div className="terms_footnote">
				<p>Versie 3 januari 2023</p>
			</div>
			<IonModal ref={privacyModal} trigger="open-modal-privacy" className="fullscreen" canDismiss={true}>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => privacyModal.current?.dismiss()}>
								<IonIcon md={arrowBack} ios={arrowBack} />
							</IonButton>
						</IonButtons>
						<IonTitle>privacy voorwaarden</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent class="center">
					<Privacy />
				</IonContent>
			</IonModal>
		</div>
	);
};

export default Terms;
