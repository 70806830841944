import {
	IonButton,
	IonButtons,
	IonCheckbox,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonModal,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import React, { PropsWithChildren, useRef } from "react";
import { arrowBack } from "ionicons/icons";
import Terms from "./Terms";

const TermsItem = (props: PropsWithChildren<{ setTermsChecked: any }>) => {
	const { setTermsChecked } = props;

	const termsModal = useRef<HTMLIonModalElement>(null);

	return (
		<>
			<IonItem id="termsItem" className="ion-margin-top">
				<IonCheckbox
					slot="start"
					onIonChange={(e) => {
						setTermsChecked(e.detail.checked);
						e.target.parentElement?.classList.remove("wrong");
					}}
				></IonCheckbox>
				<IonLabel class="ion-text-wrap">Ik ga akkoord met de algemene voorwaarden</IonLabel>
			</IonItem>

			<IonButton className="terms_and_conditions ion-margin-top" fill="outline" size="small" id="open-modal">
				Algemene voorwaarden
			</IonButton>

			<IonModal ref={termsModal} trigger="open-modal" className="fullscreen" canDismiss={true}>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => termsModal.current?.dismiss()}>
								<IonIcon md={arrowBack} ios={arrowBack} />
							</IonButton>
						</IonButtons>
						<IonTitle>Algemene voorwaarden</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent class="center">
					<Terms />
				</IonContent>
			</IonModal>
		</>
	);
};

export default TermsItem;
