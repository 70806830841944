import React, { PropsWithChildren } from "react";
import Diary from "./Diary";
import { IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import AlertButton from "../AlertButton";
import axiosConfig from "../../axiosConfig";

const DiaryDeleteButton = (props: PropsWithChildren<{ diary: Diary; func: (diaryIndex: number) => any }>) => {
	const deleteDiary = (diaryIndex: number) => {
		axiosConfig
			.post("/api/diary/" + diaryIndex + "/delete")
			.then((response) => {
				props.func(diaryIndex);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<AlertButton
			class={"diary-delete-button"}
			header={`Weet je zeker dat je ${props.diary.title} wilt verwijderen?`}
			fill="solid"
			color="danger"
			func={() => deleteDiary(props.diary.index)}
		>
			<IonIcon icon={trash} />
		</AlertButton>
	);
};

export default DiaryDeleteButton;
