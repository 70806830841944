import "./TextSlot.scss";
import { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Chapter, ContentType, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, Page } from "../../Diary";
import axiosConfig from "../../../../axiosConfig";
import { IonTextarea } from "@ionic/react";
import PageTextView from "../../Page/PageTextView";

const StickerSlot = (
	props: PropsWithChildren<{
		page: Page;
		getTextPosition: (clickedSlot: any) => any;
		updateText: (textId: any, sentText: any) => any;
		slotId: any;
		pageType: any;
		slotNumber: any;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const [textareaValue, setTextareaValue] = useState<string>();

	const onBlur = () => {
		[...Array(9)].map((e, i) => {
			if (props.page?.PageTexts[i]?.SlotNumber == props.slotId) {
				if (textareaRef?.current?.value == props.page?.PageTexts[i].text) {
					// The textbox had text but it wasn't changed
				} else {
					// The textbox had text but it was changed
					const textId = props.page?.PageTexts[i].id;
					props.updateText(textId, textareaRef?.current?.value);
				}
			} else if (props.slotId == i && textareaRef?.current?.value) {
				//The textbox was empty but text added
				console.log(props.page?.PageTexts);
				if (!props.page?.PageTexts) {
					props.getTextPosition(textareaRef.current);
				} else {
					let check = false;
					props.page?.PageTexts.map((item, index) => {
						if (item.SlotNumber == props.slotId) {
							check = true;
						}
					});
					if (check == false) {
						props.getTextPosition(textareaRef.current);
					}
				}
			}
		});
	};

	useEffect(() => {
		props.page?.PageTexts.map((text, index) => {
			if (props.page?.PageTexts[index]?.SlotNumber == props.slotId) {
				setTextareaValue(props.page?.PageTexts[index].text);
			}
		});
	}, []);

	return (
		<>
			{props.pageType == "voorgeschiedenis" ? (
				<textarea
					ref={textareaRef}
					className="trans_white_bg sticker_slot_text"
					onBlur={onBlur}
					defaultValue={textareaValue}
					style={{ height: "100%" }}
				/>
			) : (
				<textarea
					ref={textareaRef}
					className="trans_white_bg sticker_slot_text"
					onBlur={onBlur}
					defaultValue={textareaValue}
				/>
			)}
		</>
	);
};

export default StickerSlot;
