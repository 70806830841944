import "./AccountSettings.scss";
import React, { PropsWithChildren, useState } from "react";
import { IonButton, useIonAlert } from "@ionic/react";
import axiosConfig from "../../axiosConfig";

import { useCookies } from "react-cookie";
import UserSettings from "../../components/AccountSettings/UserSettings";
import SecuritySettings from "../../components/AccountSettings/SecurtiySettings";

const AccountSettings = (props: PropsWithChildren<{ setUser: any }>) => {
	const [presentAlert] = useIonAlert();
	const [role_id, setRole] = useState("");
	const [state, setState] = useState("");
	const [cookies] = useCookies(["user"]);

	const presentAlertFunc = () => {
		presentAlert({
			header: "Account verwijderen",
			message: "Er is een mail gestuurd naar het ingevoerde mailadres met de link om je account te verwijderen.",
			buttons: ["OK"],
		});

		axiosConfig
			.post("/api/propose_delete")
			.then(function (response) {
				console.log(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const editRole = () => {
		axiosConfig
			.post("/api/edit/role", {
				id: cookies.user.id,
				role: role_id,
			})
			.then(function (response) {
				//console.log(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<UserSettings setUser={props.setUser} />
			<SecuritySettings />
			<IonButton onClick={() => presentAlertFunc()} color="danger">
				Account verwijderen
			</IonButton>
		</>
	);
};

export default AccountSettings;
