import "./OverMij.scss";
import "../PageType.scss";
import React, { useState, useEffect, PropsWithChildren } from "react";
import { Page } from "../../Diary";
import { IonItem, IonInput, IonLabel, IonTextarea, IonTitle } from "@ionic/react";
import axiosConfig from "../../../../axiosConfig";
import { useCookies } from "react-cookie";

const OverMij = (props: PropsWithChildren<{ page: Page; diaryIndex: string }>) => {
	const [likes, setLikes] = useState("");
	const [eyeColor, setEyeColor] = useState("");
	const [hairColor, setHairColor] = useState("");
	const [length, setLength] = useState(0);
	const [because, setBecause] = useState("");
	const [year, setYear] = useState(0);

	const [cookies] = useCookies(["user"]);
	const d = new Date();

	const months = [
		"Januari",
		"Februari",
		"Maart",
		"April",
		"Mei",
		"Juni",
		"Juli",
		"Augustus",
		"September",
		"Oktober",
		"November",
		"December",
	];

	useEffect(() => {
		axiosConfig
			.post(`/api/diary/${props.diaryIndex}/about_me/get`)
			.then(function (response) {
				setLikes(response.data.aboutMe.likes);
				setEyeColor(response.data.aboutMe.eye_color);
				setHairColor(response.data.aboutMe.hair_color);
				setLength(response.data.aboutMe.length);
				setBecause(response.data.aboutMe.because);
			})
			.catch(function (error) {
				console.log(error);
			});

		if (cookies.user.personal_info?.year && cookies.user.personal_info?.month && cookies.user.personal_info?.day) {
			if (
				Date.parse(cookies.user.personal_info?.month + "-" + cookies.user.personal_info?.day + "-" + d.getFullYear()) <=
				Date.now()
			) {
				setYear(d.getFullYear() - cookies.user.personal_info?.year);
			} else {
				setYear(d.getFullYear() - cookies.user.personal_info?.year - 1);
			}
		}
	}, []);

	useEffect(() => {
		axiosConfig
			.post(`/api/diary/${props.diaryIndex}/about_me/update`, {
				likes: likes || null,
				eye_color: eyeColor || null,
				hair_color: hairColor || null,
				length: length || null,
				because: because || null,
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [likes, eyeColor, hairColor, length, because]);

	return (
		<>
			<div className="page_type_page over_mij">
				<div className="header">
					<h1>{props.page.title}</h1>
				</div>
				<div className="trans_white_bg about_me_content">
					<div>
						<IonTitle>
							Dit dagboek is van{" "}
							{cookies.user.personal_info?.firstname
								? cookies.user.personal_info?.firstname + " " + (cookies.user.personal_info?.surname || "")
								: cookies.user.username}
						</IonTitle>

						<IonItem className="ion-margin-top">
							<IonLabel position="floating">Ik hou van</IonLabel>
							<IonInput
								value={likes}
								onIonBlur={(e: any) => {
									setLikes(e.target.value);
								}}
							/>
						</IonItem>

						{cookies.user.personal_info?.day && cookies.user.personal_info?.month ? (
							<>
								<IonItem className="ion-margin-top">
									<IonLabel>
										Mijn verjaardag is op{" "}
										{cookies.user.personal_info?.day
											? cookies.user.personal_info?.day + " " + months[cookies.user.personal_info?.month - 1]
											: ""}
									</IonLabel>
								</IonItem>
							</>
						) : (
							""
						)}

						{year ? (
							<>
								<IonItem className="ion-margin-top">
									<IonLabel>Nu ben ik {year} jaar</IonLabel>
								</IonItem>
							</>
						) : (
							""
						)}

						<IonItem className="ion-margin-top">
							<IonLabel position="floating">Kleur haar</IonLabel>
							<IonInput
								value={hairColor}
								onIonBlur={(e: any) => {
									setHairColor(e.target.value);
								}}
							/>
						</IonItem>

						<IonItem className="ion-margin-top">
							<IonLabel position="floating">Kleur ogen</IonLabel>
							<IonInput
								value={eyeColor}
								onIonBlur={(e: any) => {
									setEyeColor(e.target.value);
								}}
							/>
						</IonItem>

						<div className="ion-margin-top">
							<IonItem>
								<IonLabel position="floating">Lengte</IonLabel>
								<IonInput
									className="ion-float-right length-input"
									type="number"
									value={length}
									onIonBlur={(e: any) => {
										setLength(e.target.value);
									}}
								>
									<span>CM</span>
								</IonInput>
							</IonItem>
						</div>

						<IonItem className="ion-margin-top">
							<IonLabel position="floating">Ik ben een dapperkind omdat</IonLabel>
							<IonTextarea
								value={because}
								autoGrow={true}
								onIonBlur={(e: any) => {
									setBecause(e.target.value);
								}}
							></IonTextarea>
						</IonItem>
					</div>
				</div>
			</div>
		</>
	);
};

export default OverMij;
