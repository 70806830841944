import { IonChip, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption } from "@ionic/react";

import axiosConfig from "../axiosConfig";

import React, { useState, useEffect, PropsWithChildren } from "react";

import "./AdminRightsForm.scss";

import AlertButton from "./AlertButton";
import User from "../User";

const AdminRightsForm = (props: PropsWithChildren<{ user: User }>) => {
	const defaultRole = null;

	const defaultStatus = true;
	const [showPage, setShowPage] = useState(1);
	const [showUsers, setShowUsers] = useState(10);

	const [pages, setPages] = useState(0);
	const showPages = 7;
	let prevPages;
	let nextPages;
	const shownPages = [];

	const [errorMessage, setErrorMessage] = useState("");
	const [filter, setFilter] = useState("");
	const [filterOn, setFilterOn] = useState("");
	const [password, setPassword] = useState();
	const [roles, setRoles] = useState<Role[]>([]);
	const [activated, setActivated] = useState(defaultStatus);
	const [showRole, setShowRole] = useState(defaultRole);
	const [users, setUsers] = useState<User[]>([]);

	const passwordId = document.getElementById("password");

	//This function can access an api of choice
	const useApi = (email: string, func: string) => {
		axiosConfig
			.post("/api/" + func, {
				headers: {
					Accept: "application/json",
				},
				admin: props.user.email,
				user: email,
				password: password,
			})
			.then((response) => {
				getUsers();
			})
			.catch((err) => {
				setErrorMessage("Wachtwoord is ongeldig");
				if (passwordId != null) {
					passwordId.scrollIntoView({ behavior: "smooth" });
				}
			});
	};

	const getUsers = () => {
		axiosConfig
			.post("/api/get_all_users", {
				headers: {
					Accept: "application/json",
				},
				email: props.user.email,
				page: showPage,
				showUsers: showUsers,
				role: showRole,
				filter: filter,
				filterOn: filterOn,
				activated: activated,
			})
			.then(function (response) {
				setUsers(response.data.users.data);
				setPages(response.data.count);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getRoles = () => {
		axiosConfig
			.post("/api/get_all_roles", {
				headers: {
					Accept: "application/json",
				},
				email: props.user.email,
			})
			.then(function (response) {
				setRoles(response.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getRoles();
	}, []);

	useEffect(() => {
		setShowPage(1);
	}, [showUsers, showRole, filter, activated]);

	useEffect(() => {
		getUsers();
	}, [showPage, showUsers, showRole, filter, filterOn, activated]);

	if (pages <= showPages) {
		prevPages = 1;
		nextPages = pages;
	} else if (showPage <= Math.floor(showPages / 2)) {
		prevPages = 1;
		nextPages = showPages;
	} else if (showPage >= pages - Math.floor(showPages / 2) + 1) {
		prevPages = pages - showPages + 1;
		nextPages = pages;
	} else {
		prevPages = showPage - Math.floor(showPages / 2);
		nextPages = showPage + Math.floor(showPages / 2);
	}

	for (let i = prevPages; i <= nextPages; i++) {
		shownPages.push(i);
	}

	return (
		<div className="admin-rights">
			<h1>Alle gebruikers</h1>
			<div className="filter-select section">
				<h2>Filteren</h2>
				<div className="choose-filter">
					<div>
						<IonItem>
							<IonLabel>Filteren op</IonLabel>
							<IonSelect
								placeholder="selecteer"
								defaultValue={"Email"}
								onIonChange={(e: any) => {
									setFilterOn(e.target.value);
								}}
							>
								<IonSelectOption value="Naam">Naam</IonSelectOption>
								<IonSelectOption value="Email">Email</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Zoek op basis van filter</IonLabel>
							<IonInput
								onIonBlur={(e: any) => {
									setFilter(e.target.value);
								}}
							/>
						</IonItem>
					</div>
				</div>
				<div>
					<IonItem>
						<IonLabel>Rol</IonLabel>
						<IonSelect
							placeholder="selecteer"
							onIonChange={(e: any) => {
								setShowRole(e.target.value);
							}}
						>
							<IonSelectOption value={defaultRole}>Allemaal</IonSelectOption>
							{roles.map((role, index) => {
								return (
									<IonSelectOption key={index} value={role.role}>
										{role.role}
									</IonSelectOption>
								);
							})}
						</IonSelect>
					</IonItem>
					<IonItem>
						<IonLabel>Status</IonLabel>
						<IonSelect
							placeholder="selecteer"
							onIonChange={(e: any) => {
								setActivated(e.target.value);
							}}
						>
							<IonSelectOption value={false}>Uitgeschakeld</IonSelectOption>
							<IonSelectOption value={defaultStatus}>Geactiveerd</IonSelectOption>
						</IonSelect>
					</IonItem>
				</div>
			</div>
			<IonItem>
				<IonLabel>Aantal gebruikers per pagina</IonLabel>
				<IonSelect
					placeholder="selecteer"
					onIonChange={(e: any) => {
						setShowUsers(e.target.value);
					}}
				>
					<IonSelectOption value={10}>10</IonSelectOption>
					<IonSelectOption value={25}>25</IonSelectOption>
					<IonSelectOption value={50}>50</IonSelectOption>
				</IonSelect>
			</IonItem>
			<br />
			<div>Wachtwoord nodig om aanpassingen op accounts uit te voeren.</div>
			<IonItem id="password">
				<IonLabel position="floating">Wachtwoord</IonLabel>
				<IonInput type="password" onIonInput={(e: any) => setPassword(e.target.value)} />
			</IonItem>
			<IonChip className="ion-margin-top" color="danger">
				{errorMessage}
			</IonChip>
			<IonList className="section">
				{users.map((user, index) => {
					return (
						<IonItem key={index} className="ion-margin-bottom" color="light">
							<div className="users">
								<div className="userPart">
									<div>{user.email}</div>
									<div>
										{user.personal_info?.firstname || "Username: " + user.username}{" "}
										{user.personal_info ? user.personal_info.surname : ""}
									</div>
								</div>
								<div className="buttons">
									{user.role === "Admin" ? (
										<div>
											<AlertButton
												func={() => {
													useApi(user.email, "take_away_admin_rights");
												}}
												header={"Weet u zeker dat u de admin rechten van " + user.email + " wilt afnemen?"}
											>
												Admin rechten afnemen
											</AlertButton>
										</div>
									) : (
										<div>
											<div>
												<AlertButton
													color="danger"
													func={() => {
														useApi(user.email, "delete_user");
													}}
													header={"Weet u zeker dat u " + user.email + "  wilt verwijderen?"}
												>
													Verwijderen
												</AlertButton>
											</div>
											{activated ? (
												<div>
													<div>
														<AlertButton
															func={() => {
																useApi(user.email, "disable_user");
															}}
															header={"Weet u zeker dat u  " + user.email + " wilt uitschakelen?"}
														>
															Uitschakelen
														</AlertButton>
													</div>
													<div>
														<AlertButton
															func={() => {
																useApi(user.email, "make_user_admin");
															}}
															header={"Weet u zeker dat u " + user.email + "  admin wil maken?"}
														>
															Admin maken
														</AlertButton>
													</div>
												</div>
											) : (
												<div>
													<AlertButton
														func={() => {
															useApi(user.email, "enable_user");
														}}
														header={"Weet u zeker dat u " + user.email + " weer wil heractiveren?"}
													>
														Heractiveren
													</AlertButton>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</IonItem>
					);
				})}
			</IonList>
			<div>
				<span
					className={showPage > 1 ? "pages pages-horizontal" : "pages-horizontal"}
					onClick={() => {
						console.log(showPage);
						if (showPage > 1) {
							setShowPage(1);
						}
					}}
				>
					First
				</span>
				<span
					className={showPage > 1 ? "pages pages-horizontal" : "pages-horizontal"}
					onClick={() => {
						if (showPage > 1) {
							setShowPage(showPage - 1);
						}
					}}
				>
					Prev
				</span>
				{shownPages.map((shownPage, index) => {
					return (
						<span
							key={index}
							className={showPage != shownPage ? "pages pages-horizontal" : "pages-horizontal"}
							onClick={() => {
								if (showPage != shownPage) {
									setShowPage(shownPage);
								}
							}}
						>
							{shownPage}
						</span>
					);
				})}
				<span
					className={showPage < pages ? "pages pages-horizontal" : "pages-horizontal"}
					onClick={() => {
						if (showPage < pages) {
							setShowPage(showPage + 1);
						}
					}}
				>
					Next
				</span>
				<span
					className={showPage < pages ? "pages pages-horizontal" : "pages-horizontal"}
					onClick={() => {
						if (showPage < pages) {
							setShowPage(pages);
						}
					}}
				>
					Last
				</span>
			</div>
		</div>
	);
};

export default AdminRightsForm;

export interface Role {
	role: string;
}
