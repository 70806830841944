import "./TextSlot.scss";
import { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Chapter, ContentType, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, Page } from "../../Diary";
import axiosConfig from "../../../../axiosConfig";
import { IonTextarea } from "@ionic/react";
import PageTextView from "../../Page/PageTextView";

const StickerSlot = (
	props: PropsWithChildren<{
		page: Page;
		getDatePosition: (clickedDate: any, slotId: number) => any;
		slotId: any;
		pageType: any;
		slotNumber: any;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = useState<string>();

	const getDate = () => {
		if (inputRef?.current?.value) props.getDatePosition(inputRef?.current, props.slotId);
	};

	useEffect(() => {
		props.page.PageStickers.map((sticker, i) => {
			if (sticker.SlotNumber == props.slotId) {
				let date = sticker.customDate;
				if (date) {
					date = date.split(" ")[0];
					setInputValue(date);
				}
			}
		});
	}, []);

	return (
		<>
			<input
				ref={inputRef}
				id={"sticker_date" + props.slotId}
				className="trans_white_bg sticker_date"
				type="date"
				onBlur={getDate}
				defaultValue={inputValue}
			></input>
		</>
	);
};

export default StickerSlot;
