import "./TableOfContents.scss";
import {
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonTitle,
	IonReorder,
	IonReorderGroup,
} from "@ionic/react";

import { Page as PageObject, Page } from "./Diary";

import { PropsWithChildren } from "react";

import "./TableOfContents.scss";

import { menuOutline } from "ionicons/icons";

const TableOfContents = (
	props: PropsWithChildren<{
		allPages: any;
		handleReorder: any;
	}>,
) => {
	return (
		<IonMenu class="page-index-menu" menuId="pageMenu" contentId="main-page-child" type="overlay" side="end">
			<IonContent>
				<IonList>
					<IonListHeader>
						<IonTitle>Inhoudsopgave</IonTitle>
					</IonListHeader>
					<IonReorderGroup disabled={false} onIonItemReorder={props.handleReorder}>
						{props.allPages?.map((page: PageObject) => {
							return (
								<IonReorder key={page.order_index}>
									<IonItem>
										<IonLabel>{page.title}</IonLabel>
										<IonIcon slot="end" icon={menuOutline} />
									</IonItem>
								</IonReorder>
							);
						})}
					</IonReorderGroup>
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default TableOfContents;
