import {
	IonGrid,
	IonItem,
	IonLabel,
	IonButton,
	IonRow,
	IonCol,
	IonContent,
	IonSelect,
	IonSelectOption,
	IonInput,
	IonBadge,
	IonAlert,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonModal,
	IonButtons,
	IonToast,
} from "@ionic/react";
import React, { useEffect, useRef, useState, PropsWithChildren } from "react";
import "./UserSettings.scss";
import axiosConfig from "../../axiosConfig";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { OverlayEventDetail } from "@ionic/core/components";

const UserSettings = (props: PropsWithChildren<{ setUser: any }>) => {
	const [isLoading, setLoading] = useState(true);
	// const [isName, setName] = useState('')
	const [cookies, setCookie] = useCookies(["user"]);

	const [showAlert, setShowAlert] = useState(false);

	/* USER INFO */
	const [isFirstname, setFirstname] = useState("");
	const [isSurname, setSurname] = useState();
	const [isDay, setDay] = useState();
	const [isMonth, setMonth] = useState();
	const [isYear, setYear] = useState();

	const getData = () => {
		axiosConfig
			.post("/api/get_personal_info")
			.then(function (response) {
				setFirstname(response.data.firstname);
				setSurname(response.data.surname);
				setDay(response.data.day);
				setYear(response.data.year);
				setMonth(response.data.month);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		if (isLoading) {
			getData();
		}
	}, []);

	const modal = useRef<HTMLIonModalElement>(null);
	const input = useRef<HTMLIonInputElement>(null);
	const [showToastError, setShowToastError] = useState(false);
	const [showToastSuccess, setShowToastSuccess] = useState(false);

	function confirm() {
		modal.current?.dismiss(input.current?.value, "confirm");
	}

	function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
		if (ev.detail.role === "confirm") {
			setShowAlert(true);
		}
	}
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	const changeData = (info: any) => {
		return new Promise((resolve, reject) => {
			if (info) {
				axiosConfig
					.post("api/change_personal_info", {
						firstName: isFirstname,
						surname: isSurname,
						day: isDay,
						month: isMonth,
						year: isYear,
						password: info.password,
					})
					.then((response) => {
						resolve(response.status);
						axiosConfig
							.post("api/user/info")
							.then((response) => {
								props.setUser(response.data.data);
								setCookie("user", response.data.data, { path: "/" });
							})
							.catch((err) => {
								console.log(err);
							});
					});
			} else {
				reject(reject);
			}
		});
	};

	if (isLoading) {
		return <></>;
	}
	if (showAlert) {
		return (
			<IonContent>
				<IonToast
					isOpen={showToastError}
					onDidDismiss={() => setShowToastError(false)}
					color={"danger"}
					message="Wachtwoord is onjuist"
					duration={1500}
				/>
				<IonToast
					isOpen={showToastSuccess}
					onDidDismiss={() => setShowToastSuccess(false)}
					color={"success"}
					message="Gegevens zijn aangepast!"
					duration={3000}
				/>
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => setShowAlert(false)}
					header="Bevestiging"
					subHeader="Vul opnieuw je wachtwoord in"
					inputs={[
						{
							name: "password",
							placeholder: "Vul hier in...",
							type: "password",
						},
					]}
					buttons={[
						{
							text: "Opslaan",
							role: "check",
							handler: (data) => {
								changeData(data).then((response) => {
									if (response == 200) {
										setShowToastSuccess(true);
										setTimeout(function () {
											setShowAlert(false);
											// useEffect();
											getData();
										}, 1000);
										const date = new Date().toLocaleString().split("/");
										let day;
										const month = date[1];
										if (date[0].charAt(0) === "0") {
											day = date[0].substring(1);
										} else {
											day = date[0];
										}
										if (day == isDay && month == isMonth) {
											localStorage.setItem("birthday", String("true"));
										} else {
											localStorage.setItem("birthday", String("false"));
										}
									} else {
										setShowToastError(true);
									}
								});
								return false;
							},
						},
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {},
						},
					]}
				/>
			</IonContent>
		);
	}

	return (
		<a>
			<IonButton id="open-modal">Persoonlijke Gegevens</IonButton>
			<IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
				<IonContent>
					<form onSubmit={handleSubmit(confirm)}>
						<IonHeader>
							<IonToolbar>
								<IonButtons slot="start">
									<IonButton onClick={() => modal.current?.dismiss()}>Terug</IonButton>
								</IonButtons>
								<IonTitle>Persoonlijke gegevens</IonTitle>
								<IonButtons slot="end">
									<IonButton strong={true} type={"submit"}>
										Opslaan
									</IonButton>
								</IonButtons>
							</IonToolbar>
						</IonHeader>
						<IonGrid>
							<IonRow>
								{/*VOORNAAM*/}
								<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"5"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Voornaam</IonLabel>
										<IonInput
											{...register("firstname", {
												required: false,
												minLength: 2,
												maxLength: 16,
												pattern: /[a-z][a-z.\s-]{1,16}$/i,
											})}
											value={isFirstname}
											onIonInput={(e: any) => setFirstname(e.target.value)}
										></IonInput>
									</IonItem>
									<b>
										{errors.firstname && errors.firstname.type === "pattern" && (
											<IonBadge color="danger">Geen geldig voornaam.</IonBadge>
										)}
										{errors.firstname && errors.firstname.type === "minLength" && (
											<IonBadge color="danger">Minimaal 2 letters</IonBadge>
										)}
										{errors.firstname && errors.firstname.type === "maxLength" && (
											<IonBadge color="danger">Maximaal 16 letters</IonBadge>
										)}
									</b>
								</IonCol>

								{/*ACHTERNAAM*/}
								<IonCol sizeXs={"12"} sizeMd={"5"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Achternaam</IonLabel>
										<IonInput
											{...register("surname", {
												required: false,
												minLength: 2,
												maxLength: 16,
												pattern: /[a-z][a-z.\s-]{1,16}$/i,
											})}
											value={isSurname}
											onIonInput={(e: any) => setSurname(e.target.value)}
										></IonInput>
									</IonItem>
									<b>
										{errors.surname && errors.surname.type === "pattern" && (
											<IonBadge color="danger">Geen geldig achternaam.</IonBadge>
										)}
										{errors.surname && errors.surname.type === "minLength" && (
											<IonBadge color="danger">Minimaal 2 letters</IonBadge>
										)}
										{errors.surname && errors.surname.type === "maxLength" && (
											<IonBadge color="danger">Maximaal 16 letters</IonBadge>
										)}
									</b>
								</IonCol>
							</IonRow>
							<IonRow class={"birthdate"}>
								<IonCol class={"personal_info_general date"} sizeXs={"12"}>
									<IonItem>
										<h6>Geboortedatum</h6>
									</IonItem>
								</IonCol>
								{/*BIRTHDAY*/}
								<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"2"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Dag</IonLabel>
										<IonInput
											{...register("day", {
												required: true,
												pattern: /^(0?[1-9]|[1@2]\d|3[01])$/,
											})}
											value={isDay}
											onIonInput={(e: any) => setDay(e.target.value)}
										></IonInput>
									</IonItem>
									<b>
										{errors.day && errors.day.type === "required" && (
											<IonBadge color="danger">Dit veld is vereist.</IonBadge>
										)}
										{errors.day && errors.day.type === "pattern" && <IonBadge color="danger">Tussen de 1 en 31</IonBadge>}
									</b>
								</IonCol>
								{/*BIRTH MONTH*/}
								<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"4"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Maand</IonLabel>
										<IonSelect
											value={isMonth}
											onIonChange={(e: any) => setMonth(e.target.value)}
											{...register("month", {
												required: true,
											})}
											interface="action-sheet"
											placeholder={"Selecteer..."}
										>
											<IonSelectOption value={1}>Januari</IonSelectOption>
											<IonSelectOption value={2}>Februari</IonSelectOption>
											<IonSelectOption value={3}>Maart</IonSelectOption>
											<IonSelectOption value={4}>April</IonSelectOption>
											<IonSelectOption value={5}>Mei</IonSelectOption>
											<IonSelectOption value={6}>Juni</IonSelectOption>
											<IonSelectOption value={7}>Juli</IonSelectOption>
											<IonSelectOption value={8}>Augustus</IonSelectOption>
											<IonSelectOption value={9}>September</IonSelectOption>
											<IonSelectOption value={10}>Oktober</IonSelectOption>
											<IonSelectOption value={11}>November</IonSelectOption>
											<IonSelectOption value={12}>December</IonSelectOption>
										</IonSelect>
									</IonItem>
									<b>
										{errors.month && errors.month.type === "required" && (
											<IonBadge color="danger">Dit veld is vereist.</IonBadge>
										)}
									</b>
								</IonCol>
								{/*BIRTH YEAR*/}
								<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"2"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Jaar</IonLabel>
										<IonInput
											{...register("year", {
												required: true,
												pattern: /^(19|20)\d{2}$/,
											})}
											value={isYear}
											onIonInput={(e: any) => setYear(e.target.value)}
										></IonInput>
									</IonItem>
									<b>
										{errors.year && errors.year.type === "required" && (
											<IonBadge color="danger">Dit veld is vereist.</IonBadge>
										)}
										{errors.year && errors.year.type === "pattern" && (
											<IonBadge color="danger">Geen geldig geboortejaar (Vb. 2000)</IonBadge>
										)}
									</b>
								</IonCol>
							</IonRow>
						</IonGrid>
					</form>
				</IonContent>
				<div className={"fill"}></div>
			</IonModal>
		</a>
	);
};

export default UserSettings;
