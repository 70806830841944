import { useIonRouter } from "@ionic/react";
import "./Login.scss";
import React, { PropsWithChildren } from "react";
import { useCookies } from "react-cookie";
import axiosConfig from "../axiosConfig";
import { useHistory } from "react-router-dom";

const Logout = (props: PropsWithChildren<{ setUser: any; loggedIn: any; setLoggedIn: any }>) => {
	const router = useIonRouter();

	const [cookies, setCookie, removeCookie] = useCookies(["user"]);

	const history = useHistory();

	const logoutUser = () => {
		// Ability to log a user out
		axiosConfig
			.post("/api/logout")
			.then(function (response) {
				//Remove the token linked in the browser
				localStorage.removeItem("auth_token");
				localStorage.removeItem("birthday");
				removeCookie("user");
				props.setUser({ id: NaN, name: "Gast" });
				props.setLoggedIn(false);

				//Redirect to the login page after logging out
				router.push("/login");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const previousPage = () => {
		history.goBack();
	};

	props.loggedIn ? logoutUser() : previousPage();

	return <></>;
};

export default Logout;
