import { IonContent } from "@ionic/react";
import React, { PropsWithChildren } from "react";
import VerifyAccount from "../../components/Verify Account/verifyAccount";

const VerifyAccountPage = (props: PropsWithChildren) => {
	return (
		<>
			<IonContent class="center">
				<VerifyAccount></VerifyAccount>
			</IonContent>
		</>
	);
};

export default VerifyAccountPage;
