import "./ChapterView.scss";
import React, { useEffect, useRef, useState } from "react";
import axiosConfig from "../../axiosConfig";
import { Chapter, ContentType, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, Page } from "./Diary";
import TableOfContents from "./TableOfContents";
import {
	IonButton,
	IonButtons,
	IonFab,
	IonFabButton,
	IonFabList,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonTitle,
	IonToolbar,
	ItemReorderEventDetail,
	useIonAlert,
	useIonRouter,
	useIonToast,
} from "@ionic/react";
import PageView from "./PageView";
import {
	add,
	arrowBack,
	arrowForward,
	cameraSharp as photo,
	documentTextSharp as page,
	happySharp as sticker,
	textOutline as text,
	videocamSharp as video,
} from "ionicons/icons";
import { useCookies } from "react-cookie";
import DOMPurify from "dompurify";
import AddPhotoModal from "./Page/AddPhotoModal";
import StickerMenu from "./Page/StickerMenu";
import axios from "axios";

const ChapterView = () => {
	const router = useIonRouter();

	const [cookies, setCookie] = useCookies(["pages"]);

	const modal = useRef<HTMLIonModalElement>(null);
	const radioGroup = useRef<HTMLIonRadioGroupElement>(null);

	const addPhotoModal = useRef<HTMLIonModalElement>(null);

	const stickerMenu = useRef<HTMLIonModalElement>(null);

	const [newPageTitle, setNewPageTitle] = useState("");
	const [newPageType, setNewPageType] = useState("");

	const [chapter, setChapter] = useState<Chapter>();

	const [currentPage, setCurrentPage] = useState<Page>();
	const [allPages, setAllPages] = useState<Page[]>([]);

	const [currentBufferIndex, setCurrentBufferIndex] = useState(0);

	const diaryIndex = router.routeInfo.pathname.split("/")[2];
	const chapterIndex = router.routeInfo.pathname.split("/")[3];

	const previousButton = useRef<HTMLIonButtonElement>(null);
	const nextButton = useRef<HTMLIonButtonElement>(null);

	const [present] = useIonToast();

	const [presentAlert] = useIonAlert();
	const [showContentButton, setShowContentButton] = useState<boolean>();
	const [showVoorgeschiedenis, setShowVoorgeschiedenis] = useState<boolean>();

	const presentToast = (field: string) => {
		present({
			message: `${field} is verplicht`,
			position: "top",
			duration: 2500,
			color: "danger",
			cssClass: "verify-failed-toast",
		});
	};

	useEffect(() => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}`)
			.then((response) => {
				setChapter(response.data.chapter as Chapter);

				//Load last created page pages
				const mostRecentPageIndex = response.data.chapter.mostRecentPageIndex;
				axiosConfig.post(`/api/diary/${diaryIndex}/${chapterIndex}/${mostRecentPageIndex}`).then((response) => {
					setCurrentPage(response.data.page as Page);
				});

				loadPageIndexList();
				nextButton.current?.setAttribute("disabled", "true");

				//Check if the most recent page index is 0 meaning there are 0 or 1 pages then disable back button.
				if (mostRecentPageIndex === 0) {
					previousButton.current?.setAttribute("disabled", "true");
				}
			})
			.catch((err) => {
				console.log(err);
				if (err.response.status === 404 || err.response.status === 400) {
					window.setNextAlertMessage("Dit hoofdstuk bestaat niet.");
				} else if (err.response.status === 401) {
					window.setNextAlertMessage("Je bent niet ingelogd.");
					router.push("/login");
					return;
				}
				router.push("/dagboek/" + diaryIndex);
			});
	}, []);

	const loadPageIndexList = () => {
		// Get pages for page index menu
		axiosConfig.post(`/api/diary/${diaryIndex}/${chapterIndex}/pages`).then((response) => {
			setAllPages(response.data.pages as Page[]);
		});
	};

	const loadNextPage = () => {
		if (currentPage) {
			previousButton.current?.setAttribute("disabled", "false");
			loadPage(currentPage.order_index + 1);
		}
	};

	const loadPreviousPage = () => {
		if (currentPage) {
			nextButton.current?.setAttribute("disabled", "false");
			loadPage(currentPage.order_index - 1);
		}
	};

	const loadLastPage = () => {
		if (currentPage) {
			nextButton.current?.setAttribute("disabled", "true");
			previousButton.current?.setAttribute("disabled", "false");
			loadPage((currentPage.order_index = allPages.length));
		}
	};

	const reloadCurrentPage = (showLoadingModal = true) => {
		if (currentPage) {
			loadPage(currentPage.order_index, showLoadingModal);
		}
	};

	const loadPage = (pageIndex: number, showLoadingModal = true) => {
		if (currentPage) {
			if (pageIndex <= 0) previousButton.current?.setAttribute("disabled", "true");
			if (pageIndex >= allPages.length - 1) nextButton.current?.setAttribute("disabled", "true");

			axiosConfig
				.post(`/api/diary/${diaryIndex}/${chapterIndex}/${pageIndex}`, { loadingModel: showLoadingModal })
				.then((response) => {
					setCurrentPage(response.data.page as Page);
					if (response.data.page.type == "verhaal") {
						setShowContentButton(true);
					} else {
						setShowContentButton(false);
					}
					if (response.data.page.type == "voorgeschiedenis") {
						setShowVoorgeschiedenis(true);
					} else {
						setShowVoorgeschiedenis(false);
					}

					if (response.data.page.type == "plakkaart") {
						//loads the fillblanks function twice for fast and slow devices. Issue with loading too fast. But looks buggy if you wait long.
						setTimeout(function () {
							fillBlanksChapterView("plakkaart");
						}, 500);

						setTimeout(function () {
							fillBlanksChapterView("plakkaart");
						}, 2000);
					} else if (response.data.page.type == "voorgeschiedenis") {
						//loads the fillblanks function twice for fast and slow devices. Issue with loading too fast. But looks buggy if you wait long.
						setTimeout(function () {
							fillBlanksChapterView("voorgeschiedenis");
						}, 500);

						setTimeout(function () {
							fillBlanksChapterView("voorgeschiedenis");
						}, 2000);
					}
				});
		}
	};

	const fillBlanksChapterView = (pageType: string) => {
		let container = null;
		let loopCount = 0;

		if (pageType == "plakkaart") {
			container = document.getElementById("sticker_slot0")?.parentNode;
			loopCount = 8;
		}
		if (pageType == "voorgeschiedenis") {
			loopCount = 5;
		}

		for (let a = 0; a <= loopCount; a++) {
			if (pageType == "plakkaart") {
				const date = document.getElementById("sticker_date" + a);
				if (
					(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display == "inline" &&
					container?.childNodes[a].childNodes[0].childNodes.length == 2
				) {
					(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display = "none";
					if (date) {
						date.style.opacity = "1";
					}
				} else if (
					(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display == "none" &&
					container?.childNodes[a].childNodes[0].childNodes.length != 2
				) {
					(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display = "inline";
					if (date) {
						date.style.opacity = "0";
					}
				}
			} else if (pageType == "voorgeschiedenis") {
				let container = document.getElementById("sticker_slot" + a)?.parentNode;
				if (!container) {
					container = document.getElementById("text_slot" + a)?.parentNode;
				} else {
					if (
						(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display == "inline" &&
						container?.childNodes[a].childNodes[0].childNodes.length == 2
					) {
						(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display = "none";
					} else if (
						(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display == "none" &&
						container?.childNodes[a].childNodes[0].childNodes.length != 2
					) {
						(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display = "inline";
					}
				}
			}
		}
	};

	const createPage = () => {
		if (!newPageTitle) setNewPageTitle("");
		if (radioGroup.current?.value) {
			modal.current?.dismiss();
			axiosConfig
				.post(`/api/diary/${diaryIndex}/${chapterIndex}/create`, {
					title: DOMPurify.sanitize(newPageTitle),
					type: DOMPurify.sanitize(radioGroup.current?.value),
				})
				.then(() => {
					loadPageIndexList();
					axiosConfig
						.post(`/api/diary/${diaryIndex}/${chapterIndex}`)
						.then((response) => {
							setChapter(response.data.chapter as Chapter);

							//Load last created page pages
							const mostRecentPageIndex = response.data.chapter.mostRecentPageIndex;
							axiosConfig.post(`/api/diary/${diaryIndex}/${chapterIndex}/${mostRecentPageIndex}`).then((response) => {
								setCurrentPage(response.data.page as Page);
							});
						})
						.catch((err) => {
							console.log(err);
						});
					loadLastPage();
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			if (!newPageTitle) {
				presentToast("De titel van je nieuwe bladzijde.");
			} else {
				presentToast("Het type van je nieuwe bladzijde.");
			}
		}
	};

	const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/update_index`, {
				fromIndex: event.detail.from,
				toIndex: event.detail.to,
			})
			.then((response) => {
				reloadCurrentPage();
			})
			.catch((err) => {
				console.log(err);
			});
		event.detail.complete();
	};

	const createPageText = () => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/text/create`, {
				text: "Nieuw tekstblok",
			})
			.then((response) => {
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const openPhotoModal = () => {
		addPhotoModal.current?.present();
	};

	const closePhotoModal = () => {
		addPhotoModal.current?.dismiss();
	};

	const createPagePhoto = (image: any, orientation: Array<string>) => {
		const data = new FormData();

		const URL = `/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/photo/create`;

		data.append("name", "image");
		data.append("file", image);
		data.append("size", image["size"]);
		data.append("type", image["type"]);
		data.append("width", orientation[0]);
		data.append("height", orientation[1]);

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};

		axiosConfig
			.post(URL, data, config)
			.then((response) => {
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});

		closePhotoModal();
	};

	//Opens sticker menu
	const openStickerMenu = () => {
		stickerMenu.current?.present();
	};

	//closes sticker menu
	const closeStickerMenu = () => {
		stickerMenu.current?.dismiss();
	};

	const showAddContentButton = (condition: boolean) => {
		setShowContentButton(condition);
	};
	const showAddVoorgeschiedenis = (condition: boolean) => {
		setShowVoorgeschiedenis(condition);
	};

	//Adds sticker to page
	const createPageSticker = (sticker: number, height: number, width: number) => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/sticker/create`, {
				sticker: sticker,
				height: height,
				width: width,
			})
			.then((response) => {
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});

		closeStickerMenu();
	};

	const onDragEnd = (onDragEndParams: OnDragEndParams) => {
		switch (onDragEndParams.dragType) {
			case ContentType.TEXT:
				axiosConfig
					.post(`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/text/${onDragEndParams.id}/move`, {
						PosX: onDragEndParams.dragPos.x,
						PosY: onDragEndParams.dragPos.y,
						loadingModel: false,
					})
					.then((response) => {
						reloadCurrentPage(false);
					});
				break;
			case ContentType.IMAGE:
				axiosConfig
					.post(`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/photo/${onDragEndParams.id}/move`, {
						PosX: onDragEndParams.dragPos.x,
						PosY: onDragEndParams.dragPos.y,
						loadingModel: false,
					})
					.then((response) => {
						reloadCurrentPage(false);
					});
				break;
			//if the ContentType == STICKER
			case ContentType.STICKER:
				//Call axiosConfig to create a post request to sent the onDragEndParams to the sticker dragEnd route.
				//Route is found in api.php.
				axiosConfig
					.post(`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/sticker/${onDragEndParams.id}/move`, {
						PosX: onDragEndParams.dragPos.x,
						PosY: onDragEndParams.dragPos.y,
						loadingModel: false,
					})
					.then((response) => {
						reloadCurrentPage(false);
					});
				break;
		}
	};

	const onUpdateContent = (onEditContentParams: OnUpdateContentParams, data: any) => {
		switch (onEditContentParams.editType) {
			case ContentType.TEXT:
				axiosConfig
					.post(
						`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/text/${onEditContentParams.id}/update`,
						data,
					)
					.then((response) => {
						reloadCurrentPage();
					});
				break;
			case ContentType.IMAGE:
				axiosConfig
					.post(
						`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/photo/${onEditContentParams.id}/resize`,
						data,
					)
					.then((response) => {
						reloadCurrentPage(false);
					});
				break;
		}
	};

	const onDeleteContent = (onDeleteContentParams: OnDeleteContentParams) => {
		switch (onDeleteContentParams.deleteType) {
			case ContentType.TEXT:
				axiosConfig
					.post(
						`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/text/${onDeleteContentParams.id}/delete`,
					)
					.then((response) => {
						reloadCurrentPage();
					});
				break;
			case ContentType.STICKER:
				axiosConfig
					.post(
						`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/sticker/${onDeleteContentParams.id}/delete`,
					)
					.then((response) => {
						reloadCurrentPage();
					})
					.catch((e) => {
						console.log(e);
					});
				break;
			case ContentType.IMAGE:
				axiosConfig
					.post(
						`/api/diary/${diaryIndex}/${chapterIndex}/${currentPage?.order_index}/photo/${onDeleteContentParams.id}/delete`,
					)
					.then((response) => {
						reloadCurrentPage();
					})
					.catch((e) => {
						console.log(e);
					});
				break;
		}
	};

	return (
		<>
			<div id="main-page-child">
				<div className="side-border">
					<div />
				</div>

				{/*TODO: these are temporary buttons these actions should probably be done by swiping*/}
				<IonToolbar>
					<IonButton
						ref={previousButton}
						className="page-back-button"
						onClick={() => loadPreviousPage()}
						shape="round"
						size="small"
					>
						<IonIcon icon={arrowBack}></IonIcon>
					</IonButton>

					<IonButton
						ref={nextButton}
						className="page-forward-button"
						onClick={() => loadNextPage()}
						shape="round"
						size="small"
					>
						<IonIcon icon={arrowForward}></IonIcon>
					</IonButton>

					<IonButton
						className="add_page_button"
						onClick={() => modal.current?.present()}
						shape="round"
						slot="end"
						color="success"
						size="small"
					>
						pagina toevoegen
						<IonIcon slot="end" icon={add}></IonIcon>
					</IonButton>
				</IonToolbar>

				<div className="page-view">
					{currentPage && (
						<PageView
							page={currentPage}
							diaryIndex={diaryIndex}
							chapterIndex={chapterIndex}
							currentBufferIndex={currentBufferIndex}
							deleteFunc={() => {
								loadPreviousPage();
							}}
							reloadFunc={() => {
								reloadCurrentPage();
							}}
							onDragEnd={onDragEnd}
							onUpdateContent={onUpdateContent}
							onDeleteContent={onDeleteContent}
							showDelete={true}
							showAddContentButton={(condition) => {
								showAddContentButton(condition);
							}}
							showAddVoorgeschiedenis={(condition) => {
								showAddVoorgeschiedenis(condition);
							}}
						/>
					)}
				</div>
			</div>

			{showContentButton && (
				<IonFab slot="fixed" horizontal="end" vertical="bottom" className="add_fab">
					<IonFabButton color="tertiary">
						<IonIcon icon={add}></IonIcon>
					</IonFabButton>
					<IonFabList side="start">
						<IonFabButton
							color="primary"
							onClick={() => {
								createPageText();
							}}
						>
							<IonIcon icon={text}></IonIcon>
						</IonFabButton>
						<IonFabButton
							color="secondary"
							onClick={() => {
								openStickerMenu();
							}}
						>
							<IonIcon icon={sticker}></IonIcon>
						</IonFabButton>
						<IonFabButton
							color="tertiary"
							onClick={() => {
								openPhotoModal();
							}}
						>
							<IonIcon icon={photo}></IonIcon>
						</IonFabButton>
					</IonFabList>
				</IonFab>
			)}
			<IonModal ref={modal} className="add-page-modal">
				<div className="wrapper">
					<IonHeader>
						<IonToolbar>
							<IonTitle>Bladzijde toevoegen</IonTitle>
						</IonToolbar>
					</IonHeader>
					<div className="ion-padding">
						<IonItem>
							<IonLabel position="stacked">Wat moet de titel van je nieuwe bladzijde zijn?</IonLabel>
							<IonInput onIonInput={(e: any) => setNewPageTitle(DOMPurify.sanitize(e.target.value))} />
						</IonItem>

						<IonItem>
							<IonLabel position="stacked" className="create-page-radio-label">
								Wat moet het type van je nieuwe bladzijde zijn?
							</IonLabel>
							{showVoorgeschiedenis ? (
								<IonRadioGroup ref={radioGroup}>
									<IonItem>
										<IonLabel>Mijn Voorgeschiedenis</IonLabel>
										<IonRadio slot="end" value="voorgeschiedenis"></IonRadio>
									</IonItem>
								</IonRadioGroup>
							) : (
								<IonRadioGroup ref={radioGroup}>
									<IonItem>
										<IonLabel>Mijn verhaal</IonLabel>
										<IonRadio slot="end" value="verhaal"></IonRadio>
									</IonItem>

									<IonItem>
										<IonLabel>Mijn plakkaart</IonLabel>
										<IonRadio slot="end" value="plakkaart"></IonRadio>
									</IonItem>

									<IonItem>
										<IonLabel>Mijn tekenkaart</IonLabel>
										<IonRadio slot="end" value="tekenkaart"></IonRadio>
									</IonItem>
								</IonRadioGroup>
							)}
						</IonItem>
					</div>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => modal.current?.dismiss()}>Annuleren</IonButton>
						</IonButtons>
						<IonButtons slot="end">
							<IonButton onClick={() => createPage()}>Toevoegen</IonButton>
						</IonButtons>
					</IonToolbar>
				</div>
			</IonModal>
			<IonModal ref={addPhotoModal} className="add-photo-modal">
				<AddPhotoModal
					savePhotoModal={(image, orientation) => createPagePhoto(image, orientation)}
					closePhotoModal={() => closePhotoModal()}
				/>
			</IonModal>
			<IonModal ref={stickerMenu}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Selecteer sticker om te plakken</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => closeStickerMenu()}>Sluiten</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<StickerMenu
					saveSticker={(sticker, height, width) => createPageSticker(sticker, height, width)}
					closeStickerMenu={() => closeStickerMenu()}
				/>
			</IonModal>
			<TableOfContents allPages={allPages} handleReorder={handleReorder} />
		</>
	);
};

export default ChapterView;
