import PageView from "../../components/Diary/PageView";
import "../../components/Diary/PageView.scss";
import "../../components/Diary/ChapterView.scss";
import "../../components/Diary/PageTypes/PageType.scss";
import { Page } from "../../components/Diary/Diary";

const HandleidingView = () => {
	const HandleidingPage: Page = {
		title: "Handleiding",
		order_index: 0,
		type: "Handleiding",
		PageTexts: [],
		PageMedia: [],
		PageStickers: [],
	};

	return (
		<>
			<div id="main-page-child">
				<div className="page-view ion-padding-top">
					<PageView
						page={HandleidingPage}
						diaryIndex={""}
						chapterIndex={""}
						currentBufferIndex={0}
						deleteFunc={() => {}}
						reloadFunc={() => {}}
						showDelete={false}
						onDragEnd={() => {}}
						onUpdateContent={() => {}}
						onDeleteContent={() => {}}
						showAddContentButton={() => {}}
						showAddVoorgeschiedenis={() => {}}
					/>
				</div>
			</div>
		</>
	);
};

export default HandleidingView;
