import { IonContent } from "@ionic/react";
import "./NewPassword.scss";
import PasswordRequestForm from "../components/PasswordRequestForm";
import React, { PropsWithChildren } from "react";

const PasswordRequest = (props: PropsWithChildren) => {
	return (
		<>
			<IonContent className="new-password-form-container">
				<PasswordRequestForm />
			</IonContent>
		</>
	);
};

export default PasswordRequest;
