import { IonContent } from "@ionic/react";
import "./Register.scss";
import RegisterForm from "../components/RegisterForm";
import React, { PropsWithChildren } from "react";

const Register = (props: PropsWithChildren) => {
	return (
		<>
			<IonContent className="center register-form-container">
				<RegisterForm></RegisterForm>
			</IonContent>
		</>
	);
};

export default Register;
