import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonIcon,
	IonInput,
	IonItem,
	useIonAlert,
	useIonRouter,
	useIonToast,
} from "@ionic/react";
import React, { useEffect, useRef } from "react";
import axiosConfig from "../../axiosConfig";
import Diary from "./Diary";
import { add, pencil } from "ionicons/icons";
import DiaryDeleteButton from "./DiaryDeleteButton";
import "./DiaryView.scss";
import ChapterListView from "./ChapterListView";
import AboutMeLink from "./AboutMeLink";
import HandleidingLink from "./HandleidingLink";

const DiaryView = () => {
	const router = useIonRouter();

	const [present] = useIonToast();

	const [presentAlert] = useIonAlert();

	const titleInput = useRef<HTMLIonInputElement>(null);

	const [prevDiary, setPrevDiary] = React.useState<Diary | null>(null);
	const [diary, setDiary] = React.useState<Diary | null>(null);

	const [editingTitle, setEditingTitle] = React.useState(false);

	useEffect(() => {
		// Make sure to wait a little bit so titleInput exists
		setTimeout(() => {
			titleInput.current?.setFocus();
		}, 10);
	}, [editingTitle]);

	useEffect(() => {
		const diaryIndex = router.routeInfo.pathname.split("/")[2];

		axiosConfig
			.post(`/api/diary/${diaryIndex}`)
			.then((response) => {
				setDiary(response.data.diary as Diary);
				setPrevDiary(JSON.parse(JSON.stringify(response.data.diary)) as Diary);
			})
			.catch((err) => {
				console.log(err);
				if (err.response.status === 404 || err.response.status === 400) {
					window.setNextAlertMessage("Dit dagboek bestaat niet.");
				} else if (err.response.status === 401) {
					window.setNextAlertMessage("Je bent niet ingelogd.");
					router.push("/login");
					return;
				}
				router.push("/");
			});
	}, []);

	const onTitleBlur = () => {
		setEditingTitle(false);
		if (diary?.title != prevDiary?.title) {
			axiosConfig
				.post(`/api/diary/${diary?.index}/update`, {
					title: diary?.title,
				})
				.then((response) => {
					setPrevDiary(JSON.parse(JSON.stringify(diary)));
				})
				.catch((err) => {
					console.log(err);
					setDiary(prevDiary);

					if (err.response.status === 422) {
						present({
							message: "De titel moet tussen de 3 en 50 tekens lang zijn.",
							duration: 3000,
							position: "top",
							color: "danger",
							cssClass: "verify-failed-toast",
						});
					}
				});
		}
	};

	const addChapter = () => {
		presentAlert({
			header: "Wat moet de titel van je nieuwe hoofdstuk zijn?",
			inputs: [
				{
					name: "title",
					type: "text",
					placeholder: "Titel",
				},
			],
			buttons: [
				{
					text: "Annuleren",
					role: "cancel",
				},
				{
					text: "Toevoegen",
					handler: (alertData) => {
						axiosConfig
							.post(`/api/diary/${diary?.index}/create`, {
								title: alertData.title,
							})
							.then((response) => {
								setDiary(response.data.diary as Diary);
								// Scroll to bottom
								// Wait for rerender
								setTimeout(() => {
									(
										document.evaluate(
											"//ion-card-title[text()='" + alertData.title + "']",
											document,
											null,
											XPathResult.FIRST_ORDERED_NODE_TYPE,
										).singleNodeValue as HTMLElement
									).scrollIntoView({ behavior: "smooth" });
								}, 500);
							})
							.catch((err) => {
								console.log(err);
								if (err.response.status === 422) {
									present({
										message: "De titel moet tussen de 3 en 50 tekens lang zijn.",
										duration: 3000,
										position: "top",
										color: "danger",
										cssClass: "verify-failed-toast",
									});
								}
							});
					},
				},
			],
		});
	};

	const title = editingTitle ? (
		<IonItem counter={true}>
			<IonInput
				ref={titleInput}
				minlength={3}
				maxlength={50}
				autofocus={true}
				value={diary?.title}
				onIonChange={(e) => {
					if (diary) {
						diary.title = e.detail.value!;
						setDiary(diary);
					}
				}}
				onIonBlur={() => onTitleBlur()}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						titleInput.current?.setBlur();
					}
				}}
			/>
		</IonItem>
	) : (
		diary?.title
	);

	return (
		<>
			{diary != null && (
				<>
					<IonCard className="diary-header">
						<IonCardHeader>
							<IonCardTitle class="diary-title">
								{title}
								<div>
									<IonButton shape="round" className="rounded-circle" onClick={addChapter}>
										<IonIcon icon={add} />
										Hoofdstuk toevoegen
									</IonButton>
								</div>
								<div>
									<IonButton onClick={() => setEditingTitle(!editingTitle)}>
										<IonIcon icon={pencil} />
									</IonButton>
									<DiaryDeleteButton
										diary={diary}
										func={() => {
											router.push("/");
										}}
									/>
								</div>
							</IonCardTitle>
						</IonCardHeader>

						<IonCardContent>
							<AboutMeLink diary={diary.index} />
							<HandleidingLink />

							{diary.chapters.map((chapter, index) => {
								if (index == 0) {
									return (
										<ChapterListView
											key={chapter.index}
											diary={diary}
											chapter={chapter}
											setDiary={setDiary}
											setPrevDiary={setPrevDiary}
											showDelete={false}
										/>
									);
								} else {
									return (
										<ChapterListView
											key={chapter.index}
											diary={diary}
											chapter={chapter}
											setDiary={setDiary}
											setPrevDiary={setPrevDiary}
											showDelete={true}
										/>
									);
								}
							})}
						</IonCardContent>
					</IonCard>
				</>
			)}
		</>
	);
};

export default DiaryView;
