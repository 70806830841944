import { IonItem, IonInput, IonLabel, IonButton, IonIcon, IonText, useIonRouter, IonChip } from "@ionic/react";

import axiosConfig from "../axiosConfig";

import DOMPurify from "dompurify";

import { arrowForward } from "ionicons/icons";

import * as yup from "yup";
import React, { useState, useEffect } from "react";

import "./ChangePasswordForm.scss";

import addEnterEvent from "../submitFormOnEnter";

const ChangePasswordForm: React.FC = () => {
	const router = useIonRouter();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [errorMessage, setErrorMessage] = useState("");

	// With useEffect(), the code below will only be executed once
	useEffect(() => {
		// The code below is used to check if the user asked permission to change the password, and to give you 15 minutes to write the code you got, otherwise they will be send to the page where you ask for permission.
		axiosConfig
			.post("/api/cache_email_exists", {
				headers: {
					Accept: "application/json",
				},
			})
			.then(function (response) {
				if (!response.data.answer) {
					router.push("/wachtwoord-aanvragen");
				}

				setTimeout(() => {
					router.push("/wachtwoord-aanvragen");
				}, 1000 * response.data.time);
			});

		addEnterEvent();
	}, []);

	const validationSchema = yup.object().shape({
		"Nieuw wachtwoord": yup.string().required().min(8).max(20),
		confirmPassword: yup.string().oneOf([yup.ref("Nieuw wachtwoord"), null], "Wachtwoorden komen niet overeen"),
	});

	//With this function, the user can change their password
	const AskPermission = () => {
		setPassword(DOMPurify.sanitize(password));
		setConfirmPassword(DOMPurify.sanitize(confirmPassword));

		validationSchema
			.validate({ "Nieuw wachtwoord": password, confirmPassword: confirmPassword })
			.then(() => {
				axiosConfig
					.post("/api/check_cache_new_password", {
						headers: {
							Accept: "application/json",
						},
						new_password: password,
						new_password_confirmed: confirmPassword,
					})
					.then(function (response) {
						window.setNextAlertMessage("Wachtwoord succesvol gewijzigd");
						window.nextAlertColor = "success";
						router.push("/login");
					});
			})
			.catch((err) => {
				setErrorMessage(err.message);
			});
	};
	return (
		<form className="change-password-form">
			<IonText>
				<h1>Nieuw wachtwoord aanvragen</h1>
			</IonText>
			<IonChip className="ion-margin-top" color="danger">
				{errorMessage}
			</IonChip>
			<IonItem>
				<IonLabel position="floating">Nieuw wachtwoord</IonLabel>
				<IonInput clearOnEdit={false} type="password" onIonInput={(e: any) => setPassword(e.target.value)} />
			</IonItem>
			<IonItem>
				<IonLabel position="floating">Wachtwoord bevestigen</IonLabel>
				<IonInput clearOnEdit={false} type="password" onIonInput={(e: any) => setConfirmPassword(e.target.value)} />
			</IonItem>
			<IonButton onClick={() => AskPermission()} form-submitter>
				<IonIcon icon={arrowForward} />
				Reset wachtwoord
			</IonButton>
		</form>
	);
};

export default ChangePasswordForm;
