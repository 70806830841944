import { clampPos, CreateDraggable, PlaceableItemProps } from "./PlaceableItem";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { ContentType, OnDragEndParams, OnUpdateContentParams, OnDeleteContentParams, PageMedia } from "../Diary";
import { IonImg, IonIcon, IonButton, IonRange } from "@ionic/react";
import { resizeOutline as resize, move, closeSharp as close, trash } from "ionicons/icons";
import AlertButton from "../../AlertButton";
import "./PageImageView.scss";

const PageImageView = (
	props: PropsWithChildren<{
		pageMedia: PageMedia;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	const divRef = useRef<HTMLDivElement>(null);

	const imageRef = useRef<HTMLIonImgElement>(null);

	const range = useRef<HTMLIonRangeElement>(null);

	const [editing, setEditing] = useState(false);

	const [deleteDown, setDeleteDown] = useState(false);

	const [args, setArgs] = useState<PlaceableItemProps | null>(null);

	const [moveDown, setMoveDown] = useState(false);
	const [resizeDown, setResizeDown] = useState(false);

	const photo = document.getElementById(`resizable-media-${props.pageMedia.id}`);

	const [photoDimensions, setPhotoDimensions] = useState<number[]>([
		props.pageMedia.size * props.pageMedia.Width,
		props.pageMedia.size * props.pageMedia.Height,
	]);
	const [photoRatio, setPhotoRatio] = useState(0.25);

	let image: any;

	useEffect(() => {
		// NOTE: the below code not hot reload, refresh the page to see changes
		if (divRef.current) {
			let _args = args;
			_args = new PlaceableItemProps(divRef.current);

			image = document.getElementById("IonI");

			_args.onClick = () => {
				if (deleteDown) return;
				console.log("clicked");
				setEditing(true);
				divRef.current?.focus();
				if (image != null) {
					image.focus();
				}
			};
			divRef.current?.addEventListener("blur", () => {
				onBlur();
			});

			if (image != null) {
				image.addEventListener("blur", () => {
					onBlur();
				});
			}

			_args.onDragEnd = () => {
				props.onDragEnd({
					dragType: ContentType.IMAGE,
					id: props.pageMedia.id,
					dragPos: _args?.currentPos ?? { x: 0, y: 0 },
				});
			};

			// Set the position of the text to position that we received from the server
			_args.currentPos = { x: props.pageMedia.Pos.X, y: props.pageMedia.Pos.Y };

			CreateDraggable(_args);

			setArgs(_args);
		}

		if (range.current) {
			range.current.value = props.pageMedia.size * 100;
		}
	}, []);

	useEffect(() => {
		args?.dragGesture?.enable(!editing);
	}, [editing]);

	const onBlur = () => {
		if (resizeDown || moveDown || deleteDown) {
			if (resizeDown || deleteDown) setEditing(true);
			return;
		}

		setEditing(false);

		if (args && divRef.current && props.pageMedia.index) {
			const pos = clampPos(args?.currentPos ?? { x: 0, y: 0 }, divRef.current as HTMLDivElement);
			divRef.current.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
			args.currentPos = pos;
			setArgs(args);
			props.onDragEnd({
				dragType: ContentType.IMAGE,
				id: props.pageMedia.index,
				dragPos: args?.currentPos ?? { x: 0, y: 0 },
			});
		}
	};

	const resetMoveDown = () => {
		if (moveDown) {
			setMoveDown(false);
			photo?.focus();
		}
	};

	const resetResizeDown = () => {
		if (resizeDown) {
			setResizeDown(false);
			photo?.focus();
		}
	};

	const saveResize = () => {
		props.onUpdateContent(
			{
				editType: ContentType.IMAGE,
				id: props.pageMedia.id,
			},
			{
				newSize: photoRatio,
				loadingModel: false,
			},
		);
	};

	const deleteImageBlock = () => {
		setEditing(false);
		setDeleteDown(false);
		try {
			props.onDeleteContent({
				deleteType: ContentType.IMAGE,
				id: props.pageMedia.id,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const dimensionChange = (e: any) => {
		const percentage = e.detail.value / 100;

		setPhotoDimensions([percentage * props.pageMedia.Width, percentage * props.pageMedia.Height]);
		setPhotoRatio(percentage);
	};

	return (
		<div
			className={`placeable-item resizable-media-${props.pageMedia.id}` + (editing ? " placeable-editing" : "")}
			id={`resizable-media-${props.pageMedia.id}`}
			ref={divRef}
			style={{
				transform: `translate(${props.pageMedia.Pos.X}px, ${props.pageMedia.Pos.Y}px)`,
				width: `${photoDimensions[0]}px`,
				height: `${photoDimensions[1]}px`,
			}}
			onBlur={onBlur}
			tabIndex={1}
		>
			<AlertButton
				class={"hidden-button delete-button button-round"}
				header={"Weet je zeker dat je deze foto wilt verwijderen?"}
				fill="solid"
				color="danger"
				onMouseDown={() => setDeleteDown(true)}
				onMouseUp={() => setDeleteDown(false)}
				func={() => deleteImageBlock()}
			>
				<IonIcon icon={trash} />
			</AlertButton>
			<IonButton
				shape="round"
				className="hidden-button move-button"
				color="success"
				onPointerDown={() => {
					args?.dragGesture?.enable(true);
					setMoveDown(true);
				}}
				onPointerUp={() => {
					resetMoveDown();
				}}
			>
				<IonIcon icon={move} />
			</IonButton>
			<IonButton
				shape="round"
				className="hidden-button resize-button"
				color={resizeDown ? "danger" : "primary"}
				onPointerDown={() => {
					if (resizeDown) {
						resetResizeDown();
						range.current?.classList.remove("visible");
					} else {
						setResizeDown(true);
						range.current?.classList.add("visible");
					}
				}}
			>
				<IonIcon icon={resizeDown ? close : resize} />
			</IonButton>

			<IonImg
				ref={imageRef}
				src={`https://online.stichtingdapperkind.nl${props.pageMedia.url}`}
				draggable="false"
				className="page-media-image"
				id="IonI"
			/>

			<IonRange
				className="h-slider"
				onIonChange={(e) => dimensionChange(e)}
				min={25}
				ref={range}
				onIonBlur={(e) => {
					range.current?.classList.remove("visible");
					saveResize();
					resetResizeDown();
				}}
			/>
		</div>
	);
};

export default PageImageView;
