import PageView from "../../components/Diary/PageView";
import "../../components/Diary/PageView.scss";
import "../../components/Diary/ChapterView.scss";
import "../../components/Diary/PageTypes/PageType.scss";
import { useIonRouter } from "@ionic/react";
import { PropsWithChildren } from "react";
import { Page } from "../../components/Diary/Diary";
import React from "react";
// import Background from "../../resources/page_backgrounds/verhaal.png";

const AboutMeView = () => {
	const router = useIonRouter();

	const diaryIndex = router.routeInfo.pathname.split("/")[2];

	const AboutMePage: Page = {
		title: "Over mij",
		order_index: 0,
		type: "Over mij",
		PageTexts: [],
		PageMedia: [],
		PageStickers: [],
	};

	return (
		<>
			<div id="main-page-child">
				<div className="page-view ion-padding-top">
					<PageView
						page={AboutMePage}
						diaryIndex={diaryIndex}
						chapterIndex={""}
						currentBufferIndex={0}
						deleteFunc={() => {}}
						reloadFunc={() => {}}
						showDelete={false}
						onDragEnd={() => {}}
						onUpdateContent={() => {}}
						onDeleteContent={() => {}}
						showAddContentButton={() => {}}
						showAddVoorgeschiedenis={() => {}}
					/>
				</div>
			</div>
		</>
	);
};

export default AboutMeView;
