import Sticker from "../Sticker Overview/Sticker";

export default interface Diary {
	title: string;
	index: number;
	chapters: Chapter[];
	coverPhoto: string;
}

export interface Chapter {
	title: string;
	pageCount: number;
	pageBufferCount: number;
	mostRecentPageIndex: number;
	index: number;
	updated_at: string;
}

export interface Page {
	title: string;
	order_index: number;
	type: string;
	PageTexts: PageText[];
	PageMedia: PageMedia[];
	PageStickers: PageSticker[];
}

export interface PageText {
	id: number;
	text: string;
	Pos: { X: number; Y: number };
	SlotNumber: number;
}

export interface PageMedia {
	id: number;
	index: number;
	type: string;
	title: string;
	url: string;
	Pos: { X: number; Y: number };
	Width: number;
	Height: number;
	size: number;
}

export interface PageSticker {
	id: number;
	Sticker: Sticker;
	Pos: { X: number; Y: number };
	Width: number;
	Height: number;
	Date: string;
	customDate: string;
	SlotNumber: number;
}

export enum ContentType {
	TEXT = "text",
	IMAGE = "image",
	VIDEO = "video",
	STICKER = "sticker",
}

export type OnDragEndParams = {
	dragType: ContentType;
	id: number;
	dragPos: { x: number; y: number };
};

export type OnUpdateContentParams = {
	id?: number;
	editType: ContentType;
};

export type OnDeleteContentParams = {
	id: number;
	deleteType: ContentType;
};
