import { useEffect, useRef, useState } from "react";
import { UseOnDraw } from "./Hooks";
import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { brushOutline, colorPaletteOutline, saveOutline, trashOutline } from "ionicons/icons";
import axiosConfig from "../../axiosConfig";

const Canvas = ({ page }) => {
	// this var opens the router function which is used to get the current path
	const router = useIonRouter();

	// three variables to get the "page, chapter and diary"
	const pageIndex = page.order_index;
	const diaryIndex = router.routeInfo.pathname.split("/")[2];
	const chapterIndex = router.routeInfo.pathname.split("/")[3];

	// creating a few refs to be used in the canvas file
	var color = useRef(null);
	var size = useRef(null);
	var colors = [];

	var canvasSave = useRef(null);

	/**
	 * fill array of colors randomly
	 */
	useEffect(() => {
		for (let index = 0; index <= 9; index++) {
			for (let index2 = 0; index2 <= 9; index2++) {
				var color2 = Math.floor(Math.random() * 16777215).toString(16);
				colors.push("#" + ("000000" + color2.toString(16)).slice(-6));
			}
		}
	}, []);

	const { onMouseDown, setCanvasRef } = UseOnDraw(onDraw);

	/**
	 * run this function to update the drawing color
	 */
	function changeColor(newColor) {
		color.current = newColor;
		toggleColorPalette();
	}

	// this function runs when you start drawing
	function onDraw(ctx, point, prevPoint) {
		if (!color.current) color.current = "black";
		if (!size.current) size.current = 50;

		var brush = document.getElementById("brushSize");
		if (brush.style.display == "block") toggleBrushSize();

		drawLine(prevPoint, point, ctx, color.current, size.current);
	}

	/**
	 * this function creates the dots and lines between (aka drawing)
	 */
	function drawLine(start, end, ctx, color, width) {
		start = start ?? end;
		ctx.beginPath();
		ctx.lineWidth = width;
		ctx.strokeStyle = color;
		ctx.moveTo(start.x, start.y);
		ctx.lineTo(end.x, end.y);
		ctx.stroke();

		ctx.fillStyle = color;
		ctx.beginPath();
		ctx.arc(start.x, start.y, width / 2, 0, 2 * Math.PI);
		ctx.fill();
	}

	/**
	 * this function sets the canvas size
	 */
	useEffect(() => {
		var canvas = document.getElementById("myCanvas");
		if (canvas) {
			canvas.style.width = "100%";
			canvas.style.height = "93%";
			canvas.style.borderRadius = "5px";
			canvas.width = canvas.offsetWidth;
			canvas.height = canvas.offsetHeight;
		} else {
			console.log("cannot find the canvas");
		}
	}, []);

	/**
	 * this function creates the grid for the color palette
	 */
	useEffect(() => {
		var colorPalette = document.getElementById("colorPalette");
		var index = 0;

		for (var row = 1; row <= 10; row++) {
			var newRow = document.createElement("div");
			newRow.id = "row" + row;
			newRow.style.marginLeft = "4px";
			newRow.style.height = "60px";
			colorPalette.appendChild(newRow);

			for (var col = 1; col <= 10; col++) {
				var newCol = document.createElement("div");
				newCol.id = colors[index];
				newCol.classList.add("paletteCol");
				newCol.style.backgroundColor = colors[index];

				newCol.style.cursor = "pointer";
				newCol.addEventListener("click", function () {
					changeColor(this.id);
				});

				newRow.appendChild(newCol);

				index = index + 1;
			}
		}
	}, []);

	/**
	 * this function adjusts the brush size value display and sets the size variable for coloring
	 */
	useEffect(() => {
		var slider = document.getElementById("brushSlider");
		var output = document.getElementById("brushValue");

		output.innerHTML = slider.value;

		slider.oninput = function () {
			output.innerHTML = this.value;
			size.current = this.value;
		};
	}, []);

	function toggleColorPalette() {
		var palette = document.getElementById("colorPalette");
		var brush = document.getElementById("brushSize");

		if (palette.style.display == "none") {
			palette.style.display = "block";
			brush.style.display = "none";
		} else {
			palette.style.display = "none";
		}
	}

	function toggleBrushSize() {
		var palette = document.getElementById("colorPalette");
		var brush = document.getElementById("brushSize");

		if (brush.style.display == "none") {
			brush.style.display = "block";
			palette.style.display = "none";
		} else {
			brush.style.display = "none";
		}
	}

	/**
	 * function that loads image from database if it exists and creates a new image on the webpage
	 */
	useEffect(() => {
		if (document.getElementById("loadedIMG")?.src) return;

		axiosConfig
			.post(`/api/dairy/${diaryIndex}/${chapterIndex}/${pageIndex}/drawing`)
			.then((response) => {
				if (response.data?.drawing?.drawing) {
					drawImage(response.data.drawing.drawing);
					disableSaveButton();
					enableRemoveButton();
				}
				// if there is no image found enable the save button
				// this is to prevent clicking before this use effect is going off
				else {
					enableSaveButton();
					disableRemoveButton();
				}
			})
			.catch((err) => {
				console.log(err, "err on load");
			});
	}, []);

	/**
	 * function to send canvas image data to the server
	 * on response run function to create a new image and load url from api into the png
	 */
	function saveCanvas() {
		var canvas = document.getElementById("myCanvas");
		const drawingURL = canvas.toDataURL("image/png");

		// send api call with @drawing as a data tag
		axiosConfig
			.post(`/api/dairy/${diaryIndex}/${chapterIndex}/${pageIndex}/drawing/create`, {
				drawing: drawingURL,
			})
			.then((response) => {
				if (document.getElementById("loadedIMG") && response.data?.drawing?.drawing) {
					document.getElementById("loadedIMG").src = response.data.drawing.drawing;
					disableSaveButton();
					enableRemoveButton();
				} else if (response.data?.drawing?.drawing) {
					drawImage(response.data.drawing.drawing);
					disableSaveButton();
					enableRemoveButton();
				}
			})
			.catch((err) => {
				console.log(err, "err on save");
			});
	}

	function emptyCanvas() {
		axiosConfig
			.post(`/api/dairy/${diaryIndex}/${chapterIndex}/${pageIndex}/drawing/delete`)
			.then(() => {
				deleteOldCanvas();
				enableSaveButton();
				disableRemoveButton();
			})
			.catch((err) => {
				console.log(err, "err on delete");
			});
	}

	/**
	 * function that creates an image tag and loads an image into it
	 * also disables the canvas
	 */
	function drawImage(drawing) {
		var canvas = document.getElementById("myCanvas");
		var container = document.getElementById("canvasPage");

		var savedIMG = document.createElement("img");

		savedIMG.id = "loadedIMG";
		savedIMG.style.width = canvas.style.width;
		savedIMG.style.height = canvas.style.height;
		savedIMG.style.border = "1px solid black";
		savedIMG.src = drawing;

		canvas.style.display = "none";
		const context = canvas.getContext("2d");
		context.clearRect(0, 0, canvas.width, canvas.height);

		container.appendChild(savedIMG);
	}

	/**
	 * function that deletes the loadedIMG element from a saved canvas
	 * turns the canvas back on from the save turning it off
	 */
	function deleteOldCanvas() {
		var canvas = document.getElementById("myCanvas");
		var loadedImg = document.getElementById("loadedIMG").remove();
		canvas.style.display = "block";
	}

	/**
	 * function that disabled the button for saving and removing
	 */
	function disableSaveButton() {
		var btn = document.getElementById("saveButton");
		btn.disabled = "true";
	}

	function enableSaveButton() {
		var btn = document.getElementById("saveButton");
		btn.disabled = "false";
	}

	function disableRemoveButton() {
		var btn = document.getElementById("removeButton");
		btn.disabled = "true";
	}

	function enableRemoveButton() {
		var btn = document.getElementById("removeButton");
		btn.disabled = "false";
	}

	return (
		<div id="canvasPage" style={{ height: "100%" }}>
			<IonButton
				shape="round"
				color="primary"
				size="medium"
				onClick={() => {
					toggleColorPalette();
				}}
			>
				<IonIcon icon={colorPaletteOutline}></IonIcon>
			</IonButton>
			<div
				id={"colorPalette"}
				style={{
					display: "none",
				}}
			></div>

			<IonButton
				shape="round"
				color="primary"
				size="medium"
				onClick={() => {
					toggleBrushSize();
				}}
			>
				<IonIcon icon={brushOutline}></IonIcon>
			</IonButton>
			<div
				id={"brushSize"}
				style={{
					display: "none",
				}}
			>
				<input id={"brushSlider"} className={"slider"} type={"range"} min={"1"} max={"150"} defaultValue={"50"} />
				<p>
					Kwast grootte: <span id="brushValue">100</span>
				</p>
			</div>

			<IonButton
				id="saveButton"
				shape="round"
				color="primary"
				size="medium"
				disabled="true"
				onClick={() => {
					saveCanvas();
				}}
			>
				<IonIcon icon={saveOutline}></IonIcon>
			</IonButton>

			<IonButton
				id="removeButton"
				shape="round"
				color="primary"
				size="medium"
				onClick={() => {
					emptyCanvas();
				}}
			>
				<IonIcon icon={trashOutline}></IonIcon>
			</IonButton>

			<canvas id="myCanvas" onMouseDown={onMouseDown} style={canvasStyle} ref={setCanvasRef} />
		</div>
	);
};

export default Canvas;

const canvasStyle = {
	border: "1px solid black",
};
