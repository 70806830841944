import { IonItem, IonInput, IonLabel, IonButton, IonIcon, IonText, useIonRouter, IonChip } from "@ionic/react";

import axiosConfig from "../axiosConfig";

import DOMPurify from "dompurify";

import { arrowForward } from "ionicons/icons";

import React, { useState, useEffect } from "react";

import * as yup from "yup";
import "./PasswordConfirmForm.scss";
import PasswordConfirm from "../pages/PasswordConfirm";
import addEnterEvent from "../submitFormOnEnter";

const PasswordConfirmForm: React.FC = () => {
	//You can use this here to navigate through your pages
	const router = useIonRouter();

	const [error, setError] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const [code, setCode] = useState("");

	// With useEffect(), the code below will only be executed on the first render
	useEffect(() => {
		// The code below is used to check if the user asked permission to change the password, and to give you 15 minutes to write the code you got, otherwise they will be send to the page where you ask for permission.
		axiosConfig
			.post("/api/cache_code_exists", {
				headers: {
					Accept: "application/json",
				},
			})
			.then(function (response) {
				if (!response.data.answer) {
					window.setNextAlertMessage("U heeft geen toestemming om uw wachtwoord te veranderen of uw code is verlopen.");
					router.push("/wachtwoord-aanvragen");
				}

				setTimeout(() => {
					window.setNextAlertMessage("Uw code is verlopen. Vraag opnieuw een code aan.");
					router.push("/wachtwoord-aanvragen");
				}, 1000 * response.data.time);
			});

		addEnterEvent();
	}, []);

	const validationSchema = yup.object().shape({
		code: yup.string().required().min(5).max(5),
	});

	// With this function, you can get permission if you write down the code that was sent to the mail you wrote on the page of ChangePassowrd.tsx
	const Confirm = () => {
		setCode(DOMPurify.sanitize(code));
		// This checks if the code is the right length. If it is, the code below will be executed

		validationSchema
			.validate({ code })
			.then(() => {
				axiosConfig
					.post("/api/check_cache_code", {
						headers: {
							Accept: "application/json",
						},
						code: code,
					})
					.then(function (response) {
						router.push("/wachtwoord-veranderen");
					})
					.catch(function (error) {
						setErrorMessage(error.response.data.answer);
					});
			})
			.catch((err) => {
				setErrorMessage(err.errors[0]);
				window.setNextAlertMessage(err.errors[0]);
				router.push("/wachtwoord-aanvragen");
			});
	};
	return (
		<form className="confirm-form">
			<IonText>
				<h1>Code invullen</h1>
				<h2>Kijk in je mail voor de code. Vul die in om toestemming te krijgen om jouw wachtwoord te veranderen</h2>
			</IonText>
			<IonChip className="ion-margin-top" color="danger" style={{ display: error }}>
				{errorMessage}
			</IonChip>
			<IonItem>
				<IonLabel position="floating">Code invullen</IonLabel>
				<IonInput clearOnEdit={false} type="password" onIonInput={(e: any) => setCode(e.target.value)} />
			</IonItem>
			<IonButton onClick={() => Confirm()} form-submitter>
				<IonIcon icon={arrowForward} />
				Reset wachtwoord
			</IonButton>
		</form>
	);
};

export default PasswordConfirmForm;
