import "./Plaatjes.scss";
import "../PageType.scss";
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonHeader,
	IonModal,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from "@ionic/react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Chapter, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, Page } from "../../Diary";
import StickerSlot from "./StickerSlot";
import TextSlot from "./TextSlot";
import StickerMenu from "../../Page/StickerMenu";
import axiosConfig from "../../../../axiosConfig";
import StickerDate from "./StickerDate";

const Plaatjes = (
	props: PropsWithChildren<{
		page: Page;
		reloadFunc: () => any;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	const router = useIonRouter();
	const [currentPage, setCurrentPage] = useState<Page | null>(null);
	const [allPages, setAllPages] = useState<Page[]>([]);
	const previousButton = useRef<HTMLIonButtonElement>(null);
	const nextButton = useRef<HTMLIonButtonElement>(null);
	const [chapter, setChapter] = useState<Chapter>();
	const stickerMenu = useRef<HTMLIonModalElement>(null);
	const stickerSlot = useRef(null);

	const diaryIndex = router.routeInfo.pathname.split("/")[2];
	const chapterIndex = router.routeInfo.pathname.split("/")[3];

	// function that calls to "ChapterView" this reloads the page properly
	const reloadCurrentPage = () => {
		props.reloadFunc();
		//loads the fillblanks function twice for fast and slow devices. Issue with loading too fast. But looks buggy if you wait long.
		setTimeout(function () {
			fillBlanks();
		}, 500);
		setTimeout(function () {
			fillBlanks();
		}, 2000);
	};

	const getPosition = (clickedSlot: any) => {
		const slot = clickedSlot.parentNode.parentNode;
		stickerSlot.current = slot.id.at(-1);

		stickerMenu.current?.present();
	};

	const getTextPosition = (sentText: any) => {
		const slot = sentText.parentNode;
		stickerSlot.current = slot.id.at(-1);

		const value = sentText.value;

		createPageText(value);
	};

	const getDatePosition = (date: any, slotId: number) => {
		let stickerId;

		props.page.PageStickers.map((sticker, i) => {
			if (sticker.SlotNumber == slotId) {
				stickerId = sticker.id;
			}
		});

		if (stickerId) fillStickerDate(date.value, stickerId);
		else console.log(stickerId);
	};

	const updateText = (textId: number, sentText: string) => {
		updatePageText(textId, sentText);
	};

	const loadPageIndexList = () => {
		// Get pages for page index menu
		axiosConfig.post(`/api/diary/${diaryIndex}/${chapterIndex}/pages`).then((response) => {
			setAllPages(response.data.pages as Page[]);
		});
	};

	//closes sticker menu
	const closeStickerMenu = () => {
		stickerMenu.current?.dismiss();
	};

	//Adds sticker to page, then runs reload function so the stickers actually show up without reloading
	const createPageSticker = (sticker: number, height: number, width: number) => {
		const slot = stickerSlot.current;
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/${props.page.order_index}/sticker/create/${slot}`, {
				sticker: sticker,
				height: height,
				width: width,
			})
			.then((response) => {
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});

		closeStickerMenu();
	};

	const createPageText = (text: string) => {
		const slot = stickerSlot.current;
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/${props.page.order_index}/text/create/${slot}`, {
				text: text,
			})
			.then((response) => {
				console.log(response);
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const fillStickerDate = (date: Date, stickerId: number) => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/${props.page.order_index}/sticker/${stickerId}/fill`, {
				date: date,
			})
			.then((response) => {
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const updatePageText = (textId: number, text: string) => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}/${props.page.order_index}/text/${textId}/update`, {
				text: text,
			})
			.then((response) => {
				console.log(response);
				reloadCurrentPage();
				loadPageIndexList();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	// this loads the page data which includes stickers when this page loads
	useEffect(() => {
		axiosConfig
			.post(`/api/diary/${diaryIndex}/${chapterIndex}`)
			.then((response) => {
				setChapter(response.data.chapter as Chapter);

				//Load first pages
				const mostRecentPageIndex = response.data.chapter.mostRecentPageIndex;
				axiosConfig.post(`/api/diary/${diaryIndex}/${chapterIndex}/${mostRecentPageIndex}`).then((response) => {
					setCurrentPage(response.data.page as Page);
				});

				loadPageIndexList();
				nextButton.current?.setAttribute("disabled", "true");

				fillBlanks();
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const fillBlanks = () => {
		const container = document.getElementById("sticker_slot0")?.parentNode;

		for (let a = 0; a <= 8; a++) {
			const date = document.getElementById("sticker_date" + a);
			if (
				(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display == "inline" &&
				container?.childNodes[a].childNodes[0].childNodes.length == 2
			) {
				(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display = "none";
				if (date) {
					date.style.opacity = "1";
				}
			} else if (
				(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display == "none" &&
				container?.childNodes[a].childNodes[0].childNodes.length != 2
			) {
				(container?.childNodes[a].childNodes[0].childNodes[0] as HTMLElement).style.display = "inline";
				if (date) {
					date.style.opacity = "0";
				}
			}
		}
	};

	// this is not in a useeffect because the useeffect would be too slow and the array wont have the slots data
	const columns = "3";
	document.documentElement.style.setProperty("--plaatjes_columns", columns);
	const rows = "3";
	document.documentElement.style.setProperty("--plaatjes_rows", rows);

	const slots = +columns * +rows;

	return (
		<>
			<div className="page_type_page plaatjes">
				<div className="header">
					<h1>{props.page.title}</h1>
					<p>Plakkaart</p>
				</div>
				<div className="content">
					{[...Array(slots)].map((e, i) => (
						<div key={i} id={"sticker_slot" + i} className="sticker_slot">
							<StickerSlot
								page={props.page}
								stickerMenu={stickerMenu}
								getPosition={(clickedSlot) => {
									getPosition(clickedSlot);
								}}
								slotId={i}
								onDragEnd={props.onDragEnd}
								onUpdateContent={props.onUpdateContent}
								onDeleteContent={props.onDeleteContent}
							/>
							<StickerDate
								page={props.page}
								getDatePosition={(date, slotId) => {
									getDatePosition(date, slotId);
								}}
								slotId={i}
								pageType={""}
								slotNumber={i}
								onDragEnd={props.onDragEnd}
								onUpdateContent={props.onUpdateContent}
								onDeleteContent={props.onDeleteContent}
							/>
							<TextSlot
								page={props.page}
								getTextPosition={(sentText) => {
									getTextPosition(sentText);
								}}
								updateText={(textId, sentText) => {
									updateText(textId, sentText);
								}}
								slotId={i}
								pageType={""}
								slotNumber={i}
								onDragEnd={props.onDragEnd}
								onUpdateContent={props.onUpdateContent}
								onDeleteContent={props.onDeleteContent}
							/>
						</div>
					))}
				</div>
			</div>

			<IonModal ref={stickerMenu}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Selecteer sticker om te plakken</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => closeStickerMenu()}>Sluiten</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<StickerMenu
					saveSticker={(sticker, height, width) => createPageSticker(sticker, height, width)}
					closeStickerMenu={() => closeStickerMenu()}
				/>
			</IonModal>
		</>
	);
};

export default Plaatjes;
