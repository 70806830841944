import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, useIonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { setLocale } from "yup";
import { nl } from "yup-locales";
import Menu from "./components/Menu";
import Page from "./pages/Page";
import React, { RefObject, useEffect, useState } from "react";
import IndexPage from "./pages/IndexPage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Logout from "./pages/Logout";
import VerifyAccountPage from "./pages/Verify Account/VerifyAccount";
import PasswordRequest from "./pages/PasswordRequest";
import ChangePassword from "./pages/ChangePassword";
import PasswordConfirm from "./pages/PasswordConfirm";
import AccountSettings from "./pages/Account Settings/AccountSettings";
import ConfirmDelete from "./pages/Delete Account/ConfirmDelete";
import AdminRights from "./pages/AdminRights";
import StickerOverview from "./pages/Sticker Overview/StickerOverview";
import { LoadingModal } from "./components/LoadingModal/LoadingModal";
import { useCookies } from "react-cookie";
import "./App.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import ReCAPTCHA from "react-google-recaptcha";
import { waitForElm } from "./WaitForElm";
import NewDiary from "./pages/Diary/NewDiary";
import DiaryPage from "./pages/Diary/DiaryPage";
import AboutMeView from "./pages/Diary/AboutMeView";
import HandleidingView from "./pages/Diary/HandleidingView";
import HelpView from "./pages/Diary/HelpView";
import ChapterView from "./components/Diary/ChapterView";
import { alertCircle, checkmarkCircle } from "ionicons/icons";
import User from "./User";
import ExternalLink from "./pages/ExternalLink";

setupIonicReact({ mode: "md", animated: true });

nl.mixed.required = "${path} mag niet leeg zijn";

setLocale(nl);

const App: React.FC = () => {
	const [cookies] = useCookies(["user"]);
	const [presentToast] = useIonToast();
	const [nextAlertMessage, setNextAlertMessage] = useState("");
	window.setNextAlertMessage = setNextAlertMessage;

	useEffect(() => {
		waitForElm("ion-header").then((elm) => {
			if (nextAlertMessage !== "") {
				showAlertNextRoute(nextAlertMessage);
				setNextAlertMessage("");
				window.nextAlertColor = "danger";
			}
		});
	});

	const getLoggedStatus = (): boolean => {
		if (cookies.user) {
			return true;
		} else {
			return false;
		}
	};

	const getUser = (): User => {
		if (cookies.user) {
			setTheme(cookies.user.role_id);
			return cookies.user;
		}
		return { id: NaN, username: "Gast" } as User;
	};

	const setTheme = (role_id: number) => {
		let roleVar;
		switch (role_id) {
			case 1:
				roleVar = "brus";
				break;
			case 2:
				roleVar = "ouder";
				break;
			case 3:
				roleVar = "kind";
				break;
			default:
				return;
		}

		const root = document.querySelector<HTMLElement>(":root");

		root?.style.setProperty("--dapper-primary", `var(--dapper-${roleVar}-primary)`);
		root?.style.setProperty("--dapper-secondary", `var(--dapper-${roleVar}-secondary)`);
		root?.style.setProperty("--dapper-tertiary", `var(--dapper-${roleVar}-tertiary)`);
	};

	const [loggedIn, setLoggedIn] = useState(getLoggedStatus());
	const [user, setUser] = useState(getUser());
	const [role, setRole] = useState();

	window.reCaptchaBox = React.createRef<ReCAPTCHA>();

	const routes: CustomRoute[] = [
		{
			path: "/",
			html: <IndexPage loggedIn={loggedIn} user={user} />,
		},
		{
			path: "/login",
			html: <Login setUser={setUser} setLoggedIn={setLoggedIn} />,
		},
		{
			path: "/logout",
			html: <Logout setUser={setUser} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />,
		},

		//#region Diary
		{
			path: "/dagboek/nieuw",
			html: <NewDiary />,
		},

		{
			path: "/dagboek/:id",
			html: <DiaryPage />,
		},
		{
			path: "/dagboek/:id/about-me",
			html: <AboutMeView />,
		},
		{
			path: "/handleiding",
			html: <HandleidingView />,
		},
		{
			path: "/help",
			html: <HelpView />,
		},
		{
			path: "/dagboek/:id/:chapter",
			html: <ChapterView />,
		},
		{
			path: "/dagboek",
			html: <Redirect to="/dagboek/1" />,
		},

		//#endregion

		//#region Change Password
		{
			path: "/wachtwoord-aanvragen",
			html: <PasswordRequest />,
		},
		{
			path: "/wachtwoord-bevestigen",
			html: <PasswordConfirm />,
		},
		{
			path: "/wachtwoord-veranderen",
			html: <ChangePassword />,
		},
		//#endregion

		{
			path: "/registreren",
			html: <Register />,
		},
		{
			path: "/verify",
			html: <VerifyAccountPage></VerifyAccountPage>,
		},
		{
			path: "/account_instellingen",
			html: <AccountSettings setUser={setUser} />,
		},
		{
			path: "/verwijderen_bevestigen",
			html: <ConfirmDelete setUser={setUser} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />,
		},
		{
			path: "/admin-rechten",
			html: <AdminRights redirect="/" user={user} />,
		},

		{
			path: "/sticker-overzicht",
			html: <StickerOverview />,
		},

		{
			path: "/externe/:url",
			html: <ExternalLink />,
		},

		{
			path: "/:name",
			html: <h1>Test</h1>,
		},
	];

	return (
		<IonApp>
			<IonReactRouter>
				<IonSplitPane contentId="main">
					<Menu user={user} loggedIn={loggedIn} />
					<IonRouterOutlet id="main">
						<Switch>
							{routes.map((customRoute) => {
								return (
									<Route exact path={customRoute.path} key={"route" + customRoute.path}>
										<Page>{customRoute.html}</Page>
									</Route>
								);
							})}
						</Switch>
					</IonRouterOutlet>
				</IonSplitPane>
			</IonReactRouter>
			<ReCAPTCHA
				ref={window.reCaptchaBox}
				sitekey="6LcpO3UiAAAAABRoKITp9cHBgTKzm3LaEWcF6CNa"
				size="invisible"
				onChange={(e) => {
					console.log(e);
					window.reCaptchaBox.current?.reset();
				}}
			/>

			<LoadingModal />
		</IonApp>
	);

	function showAlertNextRoute(message: string) {
		presentToast({
			message: message,
			position: "top",
			duration: message.length * 125,
			color: window.nextAlertColor ? window.nextAlertColor : "danger",
			icon: window.nextAlertColor == "danger" ? alertCircle : checkmarkCircle,
		});
	}
};

export default App;

declare global {
	interface Window {
		reCaptchaBox: RefObject<ReCAPTCHA>;
		setNextAlertMessage: (message: string) => void;
		nextAlertColor: string;
	}
}

export interface CustomRoute {
	path: string;
	html: any;
}
