import { IonContent, IonItem, IonText, useIonRouter } from "@ionic/react";

import React, { useEffect } from "react";
import axiosConfig from "../../axiosConfig";
import { Redirect } from "react-router-dom";

const NewDiary = () => {
	const router = useIonRouter();

	const [redirect, setRedirect] = React.useState(0);

	console.log(redirect);

	useEffect(() => {
		axiosConfig
			.post("/api/diary/create")
			.then((response) => {
				console.log(response);
				setRedirect(response.data.diary.index);
				// router.push("/dagboek/" + response.data.diary.index);
			})
			.catch((err) => {
				console.log(err);
				window.setNextAlertMessage("Er ging iets mis bij het aanmaken van het dagboek. Probeer het later opnieuw.");
				router.push("/");
			});
	}, []);

	return (
		<>
			<IonContent>
				<IonItem style={{ fontSize: "25px" }}>
					<IonText>Dagboek wordt aangemaakt</IonText>
				</IonItem>
			</IonContent>
			{redirect > 0 && <Redirect to={"/dagboek/" + redirect} />}
		</>
	);
};

export default NewDiary;
