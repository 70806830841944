import "./PlaceableItem.scss";
import { createGesture, Gesture, GestureDetail } from "@ionic/react";

export class PlaceableItemProps {
	minMoveDistance = 10;
	hostElement: HTMLElement = document.querySelector(".placeable-item-container") as HTMLElement;
	currentPos = {
		x: 0,
		y: 0,
	};
	deltaMultiplier = 1;

	dragGesture: Gesture | undefined;

	onClick = () => {};

	onDragStart = (event: GestureDetail) => {};
	onDragMove = (event: GestureDetail) => {};
	onDragEnd = (event: GestureDetail) => {};

	constructor(hostElement: HTMLElement, minMoveDistance = 10) {
		this.minMoveDistance = minMoveDistance;
		this.hostElement = hostElement;
	}
}

export const clampPos = (pos: { x: number; y: number }, hostElement: HTMLElement) => {
	const { width, height } = hostElement.getBoundingClientRect();
	const parentRect = hostElement.parentElement!.getBoundingClientRect();
	const x = Math.min(Math.max(pos.x, 0), parentRect.width - width);
	const y = Math.min(Math.max(pos.y, 0), parentRect.height - height);
	return { x, y };
};

export const CreateDraggable = (props: PlaceableItemProps) => {
	const style = props.hostElement.style;

	props.dragGesture = createGesture({
		el: props.hostElement,
		gestureName: "draggable",
		threshold: 0,
		// NOTE: the below code not hot reload, refresh the page to see changes
		onStart: (ev) => {
			props.onDragStart(ev);
			style.transition = "none";
			style.opacity = "0.7";
		},
		onMove: (detail) => {
			props.onDragMove(detail);
			// Clamp the position to the viewport
			const pos = clampPos(
				{
					x: props.currentPos.x + detail.deltaX * props.deltaMultiplier,
					y: props.currentPos.y + detail.deltaY * props.deltaMultiplier,
				},
				props.hostElement,
			);
			style.transform = `translate(${pos.x}px, ${pos.y}px)`;
		},
		onEnd: (ev) => {
			if (
				Math.abs(ev.deltaX * props.deltaMultiplier) + Math.abs(ev.deltaY * props.deltaMultiplier) / 2 <
				props.minMoveDistance
			) {
				console.log(Math.abs(ev.deltaX + ev.deltaY) / 2);
				props.onClick();
			} else {
				props.currentPos = clampPos(
					{
						x: props.currentPos.x + ev.deltaX * props.deltaMultiplier,
						y: props.currentPos.y + ev.deltaY * props.deltaMultiplier,
					},
					props.hostElement,
				);
				props.onDragEnd(ev);
			}

			style.transform = `translate(${props.currentPos.x}px, ${props.currentPos.y}px)`;

			style.transition = ".3s ease-out";
			style.zIndex = "inherit";
			style.opacity = "1";
		},
	});

	props.dragGesture.enable();
};
