import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonTitle,
	IonToolbar,
	IonToast,
} from "@ionic/react";
import React, { PropsWithChildren, useState, useEffect } from "react";
import { arrowBack, close } from "ionicons/icons";
import Sticker from "../../Sticker Overview/Sticker";
import axiosConfig from "../../../axiosConfig";
import StickerView from "../../Sticker Overview/StickerView";
import "./StickerMenu.scss";

const StickerMenu = (
	props: PropsWithChildren<{
		saveSticker: (sticker: number, height: number, width: number) => any;
		closeStickerMenu: () => void;
	}>,
) => {
	const [stickers, setStickers] = useState<Sticker[]>([]);
	const [stickerInfo, setStickerInfo] = useState<Sticker>({
		id: 0,
		name: "Sticker",
		description: "Beschrijving",
		path: "/placeholder.jpg",
	});

	//retrieves all stickers
	useEffect(() => {
		axiosConfig
			.get("/api/get_all_stickers")
			.then((response) => {
				setStickers(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [isErrorMessage, setErrorMessage] = useState("");
	const [showToastError, setShowToastError] = useState(false);

	const [showToastSuccess, setShowToastSuccess] = useState(false);
	const [isSuccessMessage, setSuccessMessage] = useState("");

	const savingSticker = () => {
		try {
			props.saveSticker(stickerInfo?.id, 50, 50);
			setSuccessMessage("Sticker is succesvol geplakt.");
			setShowToastSuccess(true);
		} catch (error) {
			setErrorMessage("Er is iets misgegaan.");
			setShowToastError(true);
		}
		props.closeStickerMenu();
	};

	return (
		<>
			{stickerInfo.id == 0 ? (
				<div className="sticker-menu-container">
					<div className="grid">
						{stickers.map((sticker: Sticker, index: number) => (
							<StickerView
								key={sticker.id}
								index={index}
								id={sticker.id}
								name={sticker.name}
								description={sticker.description}
								path={sticker.path}
								func={() => {
									setStickerInfo(sticker);
								}}
							/>
						))}
					</div>
				</div>
			) : (
				<div className="sticker-modal">
					<IonContent className="ion-padding">
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton
									onClick={() =>
										setStickerInfo({
											id: 0,
											name: "Sticker",
											description: "Beschrijving",
											path: "/placeholder.jpg",
										})
									}
									color="danger"
								>
									<IonIcon icon={close}></IonIcon>
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<img
							src={require("../../../resources" + stickerInfo?.path)}
							alt={stickerInfo?.name + " afbeelding"}
							className="sticker-overview-single-image"
						/>
						<IonTitle>{stickerInfo?.name}</IonTitle>
						<p>{stickerInfo?.description}</p>
						<div className="ion-text-center">
							<IonButton
								onClick={() => {
									savingSticker();
								}}
								color="success"
							>
								selecteer
							</IonButton>
						</div>
					</IonContent>
				</div>
			)}
		</>
	);
};

export default StickerMenu;
