import { useCookies } from "react-cookie";

const hasBackButton = (): boolean => {
	const backButtonPages = [
		"/registreren",
		"/voorwaarden",
		"/wachtwoord-aanvragen",
		"/wachtwoord-bevestigen",
		"/wachtwoord-veranderen",
		"/dagboek/",
		"/externe",
		"/handleiding",
	];

	return backButtonPages.some((p) => location.pathname.includes(p));
};

export const showPageIndexList = (): boolean => {
	return location.pathname.match("/dagboek/\\d+/\\d+") != null;
};

const isTitleHidden = (): boolean => {
	const titleHiddenPages = ["/login", "/verwijderen_bevestigen", "/logout", "/verify", "/externe"];

	return titleHiddenPages.some((p) => location.pathname.includes(p));
};

const isToolbarHidden = (): boolean => {
	const toolbarHiddenPages = ["/login", "/verwijderen_bevestigen", "/logout", "/verify"];

	return toolbarHiddenPages.some((p) => location.pathname.includes(p));
};

const isMenuHidden = (): boolean => {
	const menuHiddenPages = [
		"/login",
		"/logout",
		"/registreren",
		"/voorwaarden",
		"/wachtwoord-aanvragen",
		"/wachtwoord-bevestigen",
		"/wachtwoord-veranderen",
		"/verwijderen_bevestigen",
		"/verify",
		"/externe",
	];
	return menuHiddenPages.some((p) => location.pathname.includes(p));
};

/**
 * @description Determine wether the page must show in the menu or not. If a user is logged in then the login button must not show. When user is not logged in the logout button should not show
 * @param pageName The name of the page that is getting checked, the only relevant names are 'Inloggen' en 'Uitloggen'
 * @param loggedInUser A boolean that is true if the user is currently logged in
 * @returns  Either inline or none, as the display type for the menu item
 */
const showItemInMenu = (pageName: string, loggedInUser: boolean): string => {
	const [cookies] = useCookies(["user"]);

	if (pageName === "Inloggen") {
		if (loggedInUser) {
			return "none";
		} else {
			return "inline";
		}
	} else if (pageName === "Uitloggen") {
		if (loggedInUser) {
			return "inline";
		} else {
			return "none";
		}
	} else if (pageName === "Admin rechten") {
		if (loggedInUser && cookies.user.role == "Admin") {
			return "inline";
		} else {
			return "none";
		}
	} else {
		return "inline";
	}
};

export { hasBackButton, isTitleHidden, isMenuHidden, showItemInMenu, isToolbarHidden };
