import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";

const HandleidingLink = () => {
	return (
		<>
			<IonCard>
				<IonCardHeader class="diary-title">
					<IonCardTitle>Handleiding</IonCardTitle>
				</IonCardHeader>

				<IonCardContent>
					<IonButton routerLink={"/handleiding"}>Lees verder</IonButton>
				</IonCardContent>
			</IonCard>
		</>
	);
};
export default HandleidingLink;
