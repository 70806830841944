import "./Terms.scss";

const privacy = () => {
	return (
		<div className="terms_container">
			<div className="terms_header">
				<h1>PRIVACY VERKLARING</h1>
				<h4>STICHTING DAPPER KIND</h4>
			</div>

			<div className="terms_sub_header">
				Jouw privacy is voor Stichting Dapper Kind (hierna verwezen naar ‘wij’ of ‘ons’) van groot belang. Aangezien wij
				persoonsgegevens verwerken door en bij gebruik van de DapperKind App hebben wij een privacyverklaring opgesteld. In
				deze privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen, gebruiken en met welk doel. Wij
				verwerken jouw persoonsgegevens uitsluitend in overeenstemming met de AVG.
			</div>

			<div className="terms_article">
				<span className="terms_article_title">Wat kun je van ons verwachten</span>
				<ol className="terms_article_list">
					<li className="terms_article_list_item">
						Wij beschouwen jou als eigenaar van je persoonsgegevens. Jouw data, jij bepaalt daarom zo veel mogelijk.
					</li>
					<li className="terms_article_list_item">Jouw data verkopen wij nooit.</li>
					<li className="terms_article_list_item">
						Je bepaalt zelf in welke mate we je persoonsgegevens kunnen gebruiken of vrijgeven. Vrijgeven doen wij alleen met
						jouw expliciete opdracht of indien wij daartoe wettelijk verplicht zijn.
					</li>
					<li className="terms_article_list_item">
						We beheren je persoonsgegevens met grote zorgvuldigheid en betrouwbaarheid.
					</li>
				</ol>
			</div>
			<div className="terms_article">
				<div className="terms_header">
					<h4>Gebruik van persoonsgegevens</h4>
				</div>
				<br />
				<span className="terms_article_title">Wat kun je van ons verwachten</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Persoonsgegevens zijn alle gegevens die, direct of indirect, te herleiden zijn tot jou als persoon. Denk
						bijvoorbeeld aan je naam, adres, geboortedatum, telefoonnummer, e-mailadres en IP-adres. Alles wat met die
						persoonsgegevens wordt gedaan, wordt ‘verwerken’ genoemd. Denk aan het verzamelen, bewaren, raadplegen en het
						verwijderen van persoonsgegevens.
					</li>
				</ul>
				<span className="terms_article_title">DapperKind App</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Als je de DapperKind App (hierna: ‘App’) op jouw mobiele apparaat installeert en gebruikt, laat je bepaalde
						gegevens bij ons achter. Dat kunnen persoonsgegevens zijn. Sommige persoonsgegevens zijn noodzakelijk om de App
						goed te laten werken. Over sommige gegevens kun je zelf beslissen of je die in de App verstrekt of niet.
					</li>
				</ul>
				<span className="terms_article_title">Downloaden applicatie</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Om gebruik te kunnen maken van de App dien je de applicatie te downloaden. Dit kun je doen via de App Store of
						via Google Play. Wij hebben geen invloed op welke persoonsgegevens de aanbieder (Apple of Google) hiervan
						verwerkt en waarvoor. Wij raden je aan om de relevante privacyverklaring te lezen van de desbetreffende
						aanbieder.
					</li>
				</ul>
				<span className="terms_article_title">Registratie applicatie</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Om gebruik te kunnen maken van de App dien je je te registreren voor een DapperKind account. Hierna maken wij een
						account voor je aan, waarop je kunt inloggen met jouw gebruikersnaam en zelfgekozen wachtwoord.
					</li>
				</ul>
			</div>
			<div className="terms_article">
				<span className="terms_article_title">Om een account aan te kunnen maken, gebruiken wij jouw:</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">voor- en achternaam.</li>
					<li className="terms_article_list_item">geboortedatum.</li>
					<li className="terms_article_list_item">e-mailadres.</li>
					<li className="terms_article_list_item">zelfgekozen wachtwoord.</li>
					<li className="terms_article_list_item">IP-adres.</li>
				</ul>
				<p>
					Wij verwerken deze persoonsgegeven om jou toegang te kunnen geven tot de App. Wij bewaren deze informatie totdat
					jij jouw account voor de App opheft.{" "}
				</p>
				<br />
				<p>
					Je bent zelf verantwoordelijk voor de veiligheid van jouw inlognaam en wachtwoord. Als je denkt dat jouw
					inloggegevens bekend zijn geworden bij iemand anders, dan moet je onmiddellijk jouw wachtwoord veranderen. Wij zijn
					niet verantwoordelijk als iemand anders toegang krijgt tot jouw account en met jouw inloggegevens of door een
					schending van de gebruiksvoorwaarden of dit privacy beleid.
				</p>
				<br />
				<span className="terms_article_title">Gebruik applicatie</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Wanneer je bent ingelogd op jouw account, kun je gebruik maken van de verschillende functies die onze applicatie
						te bieden heeft
					</li>
				</ul>
				<span className="terms_article_title">
					Wanneer je gebruik maakt van onze applicatie verwerken wij de volgende gegevens van jou:
				</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">informatie die je zelf invult als inhoud van het digitale dagboek.</li>
					<li className="terms_article_list_item">IP-adres</li>
					<li className="terms_article_list_item">Gebruik van 4G/5G/Wifi.</li>
				</ul>
				<p>Wij bewaren deze informatie totdat je jouw account opheft. </p>
			</div>
			<div className="terms_article">
				<div className="terms_header">
					<h4>Onderzoek</h4>
				</div>
				<br />
				<p>Om onze diensten te verbeteren maken wij gebruik van algemeen onderzoek naar het gebruik van de App.</p>
				<br />
				<span className="terms_article_title">Hiervoor gebruiken wij:</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">Gebruiksgegevens applicatie.</li>
					<li className="terms_article_list_item">IP-adres.</li>
					<li className="terms_article_list_item">Gebruik van 4G/5G/Wifi.</li>
				</ul>
				<br />
				<p>
					Deze gegevens verwerken wij op basis van jouw toestemming. Wij bewaren deze informatie totdat je jouw account
					opheft. Je kunt jouw toestemming hiervoor op ieder moment intrekken door een e-mail te sturen naar
					info@stichtingdapperkind.nl
				</p>
				<br />
				<span className="terms_article_title">Delen van persoonlijke data</span>
				<br />
				<p>
					Wij delen jouw gegevens enkel met andere bedrijven of instellingen als dat nodig is voor de uitvoering van de
					overeenkomst, als wij daarvoor jouw toestemming hebben gekregen of in het geval wij dit wettelijk verplicht zijn.
				</p>
				<br />
				<p>De partijen die in onze of jouw opdracht persoonsgegevens verwerken, zijn:</p>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">IT-leveranciers en -dienstverleners.</li>
					<li className="terms_article_list_item">onze hostingprovider.</li>
				</ul>
				<br />
				<p>
					Met deze bedrijven hebben wij een verwerkersovereenkomst gesloten om ervoor te zorgen dat zij zorgvuldig omgaan met
					jouw gegevens.
				</p>
				<br />
				<p>
					Alle informatie die jij zelf hebt ingevuld als inhoud in de dagboekfunctionaliteit van de App zal nooit worden
					overgedragen aan een derde partij, tenzij wij dit wettelijk zijn verplicht.
				</p>
				<br />
				<span className="terms_article_title">Beveiliging</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Wij hechten grote waarden aan de beveiliging en bescherming van jouw persoonsgegevens en wij zorgen, rekening
						houden met de stand van de techniek, voor passende technische en organisatorische maatregelen om een op het
						risico afgestemd beveiligingsniveau te waarborgen en wij eisen dit ook van partijen die in opdracht van ons
						persoonsgegevens verwerken. We passen de beveiliging steeds aan en letten goed op wat er mis kan gaan.
					</li>
				</ul>
				<span className="terms_article_title">Bewaartermijn</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Wij bewaren persoonsgegevens die worden verwerkt niet langer dan noodzakelijk is voor de hiervoor genoemde
						doeleinden van de gegevensverwerking dan wel op grond van wet- en regelgeving is vereist.
					</li>
				</ul>
				<span className="terms_article_title">Welke rechten heb jij?</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Heb je vragen over de verwerking van jouw persoonsgegevens of wil je weten welke persoonsgegevens wij van je
						hebben? Neem dan gerust contact met ons op via info@stichtingdapperkind.nl.
					</li>
					<li className="terms_article_list_item">
						Op grond van de privacywetgeving heeft de verstrekker van persoonsgegevens een aantal belangrijke rechten, zoals
						het recht om eerder gegeven toestemming in te trekken en het recht te verzoeken om inzage, rectificatie of het
						wissen van persoonsgegevens. Dit laatste zal het geval zijn indien de persoonsgegevens niet langer nodig zijn
						voor de doeleinden waarvoor zij verzameld of verwerkt zijn. Ook heb je het recht op gegevensoverdraagbaarheid.
						Dat betekent dat je bij ons een verzoek kunt indienen om de persoonsgegevens waarover wij van jou beschikken in
						een computerbestand naar jou of een andere, door jou genoemde organisatie, te sturen.
					</li>
					<li className="terms_article_list_item">
						Bij je verzoek kunnen we je vragen je te legitimeren. Wij vragen hiervoor gegevens op om er zeker van te zijn dat
						jij de juiste persoon bent aan wie de persoonsgegevens toebehoren. Wij kunnen om extra informatie vragen vooral
						in het geval van minderjarigen. Dan is het nodig om met enige zekerheid vast te stellen dat het verzoek wordt
						gedaan door een wettelijk vertegenwoordiger. Een kind kan een wettelijk vertegenwoordiger eventueel schriftelijk
						machtigen; een afschrift daarvan in combinatie met enige vorm van geldige identificatie is dan wel vereist.
					</li>
					<li className="terms_article_list_item">
						De wettelijk vertegenwoordiger is degene die gezag heeft over een kind. Gezag wordt meestal door een of beide
						ouders uitgeoefend maar er zijn ook andere vormen.
					</li>
				</ul>
				<span className="terms_article_title">Reactietermijn</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Wij zullen proberen binnen 30 dagen aan je verzoek te voldoen. Deze termijn kan echter worden verlengd om redenen
						die verband houden met specifieke rechten van betrokkenen of de complexiteit van het verzoek. Als wij deze
						termijn verlengen, zullen wij je hiervan tijdig op de hoogte stellen.
					</li>
				</ul>
				<span className="terms_article_title">Afschrift</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						De kopie van alle persoonsgegevens zal in een gangbare elektronische vorm worden verstrekt, tenzij het verzoek op
						papier is gedaan of de betrokkene expliciet om een papieren kopie verzoekt.
					</li>
				</ul>
				<span className="terms_article_title">Kosten</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Aan het recht op een eerste afschrift is een bedrag van € 5,- per afschrift verbonden, vooraf te betalen. Bij
						additionele kopieën kunnen wij andere kosten in rekening brengen, dit na afstemming met betrokkene.
					</li>
				</ul>
				<span className="terms_article_title">Rechten en vrijheden van anderen</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						We zullen bij het verstrekken van gegevens altijd rekening houden met de rechten en vrijheden van anderen.
					</li>
				</ul>
				<br />
				<p>
					Wie zijn gegevens wil inzien, aanpassen, laten verwijderen of overdragen kan contact opnemen met de functionaris
					gegevensbescherming van Stichting Dapper Kind per e-mail via info@stichtingdapperkind.nl.
				</p>
				<br />
				<span className="terms_article_title">Geen cookies</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Er wordt alleen door ons bijgehouden hoe vaak de app wordt gedownload. Er wordt geen gebruik gemaakt van cookies.
					</li>
				</ul>
				<span className="terms_article_title">Wijzigingen</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						We kunnen de privacy verklaring periodiek aanpassen. De datum van de recentste wijziging verschijnt bovenaan deze
						privacyverklaring. Veranderingen treden onmiddellijk in werking nadat ze openbaar zijn gemaakt.
					</li>
				</ul>
				<span className="terms_article_title">Datalekken</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						We spreken van een datalek als er een inbreuk op de beveiliging van persoonsgegevens plaatsvindt, die kan leiden
						tot ongeoorloofde verwerking daarvan. Het kan hierbij bijvoorbeeld gaan om een diefstal van een laptop of een in
						de trein vergeten usb-stick. Datalekken kunnen afhankelijk van de inschatting van de impact daarvan worden gemeld
						bij de Autoriteit Persoonsgegevens binnen 72 uur na ontdekking daarvan en in sommige gevallen ook bij de
						betrokkene(n) als schade dreigt te ontstaan. Elk datalek en de afhandeling daarvan zal worden bijgehouden in een
						register.
					</li>
				</ul>
				<span className="terms_article_title">Klachten?</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">
						Als je vindt dat wij je niet op de juiste manier helpen, dan heb je het recht om een klacht in te dienen bij de
						toezichthouder, de Autoriteit Persoonsgegevens.
					</li>
				</ul>
				<span className="terms_article_title">Contactgegevens</span>
				<ul className="terms_article_list">
					<li className="terms_article_list_item">Stichting Dapper Kind</li>
					<li className="terms_article_list_item">De Hoogjens 26</li>
					<li className="terms_article_list_item">4254 XW Sleeuwijk</li>
					<li className="terms_article_list_item">Kvk nummer: 56502605</li>
					<li className="terms_article_list_item">E-mailadres: info@stichtingdapperkind.nl</li>
					<li className="terms_article_list_item">Telefoon: 06-51016362</li>
				</ul>
			</div>
		</div>
	);
};
export default privacy;
