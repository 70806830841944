import React, { PropsWithChildren } from "react";
import { Page } from "./Diary";
import { IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import AlertButton from "../AlertButton";
import axiosConfig from "../../axiosConfig";

const PageDeleteButton = (
	props: PropsWithChildren<{
		diaryIndex: string;
		chapterIndex: string;
		pageIndex: number;
		pageName: string;
		currentBufferIndex: number;
		deleteFunc: () => any;
	}>,
) => {
	const deletePage = () => {
		axiosConfig
			.post("/api/diary/" + props.diaryIndex + "/" + props.chapterIndex + "/" + props.pageIndex + "/delete", {
				currentBufferIndex: props.currentBufferIndex,
			})
			.then((response) => {
				props.deleteFunc();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<AlertButton
			class={"diary-delete-button"}
			header={`Weet je zeker dat je de pagina ${props.pageName} wilt verwijderen?`}
			fill="solid"
			color="danger"
			func={() => deletePage()}
			style={{ top: "10px", right: "16px", position: "absolute" }}
		>
			<IonIcon icon={trash} />
		</AlertButton>
	);
};

export default PageDeleteButton;
