import React, { PropsWithChildren, useEffect, useRef } from "react";
import Diary, { Chapter } from "./Diary";
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonIcon,
	IonInput,
	IonItem,
	useIonToast,
} from "@ionic/react";
import { pencil } from "ionicons/icons";
import ChapterDeleteButton from "./ChapterDeleteButton";

const AboutMeLink = (
	props: PropsWithChildren<{
		diary: number;
	}>,
) => {
	return (
		<>
			<IonCard>
				<IonCardHeader class="diary-title">
					<IonCardTitle>Over mij</IonCardTitle>
				</IonCardHeader>

				<IonCardContent>
					<IonButton routerLink={`/dagboek/${props.diary}/about-me`}>Lees verder</IonButton>
				</IonCardContent>
			</IonCard>
		</>
	);
};

export default AboutMeLink;
