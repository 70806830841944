//import './RegisterForm.scss';
import { IonCard, IonImg } from "@ionic/react";

import React, { PropsWithChildren } from "react";
import "./StickerView.scss";

const StickerView = (
	props: PropsWithChildren<{ index: number; id: number; name: string; description: string; path: string; func?: any }>,
) => {
	//console.log(props.func());
	return (
		<IonCard
			className="sticker-overview-single-card"
			onClick={() => {
				if (props?.func != null && props?.func != undefined) {
					props?.func(props.index);
				}
			}}
		>
			<IonImg
				src={require("../../resources" + props.path)}
				alt={props.name + " afbeelding"}
				className="sticker-overview-single-image"
				draggable={false}
			/>
		</IonCard>
	);
};

export default StickerView;
