import { IonContent, useIonRouter } from "@ionic/react";

import AdminRightsForm from "../components/AdminRightsForm";

import { PropsWithChildren } from "react";

import { useCookies } from "react-cookie";

import "./AdminRights.scss";
import User from "../User";

const AdminRights = (props: PropsWithChildren<{ redirect: string; user: User }>) => {
	const router = useIonRouter();

	const [cookies] = useCookies(["user"]);

	if (cookies.user.role !== "Admin") {
		router.push(props.redirect);
	}

	return (
		<IonContent>
			<AdminRightsForm user={props.user} />
		</IonContent>
	);
};

export default AdminRights;
