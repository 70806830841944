import "./Verhaal.scss";
import "../PageType.scss";
import React, { PropsWithChildren } from "react";
import { OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, Page } from "../../Diary";
import PageTextView from "../../Page/PageTextView";
import PageImageView from "../../Page/PageImageView";
import PageStickerView from "../../Page/PageStickerView";

const Verhaal = (
	props: PropsWithChildren<{
		page: Page;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	return (
		<>
			<div className="page_type_page verhaal">
				<div className="header">
					<h1>{props.page.title}</h1>
					<p>Jouw Verhaal</p>
				</div>
				<div className="content trans_white_bg">
					{props.page?.PageTexts?.map((pageText, index) => (
						<PageTextView
							key={pageText.id}
							pageText={pageText}
							onDragEnd={props.onDragEnd}
							onUpdateContent={props.onUpdateContent}
							onDeleteContent={props.onDeleteContent}
						/>
					))}
					{props.page?.PageMedia?.map((pageMedia, index) => (
						<PageImageView
							key={index}
							pageMedia={pageMedia}
							onDragEnd={props.onDragEnd}
							onUpdateContent={props.onUpdateContent}
							onDeleteContent={props.onDeleteContent}
						/>
					))}
					{props.page?.PageStickers?.map((pageSticker, index) => (
						<PageStickerView
							key={index}
							index={index}
							pageSticker={pageSticker}
							onDragEnd={props.onDragEnd}
							onUpdateContent={props.onUpdateContent}
							onDeleteContent={props.onDeleteContent}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default Verhaal;
