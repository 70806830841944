function addEnterEvent() {
	const submitButton: any = document.querySelector("[form-submitter]");
	const inputs = document.querySelectorAll("ion-input");
	inputs.forEach((input) => {
		input.addEventListener("keyup", (e) => {
			if (e.key === "Enter") {
				e.preventDefault();
				submitButton.click();
			}
		});
	});
}

export default addEnterEvent;
