import { IonContent } from "@ionic/react";

import React from "react";
import DiaryView from "../../components/Diary/DiaryView";

const DiaryPage = () => {
	return (
		<IonContent>
			<DiaryView />
		</IonContent>
	);
};

export default DiaryPage;
