import { IonButton, useIonAlert } from "@ionic/react";

import React from "react";

interface AlertButtonProps extends React.HTMLAttributes<HTMLIonButtonElement> {
	func?: any;
	header: string;
	fill?: "clear" | "outline" | "solid" | "default";
	class?: string;
}

const AlertButton = (props: AlertButtonProps) => {
	const [alert] = useIonAlert();

	return (
		<IonButton
			{...props}
			fill={props.fill}
			class={props.class}
			onClick={() =>
				alert({
					header: props.header,
					buttons: [
						{
							text: "Ja",
							role: "confirm",
							handler: () => {
								props?.func();
							},
						},
						{
							text: "Nee",
							role: "cancel",
						},
					],
				})
			}
		>
			{props.children}
		</IonButton>
	);
};

export default AlertButton;
