import { clampPos, CreateDraggable, PlaceableItemProps } from "./PlaceableItem";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { ContentType, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, PageText } from "../Diary";
import { IonButton, IonIcon, IonTextarea } from "@ionic/react";
import { trash, move } from "ionicons/icons";
import AlertButton from "../../AlertButton";

// Container for the page content and functions.
const PageTextView = (
	props: PropsWithChildren<{
		pageText: PageText;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	const divRef = useRef<HTMLDivElement>(null);

	const textAreaRef = useRef<HTMLIonTextareaElement>(null);

	const [editing, setEditing] = useState(false);

	const [args, setArgs] = useState<PlaceableItemProps | null>(null);

	const [deleteDown, setDeleteDown] = useState(false);
	const [moveDown, setMoveDown] = useState(false);

	useEffect(() => {
		// NOTE: the below code not hot reload, refresh the page to see changes
		if (divRef.current) {
			let _args = args;
			_args = new PlaceableItemProps(divRef.current);

			_args.onClick = () => {
				if (deleteDown) return;
				console.log("clicked");
				setEditing(true);
			};

			_args.onDragEnd = () => {
				resetMoveDown();

				props.onDragEnd({
					dragType: ContentType.TEXT,
					id: props.pageText.id,
					dragPos: _args?.currentPos ?? { x: 0, y: 0 },
				});
			};

			// Set the position of the text to position that we received from the server
			_args.currentPos = { x: props.pageText.Pos.X, y: props.pageText.Pos.Y };

			CreateDraggable(_args);

			setArgs(_args);
		}
	}, []);

	const resetMoveDown = () => {
		if (moveDown) {
			setMoveDown(false);
			textAreaRef.current?.setFocus();
		}
	};

	useEffect(() => {
		// We need to wait a little for the text area to be rendered
		setTimeout(() => {
			textAreaRef.current?.setFocus();
			textAreaRef.current?.getInputElement().then((input) => {
				// Set the cursor to the end of the text
				input.setSelectionRange(input.value.length, input.value.length);
			});
		}, 10);
		args?.dragGesture?.enable(!editing);
	}, [editing]);

	const onBlur = () => {
		if (deleteDown || moveDown) return;

		setEditing(false);

		props.pageText.text = (textAreaRef.current?.value as string) || "";

		if (args && divRef.current) {
			const pos = clampPos(args?.currentPos ?? { x: 0, y: 0 }, divRef.current as HTMLDivElement);
			divRef.current.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
			args.currentPos = pos;
			setArgs(args);
			props.onDragEnd({
				dragType: ContentType.TEXT,
				id: props.pageText.id,
				dragPos: args?.currentPos ?? { x: 0, y: 0 },
			});
		}

		props.onUpdateContent(
			{
				editType: ContentType.TEXT,
				id: props.pageText.id,
			},
			{
				text: props.pageText.text,
			},
		);
	};

	const deleteTextBlock = () => {
		setEditing(false);
		setDeleteDown(false);
		try {
			props.onDeleteContent({
				deleteType: ContentType.TEXT,
				id: props.pageText.id,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const text = editing ? (
		<IonTextarea
			ref={textAreaRef}
			value={props.pageText.text}
			onBlur={onBlur}
			minlength={3}
			maxlength={1000}
			autofocus={true}
			autoGrow={true}
		/>
	) : (
		props.pageText.text
	);

	// Page content
	return (
		<div
			className={"placeable-item" + (editing ? " placeable-editing" : "")}
			ref={divRef}
			// The line below is so the compiler ignores the `` which it dislikes
			// eslint-disable-next-line
			style={{ width: `50%`, transform: `translate(${props.pageText.Pos.X}px, ${props.pageText.Pos.Y}px)` }}
		>
			<AlertButton
				class={"delete-button hidden-button button-round"}
				header={"Weet je zeker dat je dit tekstblok wilt verwijderen?"}
				fill="solid"
				// shape="round"
				color="danger"
				onMouseDown={() => setDeleteDown(true)}
				onMouseUp={() => setDeleteDown(false)}
				func={() => deleteTextBlock()}
			>
				<IonIcon icon={trash} />
			</AlertButton>
			<IonButton
				shape="round"
				className="hidden-button move-button"
				color="success"
				onPointerDown={() => {
					args?.dragGesture?.enable(true);
					setMoveDown(true);
				}}
				onPointerUp={() => {
					resetMoveDown();
				}}
			>
				<IonIcon icon={move} />
			</IonButton>
			<h2>{text}</h2>
		</div>
	);
};

export default PageTextView;
