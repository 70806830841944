import "./StickerSlot.scss";
import {
	IonButton,
	IonButtons,
	IonFab,
	IonFabButton,
	IonFabList,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonTitle,
	IonToolbar,
	ItemReorderEventDetail,
	useIonAlert,
	useIonRouter,
	useIonToast,
} from "@ionic/react";
import { add, ellipseSharp } from "ionicons/icons";
import { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import StickerMenu from "../../Page/StickerMenu";
import { Chapter, ContentType, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, Page } from "../../Diary";
import axiosConfig from "../../../../axiosConfig";
import PageStickerView from "../../Page/PageStickerView";

const StickerSlot = (
	props: PropsWithChildren<{
		page: Page;
		stickerMenu: any;
		getPosition: (clickedSlot: any) => any;
		slotId: any;
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	return (
		<>
			{props.page.type == "voorgeschiedenis" ? (
				<div className="sticker_field big sticker_slot_sticker">
					<a style={{ display: "inline" }} onClick={(e) => props.getPosition(e.currentTarget)}>
						<IonIcon className="sticker_slot_button" icon={add}></IonIcon>
					</a>
					{[...Array(props.page?.PageStickers.length)].map((e, index) => {
						if (props.page?.PageStickers[index]?.SlotNumber == props.slotId)
							return (
								<PageStickerView
									key={props.slotId}
									index={index}
									pageSticker={props.page?.PageStickers[index]}
									pageType={"StickerSlot"}
									onDragEnd={props.onDragEnd}
									onUpdateContent={props.onUpdateContent}
									onDeleteContent={props.onDeleteContent}
								/>
							);
					})}
				</div>
			) : (
				<div className="sticker_field sticker_slot_sticker">
					<a style={{ display: "inline" }} onClick={(e) => props.getPosition(e.currentTarget)}>
						<IonIcon className="sticker_slot_button" icon={add}></IonIcon>
					</a>
					{[...Array(props.page?.PageStickers.length)].map((e, index) => {
						if (props.page?.PageStickers[index]?.SlotNumber == props.slotId)
							return (
								<PageStickerView
									key={props.slotId}
									index={index}
									pageSticker={props.page?.PageStickers[index]}
									pageType={"StickerSlot"}
									onDragEnd={props.onDragEnd}
									onUpdateContent={props.onUpdateContent}
									onDeleteContent={props.onDeleteContent}
								/>
							);
					})}
				</div>
			)}
		</>
	);
};

export default StickerSlot;
