import { clampPos, CreateDraggable, PlaceableItemProps } from "./PlaceableItem";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { trash, move } from "ionicons/icons";
import { IonIcon, IonButton } from "@ionic/react";
import { ContentType, OnDeleteContentParams, OnDragEndParams, OnUpdateContentParams, PageSticker } from "../Diary";
import StickerView from "../../Sticker Overview/StickerView";
import AlertButton from "../../AlertButton";
import "./PageStickerView.scss";

const PageStickerView = (
	props: PropsWithChildren<{
		index: number;
		pageSticker: PageSticker;
		pageType?: string;
		//Make onDragEnd useable on the current page. Function only takes params declared in the Dairy.ts at OnDragEndParams.
		onDragEnd: (onDragEndParams: OnDragEndParams) => any;
		onUpdateContent: (onEditContentParams: OnUpdateContentParams, data: any) => any;
		onDeleteContent: (onEditContentParams: OnDeleteContentParams) => any;
	}>,
) => {
	const divRef = useRef<HTMLDivElement>(null);
	const [args, setArgs] = useState<PlaceableItemProps | null>(null);

	const [editing, setEditing] = useState(false);

	const [deleteDown, setDeleteDown] = useState(false);
	const [moveDown, setMoveDown] = useState(false);

	let sticker: any;

	useEffect(() => {
		// NOTE: the below code not hot reload, refresh the page to see changes
		if (divRef.current) {
			let _args = args;
			_args = new PlaceableItemProps(divRef.current);

			if (props.pageType) {
				divRef.current.classList.add("placeable-item-big");
				divRef.current.classList.remove("placeable-item");
			}
			const sticker = document.getElementById("sticker");

			_args.onClick = () => {
				if (deleteDown) return;
				setEditing(true);
				divRef.current?.focus();
				if (sticker != null) {
					sticker.focus();
				}
			};
			sticker?.addEventListener("blur", () => {
				onBlur();
			});

			_args.onDragEnd = () => {
				//set the params for the onDragEnd function.
				props.onDragEnd({
					dragType: ContentType.STICKER,
					id: props.pageSticker.id,
					dragPos: _args?.currentPos ?? { x: 0, y: 0 },
				});
			};

			// Set the position of the sticker to position that we received from the server
			_args.currentPos = { x: props.pageSticker.Pos.X, y: props.pageSticker.Pos.Y };

			//Makes sticker draggable
			CreateDraggable(_args);

			setArgs(_args);
		}
	}, []);

	const resetMoveDown = () => {
		if (moveDown) {
			setMoveDown(false);
			sticker?.focus();
		}
	};

	const onBlur = () => {
		setTimeout(() => {
			if (deleteDown) return;

			setEditing(false);

			if (args && divRef.current) {
				const pos = clampPos(args?.currentPos ?? { x: 0, y: 0 }, divRef.current as HTMLDivElement);
				divRef.current.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
				args.currentPos = pos;
				setArgs(args);
				//Call the ondragend Function set in props.
				props.onDragEnd({
					//Sett the drag type to contentType sticker so the function knows what was being dragged on the page.
					dragType: ContentType.STICKER,
					//Sett the ID to the ID of the sticker that was being dragged.
					id: props.pageSticker.id,
					//Sett dragPos equell to the position of the sticker on the page.
					dragPos: args?.currentPos ?? { x: 0, y: 0 },
				});
			}
		}, 250);
	};

	const deleteSticker = () => {
		setEditing(false);
		setDeleteDown(true);
		try {
			props.onDeleteContent({
				deleteType: ContentType.STICKER,
				id: props.pageSticker.id,
			});
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		args?.dragGesture?.enable(!editing);
	}, [editing]);

	return (
		<div
			className={"placeable-item-container placeable-item" + (editing ? " placeable-editing" : "")}
			ref={divRef}
			style={{ transform: `translate(${props.pageSticker.Pos.X}px, ${props.pageSticker.Pos.Y}px)` }}
		>
			<AlertButton
				class={"delete-button hidden-button button-round"}
				header={"Weet je zeker dat je deze sticker wilt verwijderen?"}
				fill="solid"
				// shape="round"
				color="danger"
				onMouseDown={() => setDeleteDown(true)}
				onMouseUp={() => setDeleteDown(false)}
				func={() => deleteSticker()}
			>
				<IonIcon icon={trash} />
			</AlertButton>
			<IonButton
				shape="round"
				className="hidden-button move-button"
				color="success"
				onPointerDown={() => {
					args?.dragGesture?.enable(true);
					setMoveDown(true);
				}}
				onPointerUp={() => {
					resetMoveDown();
				}}
			>
				<IonIcon icon={move} />
			</IonButton>
			<div id="sticker" tabIndex={0}>
				<StickerView
					index={props.index}
					id={props.pageSticker?.Sticker?.id}
					name={props.pageSticker?.Sticker?.name}
					description={props.pageSticker?.Sticker?.description}
					path={props.pageSticker?.Sticker?.path}
				/>
			</div>
			{editing ? (
				<p style={{ fontSize: "10px", marginRight: "5px", marginLeft: "10px" }} className={"date_sticker"}>
					{props.pageSticker?.Date}
				</p>
			) : (
				""
			)}
		</div>
	);
};

export default PageStickerView;
