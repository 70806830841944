import { IonContent, IonModal, IonTitle, IonButtons, IonButton, IonToolbar, IonHeader } from "@ionic/react";
import axiosConfig from "../../axiosConfig";
import React, { useEffect, useState, useRef } from "react";
import Sticker from "../../components/Sticker Overview/Sticker";
import StickerView from "../../components/Sticker Overview/StickerView";
import "./StickerOverview.scss";

const StickerOverview = () => {
	const [stickers, setStickers] = useState<Sticker[]>([]);
	const [stickerInfo, setStickerInfo] = useState<Sticker>({
		id: 0,
		name: "Sticker",
		description: "Beschrijving",
		path: "/placeholder.jpg",
	});

	const modal = useRef<HTMLIonModalElement>(null);

	useEffect(() => {
		axiosConfig
			.get("/api/get_all_stickers")
			.then((response) => {
				setStickers(response.data);
				console.log(stickers);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const showStickerInfo = (index: number) => {
		setStickerInfo(stickers[index]);

		modal.current?.present();
	};

	return (
		<IonContent className="sticker-overview-container">
			<div className="grid">
				{stickers.map((sticker: Sticker, index: number) => (
					<StickerView
						key={sticker.id}
						index={index}
						id={sticker.id}
						name={sticker.name}
						description={sticker.description}
						path={sticker.path}
						func={showStickerInfo}
					/>
				))}
			</div>

			<IonModal ref={modal} className="sticker-modal">
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => modal.current?.dismiss()}>Sluiten</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<img
						src={require("../../resources" + stickerInfo?.path)}
						alt={stickerInfo?.name + " afbeelding"}
						className="sticker-overview-single-image"
					/>
					<IonTitle>{stickerInfo?.name}</IonTitle>
					<p>{stickerInfo?.description}</p>
				</IonContent>
			</IonModal>
		</IonContent>
	);
};

export default StickerOverview;
