import "./Tekenkaart.scss";
import "../PageType.scss";
import { IonCard, IonCardContent } from "@ionic/react";
import { PropsWithChildren } from "react";
import { Page } from "../../Diary";

import Canvas from "../../../DrawingCanvas/Canvas";

const Tekenkaart = (props: PropsWithChildren<{ page: Page }>) => {
	return (
		<>
			<div className="page_type_page tekenkaart">
				<div className="header">
					<h1>{props.page.title}</h1>
					<p>Tekenkaart</p>
				</div>
				<div id="canvasParent" className="content trans_white_bg">
					<Canvas page={props.page} />
				</div>
			</div>
		</>
	);
};

export default Tekenkaart;
