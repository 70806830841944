import {
	IonAlert,
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	IonModal,
	IonButton,
	IonLabel,
	IonImg,
	IonItem,
	IonText,
} from "@ionic/react";
import { useLocation } from "react-router-dom";

import "./Page.scss";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { hasBackButton, isMenuHidden, isToolbarHidden, showPageIndexList, isTitleHidden } from "../displayConditions";
import { listOutline } from "ionicons/icons";
import { useCookies } from "react-cookie";
import Confetti from "react-confetti";
import AxiosConfig from "../axiosConfig";

const Page = (props: PropsWithChildren) => {
	const routeName = useLocation().pathname;
	const name = routeName.split("/")[1].replace("_", " ").replace("-", " ");
	const [birthday, setBirthday] = useState("");
	const [birthdayBoolean, setBirthdayBoolean] = useState(false);
	const [birthdayAlert, setBirthdayAlert] = useState(false);
	const location = useLocation();

	const [cookies] = useCookies(["user"]);

	const modal = useRef<HTMLIonModalElement>(null);

	function dismiss() {
		modal.current?.dismiss();
	}

	useEffect(() => {
		checkBirthday();
	}, []);
	const checkBirthday = () => {
		if (cookies["user"] && cookies["user"].personal_info) {
			const date = new Date().toLocaleString().split("/");
			let day;
			const month = date[1];
			const personal = cookies["user"].personal_info;
			switch (localStorage.getItem("birthday")) {
				case "true":
					AxiosConfig.post("/api/check_birthday").then((response) => {
						if (response.data === "available-for-birthday") {
							// setBirthdayBoolean(true)
							setBirthdayAlert(true);
							localStorage.setItem("birthday", String("used"));
						}
						if (response.data === "used") {
							localStorage.setItem("birthday", String("used"));
						}
					});
					break;
				case "false":
					if (date[0].charAt(0) === "0") {
						day = date[0].substring(1);
					} else {
						day = date[0];
					}
					if (day == personal.day && month == personal.month) {
						localStorage.setItem("birthday", String("true"));
					}
					break;
				case "used":
					break;
			}
		}
	};
	return (
		<IonPage>
			{isToolbarHidden() ? (
				""
			) : (
				<IonHeader className="page-toolbar">
					<IonToolbar>
						<IonButtons slot="start">
							{!isMenuHidden() && <IonMenuButton menu="start" />}
							{hasBackButton() && <IonBackButton defaultHref="/" />}
						</IonButtons>
						<IonTitle>{!isTitleHidden() && name.charAt(0).toUpperCase() + name.slice(1)}</IonTitle>
						<IonButtons slot="end">
							{showPageIndexList() && (
								<>
									<IonText>Inhoudsopgave</IonText>
									<IonMenuButton auto-hide={false} menu="end">
										<IonIcon icon={listOutline} />
									</IonMenuButton>
								</>
							)}
						</IonButtons>
					</IonToolbar>
				</IonHeader>
			)}

			{birthdayAlert ? (
				<IonModal id="birthday" ref={modal} isOpen={true} canDismiss={true}>
					<img src="https://media3.giphy.com/media/Miu1S2r0YuKfLZM4OL/giphy.gif?cid=790b7611b2c269614cbf7498328d6921685731daf8e0ac7a&rid=giphy.gif&ct=g" />
					<div className={"gefeliciteerd"}>
						<span>G</span>
						<span>E</span>
						<span>F</span>
						<span>E</span>
						<span>L</span>
						<span>I</span>
						<span>C</span>
						<span>I</span>
						<span>T</span>
						<span>E</span>
						<span>E</span>
						<span>R</span>
						<span>D</span>
					</div>
					<div className={"met_je_verjaardag"}>
						<span className={"space met"}>MET</span>
						<span className={"space je"}>JE</span>
						<span className={"verjaardag"}>VERJAARDAG!</span>
					</div>
					<Confetti width={screen.width} height={screen.height} recycle={true} run={true} numberOfPieces={200} />
				</IonModal>
			) : null}
			{/*) : null}*/}

			<IonContent id="main-child">{props.children}</IonContent>
		</IonPage>
	);
};

export default Page;
