import { IonSpinner } from "@ionic/react";
import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../../resources/loadingAnimation.json";
import "./LoadingModal.scss";

const LoadingModal: React.FC = () => {
	return (
		<div className="loading-modal-container" data-loading-modal style={{ display: "none" }}>
			{/* <IonSpinner color="tertiary" name="lines-sharp"></IonSpinner> */}
			<Lottie animationData={loadingAnimation} loop={true} className="lottie-container" />
		</div>
	);
};

const PresentLoadingModal: any = () => {
	const element: any = document.querySelector(".loading-modal-container[data-loading-modal]");
	element.style.display = "flex";
};

const DismissLoadingModal: any = () => {
	const element: any = document.querySelector(".loading-modal-container[data-loading-modal]");
	element.style.display = "none";
};

export { LoadingModal, PresentLoadingModal, DismissLoadingModal };
