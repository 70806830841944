import "./Handleiding.scss";
import "../PageType.scss";
import { PropsWithChildren } from "react";
import { Page } from "../../Diary";

const Handleiding = (
	props: PropsWithChildren<{
		page: Page;
	}>,
) => {
	return (
		<>
			<div className="page_type_page handleiding"></div>
		</>
	);
};
export default Handleiding;
