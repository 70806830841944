import { IonItem } from "@ionic/react";
import React from "react";

export const ReCaptchaMessage = () => {
	return (
		<IonItem className="ion-margin-top">
			<small>
				Deze site is beveiligd door reCAPTCHA en de Google
				<a href="https://policies.google.com/privacy"> Privacy verklaring</a> en
				<a href="https://policies.google.com/terms"> Servicevoorwaarden</a> zijn van toepassing.
			</small>
		</IonItem>
	);
};
