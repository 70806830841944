import {
	IonButton,
	IonChip,
	IonImg,
	IonInput,
	IonItem,
	IonItemDivider,
	IonLabel,
	useIonRouter,
	useIonToast,
} from "@ionic/react";
import "./LoginForm.scss";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import axiosConfig from "../axiosConfig";
import DOMPurify from "dompurify";
import { useCookies } from "react-cookie";
import logo from "../resources/dapper_dagboek_logo.png";
import * as yup from "yup";
import { ReCaptchaMessage } from "./ReCaptcha/ReCaptchaMessage";
import { WaitForCaptcha } from "./ReCaptcha/WaitForCaptcha";
import addEnterEvent from "../submitFormOnEnter";

const LoginForm = (props: PropsWithChildren<{ redirect: string; setUser: any; setLoggedIn: any; function?: any }>) => {
	const router = useIonRouter();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [errorMessage, setErrorMessage] = useState("");

	const [nonVerifiedUserId, setNonVerifiedUserId] = useState();
	const nonVerifiedMessage = useRef<HTMLIonChipElement>(null);
	const [present] = useIonToast();

	const validationSchema = yup.object().shape({
		Gebruikersnaam: yup.string().required(),
		Wachtwoord: yup.string().required().min(8),
	});

	useEffect(() => {
		WaitForCaptcha();

		addEnterEvent();
	}, []);

	const [cookies, setCookie] = useCookies(["user"]);

	/**
	 * @description Validates the submitted form and then requests data from the database to log the user in
	 */
	const handleSubmit = async () => {
		validationSchema
			.validate(
				{
					Gebruikersnaam: username,
					Wachtwoord: password,
				},
				{ abortEarly: false },
			)
			.then(async (value) => {
				// Sanitize user input
				setUsername(DOMPurify.sanitize(username));
				setPassword(DOMPurify.sanitize(password));

				if (!window.reCaptchaBox.current) {
					return;
				}

				// Get captcha token and show if necessary
				const token = await window.reCaptchaBox.current.executeAsync();

				if (token === null || token === "") {
					setErrorMessage("Captcha is niet ingevuld");
					return;
				}

				axiosConfig
					.post("/api/login", {
						headers: {
							Accept: "application/json",
						},
						username: username,
						password: password,
						captchaToken: token,
					})
					.then(function (response) {
						localStorage.setItem("auth_token", response.data.token);
						localStorage.setItem("birthday", response.data.birthday);

						axiosConfig.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("auth_token");

						axiosConfig
							.post("api/user/info")
							.then((response) => {
								props.setUser(response.data.data);
								props.setLoggedIn(true);
								setCookie("user", response.data.data, { path: "/" });
								window.reCaptchaBox.current?.reset();

								setTheme(response.data.data.role_id);
								handleRedirect(props.redirect);
							})
							.catch((err) => {
								console.log(err);
							});
					})
					.catch(function (error) {
						if (nonVerifiedMessage.current) {
							nonVerifiedMessage.current.style.display = "none";
						}

						if (error.response.data.message === "Too many attempts. Try again later") {
							setErrorMessage("Te veel inlog pogingen. Probeer het over 5 minuten opnieuw.");
						} else if (error.response.data.message === "User is not verified") {
							if (nonVerifiedMessage.current) {
								setNonVerifiedUserId(error.response.data.user_id);
								nonVerifiedMessage.current.style.display = "block";
							}
						} else {
							setErrorMessage("Gebruikersnaam of wachtwoord is ongeldig.");
						}
					});
			})
			.catch((err) => {
				setErrorMessage(err.errors[0]);
				console.log(err);
			});
	};

	const resendVerify = () => {
		axiosConfig
			.post("api/resend_verify", {
				id: nonVerifiedUserId,
			})
			.then((response) => {
				present({
					message: "Check je inbox om je account te bevestigen.",
					position: "top",
					duration: 2500,
					color: "success",
				});
			})
			.catch((err) => {
				present({
					message: "Er is iets mis gegaan. Probeer het later opnieuw",
					position: "top",
					duration: 2500,
					color: "danger",
				});
			});

		if (nonVerifiedMessage.current) {
			nonVerifiedMessage.current.style.display = "none";
		}
	};

	const handleRedirect = (target: string) => {
		props.function?.();
		router.push(target);
	};

	const setTheme = (role_id: number) => {
		let roleVar;
		switch (role_id) {
			case 1:
				roleVar = "brus";
				break;
			case 2:
				roleVar = "ouder";
				break;
			case 3:
				roleVar = "kind";
				break;
			default:
				return;
		}

		const root = document.querySelector<HTMLElement>(":root");

		root?.style.setProperty("--dapper-primary", `var(--dapper-${roleVar}-primary)`);
		root?.style.setProperty("--dapper-secondary", `var(--dapper-${roleVar}-secondary)`);
		root?.style.setProperty("--dapper-tertiary", `var(--dapper-${roleVar}-tertiary)`);
	};

	return (
		<form className="ion-padding login-form">
			<IonImg src={logo} />

			<IonItem>
				<IonLabel position="floating">Gebruikersnaam*</IonLabel>
				<IonInput onIonInput={(e: any) => setUsername(e.target.value)} />
			</IonItem>

			<IonItem className="ion-margin-top">
				<IonLabel position="floating">Wachtwoord</IonLabel>
				<IonInput
					className="password"
					clearOnEdit={false}
					type="password"
					onIonInput={(e: any) => setPassword(e.target.value)}
				/>
			</IonItem>

			<IonButton
				className="forgotten_password ion-margin-top"
				onClick={() => handleRedirect("/wachtwoord-aanvragen")}
				fill="outline"
				size="small"
			>
				Wachtwoord vergeten?
			</IonButton>

			<IonButton className="ion-margin-top" expand="block" onClick={() => handleSubmit()} form-submitter>
				Inloggen
			</IonButton>

			<IonChip className="ion-margin-top" color="danger">
				{errorMessage}
			</IonChip>

			<IonChip
				className="ion-margin-top resend-verify"
				color="warning"
				ref={nonVerifiedMessage}
				onClick={() => resendVerify()}
			>
				<p>Bevestig je account om in te kunnen loggen.</p>
				<p>Klik op dit bericht om de bevestiging email opnieuw te versturen.</p>
			</IonChip>

			<IonItemDivider className="ion-padding-top login-form-divider">
				* Let op!! gebruikersnaam is hoofdlettergevoelig
			</IonItemDivider>
			<IonItemDivider className="ion-padding-top login-form-divider">Nog geen account?</IonItemDivider>

			<IonButton className="ion-margin-top" expand="block" onClick={() => handleRedirect("/registreren")}>
				Registreren
			</IonButton>

			{/*Protected by Google reCAPTCHA message*/}
			<ReCaptchaMessage />
		</form>
	);
};

export default LoginForm;
