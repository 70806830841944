import "./ConfirmDelete.scss";
import LoginForm from "../../components/LoginForm";
import React, { PropsWithChildren, useState, useRef, useEffect } from "react";
import { IonButton, IonContent, IonImg, IonModal, IonTitle, IonHeader, IonToolbar } from "@ionic/react";
import queryString from "query-string";
import axiosConfig from "../../axiosConfig";
import { useCookies } from "react-cookie";
import DOMPurify from "dompurify";
import sadSun from "../../resources/sad_sun.png";
import ontslag from "../../resources/ontslag.png";

const ConfirmDelete = (props: PropsWithChildren<{ setUser: any; loggedIn: boolean; setLoggedIn: any }>) => {
	const [cookies, setCookie, removeCookie] = useCookies(["user"]);

	const [succes, setSucces] = useState(false);

	const modal = useRef<HTMLIonModalElement>(null);

	const query = queryString.parse(location.search);

	useEffect(() => {
		if (!cookies.user && !succes) {
			modal.current?.present();
		}
	});

	const closeModal = () => {
		modal.current?.dismiss();
	};

	const buildRedirect = () => {
		return `/verwijderen_bevestigen?token=${query.token as string}`;
	};

	if (cookies.user) {
		axiosConfig
			.post("/api/confirm_delete", {
				headers: {
					Accept: "application/json",
				},
				user_id: DOMPurify.sanitize(cookies.user.id),
				token: DOMPurify.sanitize(query.token as string),
			})
			.then(function (response) {
				console.log(response.data);

				setSucces(true);

				localStorage.removeItem("auth_token");
				removeCookie("user");
				props.setUser({ id: NaN, name: "Gast" });
				props.setLoggedIn(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	return (
		<IonContent className="confirm_delete_parent">
			{succes ? (
				<div className="succes">
					<IonImg src={ontslag} />
					<h2>Je account is verwijderd.</h2>
					<p>Je kan dit venster nu sluiten.</p>
				</div>
			) : (
				<div className="failed">
					<IonImg src={sadSun} />
					<h2>Er is iets misgegaan!</h2>
					<p>Probeer het later opnieuw.</p>
					<IonButton routerLink="/">Terug naar voorpagina</IonButton>
				</div>
			)}

			<IonModal ref={modal} className="fullscreen">
				<IonHeader>
					<IonToolbar>
						<IonTitle>Inloggen</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">
					<div className="login-form-container">
						<LoginForm
							redirect={buildRedirect()}
							setUser={props.setUser}
							setLoggedIn={props.setLoggedIn}
							function={closeModal}
						/>
					</div>
				</IonContent>
			</IonModal>
		</IonContent>
	);
};

export default ConfirmDelete;
