import { IonItem, IonInput, IonLabel, IonButton, IonIcon, IonText, useIonRouter, IonChip } from "@ionic/react";

import axiosConfig from "../axiosConfig";

import DOMPurify from "dompurify";
import "./PasswordRequestForm.scss";
import { arrowForward } from "ionicons/icons";

import React, { useState, useEffect } from "react";
import addEnterEvent from "../submitFormOnEnter";

const PasswordRequestForm = () => {
	//You can use this here to navigate through your pages
	const router = useIonRouter();

	const [email, setEmail] = useState("");

	const [error, setError] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		addEnterEvent();
	}, []);

	//With this function, the user can get a code to get permission to change their password
	const NewPassword = () => {
		setEmail(DOMPurify.sanitize(email));
		// This is used to check if you the email field isn't empty, otherwise you get an error
		if (email.length > 0) {
			//This is used to check if the email is registered as an user, otherwise you get an error
			axiosConfig
				.post("/api/password_change_request", {
					headers: {
						Accept: "application/json",
					},
					email: email,
				})
				.then(function (response) {
					router.push("/wachtwoord-bevestigen");
				})
				.catch(function (error) {
					console.log(error);
					setErrorMessage(error.response.data.warning);
				});
		} else {
			setErrorMessage("Email veld kan niet leeg zijn");
		}
	};
	return (
		<form className="ion-padding new-password-form">
			<IonText>
				<h1>Wachtwoord vergeten</h1>
			</IonText>
			<IonChip className="ion-margin-top" color="danger" style={{ display: error }}>
				{errorMessage}
			</IonChip>
			<IonItem>
				<IonLabel position="floating">Email</IonLabel>
				<IonInput type="email" name="email" autocomplete="email" onIonInput={(e: any) => setEmail(e.target.value)} />
			</IonItem>
			<IonButton expand="block" onClick={() => NewPassword()} className="ion-margin" form-submitter>
				<IonIcon icon={arrowForward} />
				Reset wachtwoord
			</IonButton>
		</form>
	);
};

export default PasswordRequestForm;
