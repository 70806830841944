import React, { PropsWithChildren } from "react";
import { IonButton, IonImg, IonItem, IonLabel } from "@ionic/react";
import { useLocation } from "react-router-dom";
import "../components/Verify Account/VerifyAccount.scss";
import logo from "../resources/dapper_dagboek_logo.png";

const ExternalLink = () => {
	const url = "https://" + useLocation().pathname.replace("/externe/", "");

	return (
		<div className="verify-page">
			<div className="verify-page-container">
				<IonImg src={logo} />
				<h4 style={{ textAlign: "center" }}>
					Je verlaat nu de app.
					<br />
					Druk op de knop om naar <br />
					&quot;
					<a href={url.replace("\\", "/")} target="_system">
						{url.replace("\\", "/")}
					</a>
					&quot; <br />
					te gaan.
				</h4>

				<IonButton routerLink={undefined} href={url} target="_system" color="primary">
					<IonLabel>Naar externe link</IonLabel>
				</IonButton>
			</div>
		</div>
	);
};

export default ExternalLink;
