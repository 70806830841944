import {
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonButton,
	useIonRouter,
} from "@ionic/react";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import {
	helpCircleOutline,
	helpCircleSharp,
	logIn,
	logOutOutline,
	cartOutline,
	cartSharp,
	settingsSharp,
	happyOutline,
	happySharp,
	homeOutline,
	homeSharp,
} from "ionicons/icons";
import "./Menu.scss";
import { useCookies } from "react-cookie";
import { isMenuHidden, showItemInMenu as getMenuItemDisplay } from "../displayConditions";
import User from "../User";

interface AppPage {
	url: string;
	iosIcon: string;
	mdIcon: string;
	title: string;
}

const appPages: AppPage[] = [
	{
		title: "Dashboard",
		url: "/",
		iosIcon: homeOutline,
		mdIcon: homeSharp,
	},
	{
		title: "Mijn stickers",
		url: "/sticker-overzicht",
		iosIcon: happyOutline,
		mdIcon: happySharp,
	},
	{
		title: "Help",
		url: "/Help",
		iosIcon: helpCircleOutline,
		mdIcon: helpCircleSharp,
	},
	{
		title: "Webshop",
		url: "/externe/dapperdagboek.nl\\bestel-hier-je-gratis-dapperdagboek",
		iosIcon: cartOutline,
		mdIcon: cartSharp,
	},
	{
		title: "Inloggen",
		url: "/login",
		iosIcon: logIn,
		mdIcon: logIn,
	},
	{
		title: "Uitloggen",
		url: "/logout",
		iosIcon: logOutOutline,
		mdIcon: logOutOutline,
	},
	{
		title: "Admin rechten",
		url: "/admin-rechten",
		iosIcon: logOutOutline,
		mdIcon: logOutOutline,
	},
];

const Menu = (props: PropsWithChildren<{ user: User; loggedIn: boolean }>) => {
	const location = useLocation();
	const router = useIonRouter();

	const [cookies] = useCookies(["user"]);

	const menuHidden = isMenuHidden();

	return (
		<IonMenu contentId="main" type="overlay" style={{ display: menuHidden ? "none" : "" }}>
			<IonContent>
				<IonList id="inbox-list">
					<IonListHeader className="welcome_indicator">
						<p style={{ cursor: "pointer" }} onClick={() => router.push("/")}>
							Welkom {props.user.personal_info?.firstname || props.user.username}
						</p>
						{props.loggedIn ? (
							<IonButton shape="round" routerLink="/account_instellingen">
								<IonIcon ios={settingsSharp} md={settingsSharp} />
							</IonButton>
						) : (
							<></>
						)}
					</IonListHeader>
					{appPages.map((appPage, index) => {
						return (
							<IonMenuToggle
								key={index}
								autoHide={false}
								style={{ display: getMenuItemDisplay(appPage.title, props.loggedIn) }}
							>
								<IonItem
									className={location.pathname === appPage.url ? `selected ${appPage.title}` : appPage.title}
									routerLink={appPage.url.startsWith("/") ? appPage.url : undefined}
									href={appPage.url.startsWith("/") ? undefined : appPage.url}
									target={appPage.url.startsWith("/") ? undefined : "_system"}
									routerDirection="none"
									lines="none"
									detail={false}
								>
									<IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default Menu;
