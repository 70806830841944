import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import Privacy from "../../components/Terms en Conditions/Privacy";
import Terms from "../../components/Terms en Conditions/Terms";
import "../../components/Terms en Conditions/Terms.scss";
import { useRef } from "react";
import { arrowBack } from "ionicons/icons";

const HelpView = () => {
	const privacyHelpModal = useRef<HTMLIonModalElement>(null);
	const termsModal = useRef<HTMLIonModalElement>(null);
	return (
		<>
			<IonButton className="terms_and_conditions ion-margin-top" fill="outline" id="open-modal-privacy-help">
				privacy voorwaarden
			</IonButton>

			<IonButton className="terms_and_conditions ion-margin-top" fill="outline" id="open-modal-terms">
				terms en conditions
			</IonButton>

			<IonModal ref={privacyHelpModal} trigger="open-modal-privacy-help" className="fullscreen" canDismiss={true}>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => privacyHelpModal.current?.dismiss()}>
								<IonIcon md={arrowBack} ios={arrowBack} />
							</IonButton>
						</IonButtons>
						<IonTitle>privacy voorwaarden</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent class="center">
					<Privacy />
				</IonContent>
			</IonModal>

			<IonModal ref={termsModal} trigger="open-modal-terms" className="fullscreen" canDismiss={true}>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => termsModal.current?.dismiss()}>
								<IonIcon md={arrowBack} ios={arrowBack} />
							</IonButton>
						</IonButtons>
						<IonTitle>terms en conditions</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent class="center">
					<Terms />
				</IonContent>
			</IonModal>
		</>
	);
};
export default HelpView;
