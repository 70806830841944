import { IonContent } from "@ionic/react";

import ChangePasswordForm from "../components/ChangePasswordForm";
import "./ChangePassword.scss";
import React from "react";

const ChangePassword: React.FC = () => {
	return (
		<IonContent className="change-password-form-container">
			<ChangePasswordForm />
		</IonContent>
	);
};

export default ChangePassword;
