/* eslint-disable indent */
import "./DiaryList.scss";
import {
	IonButton,
	IonButtons,
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonCard,
	IonRadioGroup,
	IonItem,
	IonLabel,
	IonRadio,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonRow,
	IonText,
} from "@ionic/react";
import dagboek_preset_blue from "../../resources/dagboek-blue.jpg";
import dagboek_preset_pink from "../../resources/dagboek-pink.jpg";
import dagboek_preset_algemeen from "../../resources/dagboek-preview.png";
import dagboek_preset_neonatologie from "../../resources/dagboek-standard.jpg";
import { add, pencil } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import axiosConfig from "../../axiosConfig";
import Diary from "../Diary/Diary";
import DiaryDeleteButton from "../Diary/DiaryDeleteButton";
import { useCookies } from "react-cookie";
import DiaryCoverPhoto from "../Diary/DiaryCoverPhoto";
import { useHistory } from "react-router-dom";
import { blue, pink } from "@material-ui/core/colors";

const DiaryList = () => {
	const [diaries, setDiaries] = React.useState<Diary[]>([]);

	const modal = useRef<HTMLIonModalElement>(null);
	const radioGroup = useRef<HTMLIonRadioGroupElement>(null);

	const [cookies] = useCookies(["user"]);
	const [diaryImage, setDiaryImage] = useState("");
	let createBool: boolean;

	useEffect(() => {
		//GetAllRolesForDiaryImage();
		updateDiaries();
	}, []);

	const history = useHistory();

	function createDiary(color: any) {
		modal.current?.dismiss();
		//Api call to create diary and set cover image to choosen color
		if (createBool) {
			console.log("you already have a diary");
		} else {
			axiosConfig
				.post("/api/diary/create", {
					cover_photo: DOMPurify.sanitize(radioGroup.current?.value),
				})
				.then((response) => {
					axiosConfig
						.post(`/api/diary/${response.data.diary?.index}/create`, {
							title: DOMPurify.sanitize("Voorgeschiedenis"),
						})
						.then((response) => {
							console.log(response.data.diary.chapters[0]);
							axiosConfig
								.post(`/api/diary/${response.data.diary?.index}/${response.data.diary.chapters[0]?.index}/create`, {
									title: DOMPurify.sanitize(""),
									type: DOMPurify.sanitize("voorgeschiedenis"),
								})
								.then(() => {
									modal.current?.dismiss();
									//Call updateDiaries function to reload the diary list and get the new diary
									updateDiaries();
								})
								.catch(function (error) {
									console.log(error + " PAGE");
								});
						})
						.catch(function (error) {
							console.log(error);
						});
				})
				.catch(function (error) {
					console.log(error);
				});
		}

		createBool = true;
	}

	const updateDiaries = () => {
		axiosConfig
			.post("/api/diary/list")
			.then((response) => {
				const diaries: Diary[] = [];
				for (let diary of response.data.diaries) {
					diary = diary as Diary;
					diaries.push(diary);
				}
				setDiaries(diaries);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const setCoverChanged = () => {
		setTimeout(() => {
			updateDiaries();
		}, 10);
	};

	return (
		<>
			<div style={{ marginTop: "3%" }} className="horizontalScroll ion-margin-bottom">
				<IonGrid className={"grid"}>
					<IonRow className="centerItems">
						{diaries.map((diary) => {
							return (
								<IonCol className={"diary-edit"} size={"3"} key={diary.index}>
									<IonCardHeader
										onClick={function () {
											history.push("/dagboek/" + diary.index);
										}}
										style={{ padding: "16px 16px 0px 16px", margin: "0px" }}
									>
										<IonCardTitle style={{ margin: "0px" }}>
											<h1>Hoi {cookies.user.personal_info?.firstname || cookies.user.username}</h1>
										</IonCardTitle>
									</IonCardHeader>
									<IonCardContent style={{ margin: "0px" }}>
										<div
											onClick={function () {
												history.push("/dagboek/" + diary.index);
											}}
										>
											{diaries.map((diary, index) => {
												if (diary.coverPhoto == "dagboek_preset_blue") {
													return <IonImg key={index} class="coverImage" src={dagboek_preset_blue} />;
												} else if (diary.coverPhoto == "dagboek_preset_pink") {
													return <IonImg key={index} class="coverImage" src={dagboek_preset_pink} />;
												} else if (diary.coverPhoto == "dagboek_preset_algemeen") {
													return <IonImg key={index} class="coverImage" src={dagboek_preset_algemeen} />;
												} else if (diary.coverPhoto == "dagboek_preset_neonatologie") {
													return <IonImg key={index} class="coverImage" src={dagboek_preset_neonatologie} />;
												}
											})}
										</div>
										{
											//<DiaryCoverPhoto diary={diary} setCoverChanged={setCoverChanged} />
										}
										<DiaryDeleteButton diary={diary} func={() => updateDiaries()} />
									</IonCardContent>
								</IonCol>
							);
						})}
					</IonRow>
				</IonGrid>
			</div>
			<div style={{ minWidth: "50%", textAlign: "center" }} />
			{diaries.length === 0 && (
				<div style={{ width: "33%" }}>
					<IonCard style={{}} className="diary-addButton">
						<IonCardHeader>
							<IonCardTitle>Nieuw dagboek</IonCardTitle>
						</IonCardHeader>
						<IonButton onClick={() => modal.current?.present()} fill="clear">
							<IonIcon md={add} ios={add} />
						</IonButton>
					</IonCard>
				</div>
			)}

			<IonModal ref={modal} className="add-page-modal">
				<div className="wrapper">
					<IonHeader>
						<IonToolbar>
							<IonTitle>Kies de kleur van je dagboek</IonTitle>
						</IonToolbar>
					</IonHeader>
					<div className="ion-padding">
						{cookies.user.diary_type == 1 ? (
							<IonRadioGroup ref={radioGroup}>
								<IonItem>
									<IonLabel>Blauw</IonLabel>
									<IonRadio slot="end" value="dagboek_preset_blue"></IonRadio>
								</IonItem>

								<IonItem>
									<IonLabel>Roze</IonLabel>
									<IonRadio slot="end" value="dagboek_preset_pink"></IonRadio>
								</IonItem>
							</IonRadioGroup>
						) : (
							<IonRadioGroup ref={radioGroup}>
								<IonItem>
									<IonLabel>Algemeen</IonLabel>
									<IonRadio slot="end" value="dagboek_preset_algemeen"></IonRadio>
								</IonItem>

								<IonItem>
									<IonLabel>Neonatologie</IonLabel>
									<IonRadio slot="end" value="dagboek_preset_neonatologie"></IonRadio>
								</IonItem>
							</IonRadioGroup>
						)}
					</div>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => modal.current?.dismiss()}>Annuleren</IonButton>
						</IonButtons>
						<IonButtons slot="end">
							<IonButton
								onClick={() => {
									createDiary(radioGroup.current?.value);
								}}
							>
								Dagboek aanmaken
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</div>
			</IonModal>
		</>
	);
};

export default DiaryList;
