import React, { PropsWithChildren } from "react";
import Diary, { Chapter } from "./Diary";
import { IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import AlertButton from "../AlertButton";
import axiosConfig from "../../axiosConfig";

const ChapterDeleteButton = (
	props: PropsWithChildren<{ diaryIndex: number; chapter: Chapter; func: (diary: Diary) => any }>,
) => {
	const deleteChapter = () => {
		axiosConfig
			.post("/api/diary/" + props.diaryIndex + "/" + props.chapter.index + "/delete")
			.then((response) => {
				props.func(response.data.diary);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<AlertButton
			class={"diary-delete-button"}
			header={`Weet je zeker dat je hoofdstuk ${props.chapter.title} wilt verwijderen?`}
			fill="solid"
			color="danger"
			func={() => deleteChapter()}
		>
			<IonIcon icon={trash} />
		</AlertButton>
	);
};

export default ChapterDeleteButton;
