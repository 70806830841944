import {
	IonGrid,
	IonItem,
	IonLabel,
	IonButton,
	IonRow,
	IonCol,
	IonContent,
	IonInput,
	IonBadge,
	IonAlert,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonModal,
	IonButtons,
	IonToast,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import "./UserSettings.scss";
import axiosConfig from "../../axiosConfig";
import { useForm } from "react-hook-form";
import { OverlayEventDetail } from "@ionic/core/components";

const SecuritySettings = () => {
	const [isEmail, setEmail] = useState();

	const [isNewEmail, setNewEmail] = useState("");

	const [isPassword, setPassword] = useState("");
	const [isNewPassword, setNewPassword] = useState("");
	const [isNewPasswordConfirm, setNewPasswordConfirm] = useState("");
	const [isLoading, setLoading] = useState(true);

	const [showToastError, setShowToastError] = useState(false);
	const [showToastSuccess, setShowToastSuccess] = useState(false);

	const [isErrorMessage, setErrorMessage] = useState("");

	const [showAlert, setShowAlert] = useState(false);

	const modal = useRef<HTMLIonModalElement>(null);
	const input = useRef<HTMLIonInputElement>(null);

	function confirm() {
		modal.current?.dismiss(input.current?.value, "confirm");
	}

	function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
		if (ev.detail.role === "confirm") {
			setShowAlert(true);
		}
	}

	const updatePassword = () => {
		if (isPassword.length < 1) {
			setErrorMessage("Het huidige wachtwoordveld is leeg.");
			setShowToastError(true);
			return;
		}
		if (isNewPassword.length < 8) {
			setErrorMessage("Minimaal acht tekens voor het nieuwe wachtwoord.");
			setShowToastError(true);
			return;
		}
		if (isNewPassword !== isNewPasswordConfirm) {
			setErrorMessage("Wachtwoorden komen niet overeen.");
			setShowToastError(true);
			return;
		}
		axiosConfig
			.post("/api/update_password", {
				old: isPassword,
				new: isNewPassword,
				new_confirm: isNewPasswordConfirm,
			})
			.then(function (response) {
				if (response.status === 200) {
					setShowToastSuccess(true);
					setNewPassword("");
					setNewPasswordConfirm("");
					setPassword("");
					refresh();
				}
				if (response.status === 201) {
					setErrorMessage("Huidige wachtwoord is onjuist.");
					setShowToastError(true);
					return;
				}
				if (response.status === 202) {
					setErrorMessage("Nieuwe wachtwoord mag niet hetzelfde zijn.");
					setShowToastError(true);
					setNewPassword("");
					setNewPasswordConfirm("");
					setPassword("");
				}
			})
			.catch(function (error) {
				setErrorMessage("Er is iets misgegaan.");
				setShowToastError(true);
				return;
			});
	};

	const refresh = () => {
		setLoading(true);
		axiosConfig
			.post("/api/user/info")
			.then(function (response) {
				setEmail(response.data.data.email);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		if (isLoading) {
			refresh();
		}
	}, []);

	const submitCode = (info: any) => {
		return new Promise((resolve, reject) => {
			if (info) {
				axiosConfig
					.post("api/check_email_request", {
						code: info.code,
						email: isNewEmail,
					})
					.then((response) => {
						console.log(response.status);
						resolve(response.status);
					});
			} else {
				reject(reject);
			}
		});
	};
	const showAlertEmail = () => {
		axiosConfig
			.post("api/new_email_request", {
				email: isNewEmail,
			})
			.then(() => {
				setShowAlert(true);
			})
			.catch(() => {
				setErrorMessage("Deze e-mail is bezet.");
				setShowToastError(true);
			});
	};

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	return (
		<>
			<IonButton id="open-security">Beveiligingsinstellingen</IonButton>
			<IonModal ref={modal} trigger="open-security" onWillDismiss={(ev) => onWillDismiss(ev)}>
				<IonToast
					isOpen={showToastError}
					onDidDismiss={() => setShowToastError(false)}
					color={"danger"}
					message={isErrorMessage}
					duration={1500}
				/>

				<IonToast
					isOpen={showToastSuccess}
					onDidDismiss={() => setShowToastSuccess(false)}
					color={"success"}
					message="Gegevens zijn aangepast!"
					duration={3000}
				/>

				{/*ALERT*/}
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => setShowAlert(false)}
					header="Bevestiging"
					subHeader="Er is een mail verzonden."
					inputs={[
						{
							name: "code",
							placeholder: "Vul hier de code in...",
							type: "password",
						},
					]}
					buttons={[
						{
							text: "Opslaan",
							role: "check",
							handler: (data) => {
								submitCode(data)
									.then((response) => {
										if (response == 200) {
											setShowToastSuccess(true);
											setTimeout(function () {
												setShowAlert(false);
												refresh();
												setNewEmail("");
											}, 3000);
										} else {
											setErrorMessage("De code is onjuist.");
											setShowToastError(true);
										}
									})
									.catch(() => {
										setErrorMessage("De code is onjuist.");
										setShowToastError(true);
									});
								return false;
							},
						},
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {},
						},
					]}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => modal.current?.dismiss()}>Terug</IonButton>
						</IonButtons>
						<IonTitle>Beveiliging</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonGrid>
						<form onSubmit={handleSubmit(showAlertEmail)}>
							<IonRow>
								<IonCol class={"personal_info_general"} sizeXs={"12"}>
									<IonItem>
										<h3>E-mail</h3>
									</IonItem>
								</IonCol>
								<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"5"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Actuele e-mail</IonLabel>
										<IonInput disabled value={isEmail}></IonInput>
									</IonItem>
								</IonCol>
								<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"5"}>
									<IonItem class={"input_field"}>
										<IonLabel position={"stacked"}>Nieuwe e-mail</IonLabel>
										<IonInput
											value={isNewEmail}
											placeholder={"Vul hier in..."}
											{...register("mail", { required: true, pattern: /^[-\w.]+@([-\w-]+\.)+[-\w-]{2,4}$/ })}
											onIonInput={(e: any) => setNewEmail(e.target.value)}
										></IonInput>
									</IonItem>
									<b>
										{errors.mail && errors.mail.type === "required" && (
											<IonBadge color="danger">Dit veld is vereist.</IonBadge>
										)}
										{errors.mail && errors.mail.type === "pattern" && (
											<IonBadge color="danger">Dit is geen geldig email.</IonBadge>
										)}
									</b>
								</IonCol>
								<IonCol class={"first_field_move_margin"} sizeXs={"12"}>
									<IonButton type={"submit"} expand="block">
										Email wijzigen
									</IonButton>
								</IonCol>
							</IonRow>
						</form>
						<IonRow>
							<IonCol class={"personal_info_general"} sizeXs={"12"}>
								<IonItem>
									<h3>Wachtwoord</h3>
								</IonItem>
							</IonCol>
							<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"5"}>
								<IonItem class={"input_field"}>
									<IonLabel position={"stacked"}>Huidige wachtwoord</IonLabel>
									<IonInput
										type={"password"}
										onIonInput={(e: any) => setPassword(e.target.value)}
										value={isPassword}
										placeholder={"Vul hier in..."}
									></IonInput>
								</IonItem>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"5"}>
								<IonItem class={"input_field"}>
									<IonLabel position={"stacked"}>Nieuwe wachtwoord</IonLabel>
									<IonInput
										type={"password"}
										onIonInput={(e: any) => setNewPassword(e.target.value)}
										value={isNewPassword}
										placeholder={"Vul hier in..."}
									></IonInput>
								</IonItem>
							</IonCol>
							<IonCol class={"first_field_move_margin"} sizeXs={"12"} sizeMd={"6"}>
								<IonItem class={"input_field"}>
									<IonLabel position={"stacked"}>Nieuwe wachtwoord bevestigen</IonLabel>
									<IonInput
										type={"password"}
										onIonInput={(e: any) => setNewPasswordConfirm(e.target.value)}
										value={isNewPasswordConfirm}
										placeholder={"Vul hier in..."}
									></IonInput>
								</IonItem>
							</IonCol>
							<IonCol class={"first_field_move_margin"} sizeXs={"12"}>
								<IonButton onClick={updatePassword} expand="block">
									Wachtwoord wijzigen
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonModal>
		</>
	);
};

export default SecuritySettings;
