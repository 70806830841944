import "./RegisterForm.scss";
import {
	IonButton,
	IonSelect,
	IonCheckbox,
	IonSelectOption,
	IonChip,
	IonInput,
	IonItem,
	IonLabel,
	useIonRouter,
} from "@ionic/react";
import * as yup from "yup";
import shouldComponentUpdate from "react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import axiosConfig from "../axiosConfig";
import TermsItem from "./Terms en Conditions/TermsItem";
import { ReCaptchaMessage } from "./ReCaptcha/ReCaptchaMessage";
import { WaitForCaptcha } from "./ReCaptcha/WaitForCaptcha";
import { ValidationError } from "yup";
import addEnterEvent from "../submitFormOnEnter";

const RegisterForm = (props: PropsWithChildren) => {
	const router = useIonRouter();
	const [username, setUsername] = useState("");
	const [firstname, setFirstname] = useState("");
	const [surname, setSurname] = useState("");
	const [birthdate, setBirthdate] = useState("");

	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("");
	const [repeatPassword, setRepeatPass] = useState("");
	const [ageChecked, setAgeChecked] = useState(false);
	const [termsChecked, setTermsChecked] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [roles, setRoles] = useState([]);
	const [diaryType, setDiaryType] = useState([]);

	useEffect(() => {
		WaitForCaptcha();

		addEnterEvent();

		const inputs = document.querySelectorAll("ion-input");
		inputs.forEach((input) => {
			input.addEventListener("ionFocus", (event) => {
				if (input.parentElement) {
					input.parentElement.classList.remove("wrong");
				}
			});
			input.addEventListener("ionChange", (event) => {
				if (input.parentElement) {
					input.parentElement.classList.remove("wrong");
				}
			});
		});
	});

	useEffect(() => {
		axiosConfig
			.get("/api/get_all_roles")
			.then((response) => {
				setRoles(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
		axiosConfig
			.get("/api/get_all_diary_types")
			.then((response) => {
				setDiaryType(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const clearWrong = () => {
		const items = document.querySelectorAll("ion-item");
		items.forEach((item) => {
			item.classList.remove("wrong");
		});
	};

	const validationSchema = yup.object().shape({
		Gebruikersnaam: yup.string().required().min(2),
		Voornaam: yup.string(),
		Achternaam: yup.string(),
		Geboortedatum: yup
			.date()
			.nullable()
			.transform((curr, orig) => (orig === "" ? null : curr)),
		Email: yup.string().email().required(),
		Wachtwoord: yup.string().required().min(8),
		"Herhaal wachtwoord": yup
			.string()
			.oneOf([yup.ref("Wachtwoord"), null], "Wachtwoorden komen niet overeen.")
			.required("Wachtwoorden komen niet overeen."),
		ageCheck: yup.boolean().oneOf([true], "Je moet 13 jaar zijn of toestemming van je ouders hebben."),
		termsCheck: yup.boolean().oneOf([true], "Je moet akkoord gaan met de algemene voorwaarden."),
	});

	const handleSubmit = () => {
		validationSchema
			.validate(
				{
					Gebruikersnaam: username,
					Voornaam: firstname,
					Achternaam: surname,
					Geboortedatum: birthdate,
					Email: email,
					Wachtwoord: password,
					"Herhaal wachtwoord": repeatPassword,
					ageCheck: ageChecked,
					termsCheck: termsChecked,
					type_id: role,
				},
				{ abortEarly: false },
			)
			.then(async (value) => {
				setErrorMessage("");
				// checks if date is correct and not in the futher for a couple hunderd years
				if (new Date().getFullYear() < new Date(birthdate).getFullYear()) {
					const alert = document.getElementById("datumAlert");
					if (alert) alert.style.display = "block";
					return;
				}
				// checks if date is correct and not in the past for a couple hunderd years
				if (new Date().getFullYear() - 150 > new Date(birthdate).getFullYear()) {
					const alert = document.getElementById("datumAlert");
					if (alert) alert.style.display = "block";
					return;
				}

				if (!window.reCaptchaBox.current) {
					return;
				}

				// Get captcha token and show if necessary
				const token = await window.reCaptchaBox.current.executeAsync();

				if (token === null || token === "") {
					setErrorMessage("Captcha is niet ingevuld.");
					return;
				}
				axiosConfig
					.post("/api/register", {
						username: username,
						firstname: firstname,
						surname: surname,
						birthdate: birthdate,
						password: password,
						email: email,
						captchaToken: token,
						type_id: role,
					})
					.then(function (response) {
						setErrorMessage("Account succesvol toegevoegd.");
						localStorage.setItem("auth_token", response.data.token);
						router.push("/login");
					})
					.catch(function (error) {
						let elementId = "";
						if (
							(error.response.data?.errors?.username &&
								error.response.data?.errors?.username[0] === "The username has already been taken.") ||
							error.response.data.error == "Name is used by disabled user"
						) {
							setErrorMessage("Gebruikersnaam bestaat al.");
							elementId = "username";
						} else if (
							(error.response.data?.errors?.email &&
								error.response.data?.errors?.email[0] === "The email has already been taken.") ||
							error.response.data.error == "User is disabled"
						) {
							setErrorMessage("Email is al in gebruik");
							elementId = "email";
						} else {
							setErrorMessage("Er is iets mis gegaan");
						}
						document.getElementById(elementId)?.classList.add("wrong");
						console.log(error);
					});
			})
			.catch((err: ValidationError) => {
				// Set one visual error message
				setErrorMessage(err.errors[0]);

				// Set all input fields that are wrong to the wrong class
				clearWrong();

				err.inner.forEach((error) => {
					let elementId = "";
					switch (error.path) {
						case "Gebruikersnaam":
							elementId = "username";
							break;
						case "Voornaam":
							elementId = "firstname";
							break;
						case "Achternaam":
							elementId = "surname";
							break;
						case "Geboortedatum":
							elementId = "birthdate";
							break;
						case "Email":
							elementId = "email";
							break;
						case "Wachtwoord":
							elementId = "password";
							break;
						case "Herhaal wachtwoord":
							elementId = "repeat";
							break;
						case "ageCheck":
							elementId = "ageCheck";
							break;
						case "termsCheck":
							elementId = "termsItem";
							break;
					}

					document.getElementById(elementId)?.classList.add("wrong");
				});
			});
	};

	return (
		<form className="ion-padding register-form">
			<IonItem className="ion-margin-top" id="username">
				<IonLabel position="floating">Gebruikersnaam</IonLabel>
				<IonInput
					minlength={2}
					maxlength={20}
					onIonInput={(e: any) => setUsername(e.target.value)}
					required={true}
				></IonInput>
			</IonItem>

			<IonItem className="ion-margin-top" id="firstname">
				<IonLabel position="floating">Voornaam</IonLabel>
				<IonInput
					minlength={2}
					maxlength={20}
					onIonInput={(e: any) => setFirstname(e.target.value)}
					required={false}
					placeholder={"Optioneel"}
				></IonInput>
			</IonItem>
			<IonItem className="ion-margin-top" id="surname">
				<IonLabel position="floating">Achternaam</IonLabel>
				<IonInput
					minlength={2}
					maxlength={20}
					onIonInput={(e: any) => setSurname(e.target.value)}
					required={false}
					placeholder={"Optioneel"}
				></IonInput>
			</IonItem>
			<IonItem className="ion-margin-top" id="birthdate">
				<span
					id="datumAlert"
					style={{
						display: "none",
						color: "red",
					}}
				>
					Geen geldige datum
				</span>
				<IonLabel position="stacked">Geboortedatum</IonLabel>
				<br />
				<IonInput
					type="date"
					min="1900-01-01"
					max="3000-01-01"
					minlength={2}
					maxlength={20}
					onIonInput={(e: any) => setBirthdate(e.target.value)}
					required={false}
				></IonInput>
			</IonItem>

			<IonItem className="ion-margin-top" id="email">
				<IonLabel position="floating">Email: </IonLabel>
				<IonInput type="email" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
			</IonItem>
			<IonItem className="ion-margin-top" id="password">
				<IonLabel position="floating">Wachtwoord</IonLabel>
				<IonInput
					type="password"
					onIonInput={(e: any) => setPassword(e.target.value)}
					minlength={8}
					maxlength={20}
					required={true}
					clearOnEdit={false}
				></IonInput>
			</IonItem>

			<IonItem className="ion-margin-top" id="repeat">
				<IonLabel position="floating">Herhaal wachtwoord</IonLabel>
				<IonInput
					type="password"
					minlength={8}
					maxlength={20}
					onIonInput={(e: any) => setRepeatPass(e.target.value)}
					required={true}
					clearOnEdit={false}
				></IonInput>
			</IonItem>

			<IonItem className="ion-margin-top">
				<IonLabel>Kies jouw dagboek</IonLabel>
				<IonSelect
					cancelText="Annuleren"
					okText="Bevestigen"
					onIonChange={(e: any) => setRole(e.target.value)}
					className="register_role_select"
				>
					{diaryType.map((type: any) => (
						<IonSelectOption key={type.id} value={type.id}>
							{type.type}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>

			<IonItem className="ion-margin-top" id="ageCheck" lines="none">
				<IonCheckbox
					slot="start"
					onIonChange={(e) => {
						setAgeChecked(e.detail.checked);
						e.target.parentElement?.classList.remove("wrong");
					}}
				/>
				<IonLabel class="ion-text-wrap">Ik ben ouder dan 13 jaar of heb toestemming van mijn ouders.</IonLabel>
			</IonItem>

			{/*Terms and conditions*/}
			<TermsItem setTermsChecked={setTermsChecked} />

			<IonButton className="ion-margin-top" onClick={() => handleSubmit()} expand="block" form-submitter>
				Registeren
			</IonButton>

			<IonChip className="right_cred_message ion-margin-top" color="danger">
				{errorMessage}
			</IonChip>

			{/*Protected by Google reCAPTCHA message*/}
			<ReCaptchaMessage />
		</form>
	);
};

interface Roles {
	id: number;
	role: string;
}

export default RegisterForm;
