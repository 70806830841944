import { IonContent } from "@ionic/react";

import PasswordConfirmForm from "../components/PasswordConfirmForm";

import "./Confirm.scss";

const PasswordConfirm: React.FC = () => {
	return (
		<IonContent className="confirm-form-container">
			<PasswordConfirmForm />
		</IonContent>
	);
};

export default PasswordConfirm;
