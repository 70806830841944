import React, { PropsWithChildren, useEffect, useRef } from "react";
import Diary, { Chapter } from "./Diary";
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonIcon,
	IonInput,
	IonItem,
	IonRow,
	IonText,
	useIonToast,
} from "@ionic/react";
import { pencil } from "ionicons/icons";
import ChapterDeleteButton from "./ChapterDeleteButton";
import axiosConfig from "../../axiosConfig";
import { useHistory } from "react-router-dom";
import "./ChapterListView.scss";

const ChapterListView = (
	props: PropsWithChildren<{
		diary: Diary;
		chapter: Chapter;
		setDiary: (diary: Diary) => any;
		setPrevDiary: (diary: Diary) => any;
		showDelete: boolean;
	}>,
) => {
	const diary = props.diary;
	const [chapter, setChapter] = React.useState<Chapter>(props.chapter);

	const [present] = useIonToast();

	const [editingTitle, setEditingTitle] = React.useState(false);
	const [prevChapter, setPrevChapter] = React.useState<Chapter>(JSON.parse(JSON.stringify(props.chapter)));

	const titleInput = useRef<HTMLIonInputElement>(null);

	const history = useHistory();

	useEffect(() => {
		// Make sure to wait a little bit so titleInput exists
		setTimeout(() => {
			titleInput.current?.setFocus();
		}, 10);
	}, [editingTitle]);

	useEffect(() => {}, [chapter.title]);

	const onTitleBlur = () => {
		setEditingTitle(false);
		if (chapter.title != prevChapter.title) {
			axiosConfig
				.post(`/api/diary/${diary.index}/${chapter.index}/update`, {
					title: chapter.title,
				})
				.then((response) => {
					setPrevChapter(JSON.parse(JSON.stringify(chapter)));

					props.setDiary(response.data.diary as Diary);
					props.setPrevDiary(JSON.parse(JSON.stringify(response.data.diary)) as Diary);
				})
				.catch((err) => {
					console.log(err);
					diary.chapters[chapter.index - 1].title = prevChapter.title;

					props.setDiary(diary);
					setChapter(prevChapter);

					if (err.response.status === 422) {
						present({
							message: "De titel moet tussen de 3 en 50 tekens lang zijn.",
							duration: 3000,
							position: "top",
						});
					}
				});
		}
	};

	const title = editingTitle ? (
		<IonItem counter={true}>
			<IonInput
				ref={titleInput}
				minlength={3}
				maxlength={50}
				autofocus={true}
				value={chapter.title}
				onIonChange={(e) => {
					if (diary) {
						diary.chapters[chapter.index - 1].title = e.detail.value!;
						chapter.title = e.detail.value!;
						props.setDiary(diary);
					}
				}}
				onIonBlur={() => onTitleBlur()}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						titleInput.current?.setBlur();
					}
				}}
			/>
		</IonItem>
	) : (
		chapter.title
	);

	return (
		<>
			<IonGrid key={chapter.index}>
				<IonRow>
					{props.showDelete && (
						<div className={"buttons"}>
							<IonButton onClick={() => setEditingTitle(!editingTitle)}>
								<IonIcon icon={pencil} />
							</IonButton>
							<ChapterDeleteButton
								diaryIndex={diary.index}
								chapter={chapter}
								func={(newDiary: Diary) => {
									props.setDiary(newDiary);
									props.setPrevDiary(newDiary);
								}}
							/>
						</div>
					)}
					<IonCol
						onClick={function () {
							history.push("/dagboek/" + diary.index + "/" + chapter.index);
						}}
						size={"12"}
					>
						<IonText color="black">
							<h1>{title}</h1>
						</IonText>
					</IonCol>
					<IonCol
						onClick={function () {
							history.push("/dagboek/" + diary.index + "/" + chapter.index);
						}}
						size={"12"}
					>
						<h4>
							{chapter.pageCount} pagina&apos;s <br />
							Laats gewijzigd {new Date(chapter.updated_at).toLocaleString()}
						</h4>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
};

export default ChapterListView;
