import { IonContent, IonImg } from "@ionic/react";
import "./IndexPage.scss";
import React, { PropsWithChildren, useEffect, useState } from "react";

import adminBanner from "../resources/dapper_dagboek_logo.png";
import bozboekBanner from "../resources/dashboardbanner3.png";
import dapperdagboekBanner from "../resources/dashboardbanner2.png";

import zon from "../resources/zon.png";

import { useCookies } from "react-cookie";
import axiosConfig from "../axiosConfig";

import { Redirect } from "react-router-dom";
import { add } from "ionicons/icons";
import DiaryList from "../components/IndexPage/DiaryList";
import User from "../User";

const IndexPage = (props: PropsWithChildren<{ loggedIn: boolean; user: User }>) => {
	const [cookies] = useCookies(["user"]);
	const [dashboardBanner, setDashboardBanner] = useState("");

	useEffect(() => {
		GetAllRolesForDashboardImage();
	}, []);

	const GetAllRolesForDashboardImage = () => {
		axiosConfig
			.get("/api/get_all_diary_types")
			.then(function (response) {
				if (cookies.user.diary_type == response.data[1].id) {
					setDashboardBanner(dapperdagboekBanner);
				} else if (cookies.user.diary_type == response.data[0].id) {
					setDashboardBanner(bozboekBanner);
				} else {
					setDashboardBanner(adminBanner);
				}
			})
			.catch(function (error) {
				console.log(error + " OK");
			});
	};

	return (
		<>
			{cookies.user !== undefined ? (
				<IonContent>
					<div className="background">
						<div className="zon-wrapper">
							<IonImg src={zon} className="zon" />
						</div>
						<div className="center">
							<IonImg style={{ width: "400px" }} src={dashboardBanner} />
							<DiaryList />
						</div>
					</div>
				</IonContent>
			) : (
				<Redirect to="/login" />
			)}
		</>
	);
};

export default IndexPage;
