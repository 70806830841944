import React, { ChangeEvent, PropsWithChildren, useCallback, useState } from "react";
import {
	IonHeader,
	IonTitle,
	IonButtons,
	IonToolbar,
	IonButton,
	IonImg,
	IonToast,
	IonRadio,
	IonItem,
	IonRadioGroup,
	IonLabel,
} from "@ionic/react";
import getCroppedImg from "../cropImage";
import Cropper from "react-easy-crop";
import { Slider } from "@material-ui/core";
import "./AddPhotoModal.scss";

const AddPhotoModal = (
	props: PropsWithChildren<{ closePhotoModal: () => void; savePhotoModal: (image: any, orientation: Array<string>) => any }>,
) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [selectedImage, setSelectedImage]: any[] = useState();
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const [imageLoaded, setImageLoaded] = useState(false);

	const [isErrorMessage, setErrorMessage] = useState("");
	const [showToastError, setShowToastError] = useState(false);

	const [showToastSuccess, setShowToastSuccess] = useState(false);
	const [isSuccessMessage, setSuccessMessage] = useState("");

	const [aspectRatio, setAspectRatio] = useState("liggend");
	const [aspectWidth, setAspectWidth] = useState(4);
	const [aspectHeight, setAspectHeight] = useState(3);

	const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	function setImage(image: ChangeEvent<HTMLInputElement>) {
		if (image.target.files) {
			if (image.target.files[0].size < 4022689) {
				if (image.target.files[0].type === "image/png" || image.target.files[0].type === "image/jpeg") {
					setImageLoaded(true);
					setSelectedImage(URL.createObjectURL(image.target.files[0]));
				} else {
					setImageLoaded(false);
					setErrorMessage("Alleen png, jpg en jpeg toegestaan!");
					setShowToastError(true);
					return;
				}
			} else {
				setImageLoaded(false);
				setErrorMessage("De afbeelding is te groot. Maximaal 4MB!");
				setShowToastError(true);
				return;
			}
		}
	}

	async function getImage() {
		setImageLoaded(false);
		setShowToastSuccess(true);
		setSuccessMessage("Afbeelding is geüpload.");

		const croppedImageUrl = await getCroppedImg(selectedImage, croppedAreaPixels);

		props.savePhotoModal(croppedImageUrl, [(aspectWidth * 100).toString(), (aspectHeight * 100).toString()]);
	}

	const aspectChange = (e: CustomEvent) => {
		const aspectRatio = e.detail.value;
		console.log(aspectRatio);
		let aspectArray = [];
		aspectRatio == "liggend" ? (aspectArray = [4, 3]) : (aspectArray = [3, 4]);

		setAspectRatio(aspectRatio);
		setAspectWidth(aspectArray[0]);
		setAspectHeight(aspectArray[1]);
	};

	return (
		<>
			<IonToast
				isOpen={showToastError}
				onDidDismiss={() => setShowToastError(false)}
				color={"danger"}
				message={isErrorMessage}
				duration={1500}
			/>

			<IonToast
				isOpen={showToastSuccess}
				onDidDismiss={() => setShowToastSuccess(false)}
				color={"success"}
				message={isSuccessMessage}
				duration={3000}
			/>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Foto toevoegen</IonTitle>
					<IonButtons slot="start">
						<IonButton onClick={() => props.closePhotoModal()}>Sluiten</IonButton>
					</IonButtons>
					{imageLoaded ? (
						<>
							<IonButtons slot="end">
								<IonButton className={"save_image_button"} onClick={() => getImage()}>
									Opslaan
								</IonButton>
							</IonButtons>
						</>
					) : (
						""
					)}
				</IonToolbar>
			</IonHeader>
			<div className="image-upload">
				{imageLoaded ? (
					<>
						<IonRadioGroup onIonChange={(e) => aspectChange(e)} className="aspect-ratio-selector" value={aspectRatio}>
							<IonLabel className="aspect-ratio-selector-title">Foto oriëntatie</IonLabel>
							<IonItem lines="none">
								<IonLabel>Liggend</IonLabel>
								<IonRadio slot="start" value="liggend"></IonRadio>
							</IonItem>

							<IonItem lines="none">
								<IonLabel>Staand</IonLabel>
								<IonRadio slot="start" value="staand"></IonRadio>
							</IonItem>
						</IonRadioGroup>
					</>
				) : (
					<label htmlFor="file-input">
						<IonImg src={process.env.PUBLIC_URL + "assets/icon/upload.png"}></IonImg>
					</label>
				)}

				<input
					id="file-input"
					type="file"
					onChange={(event) => {
						setImage(event);
					}}
				/>
			</div>
			{imageLoaded ? (
				<div className="App crop-zoom-controls">
					<div className="crop-container">
						<Cropper
							image={selectedImage}
							crop={crop}
							zoom={zoom}
							aspect={aspectWidth / aspectHeight}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
					</div>
					<div className="controls">
						<Slider
							value={zoom}
							min={1}
							max={3}
							step={0.1}
							aria-labelledby="Zoom"
							onChange={(e, zoom) => setZoom(Number(zoom))}
							classes={{ root: "slider" }}
						/>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default AddPhotoModal;
